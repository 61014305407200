<template>
  
  <!-- <div id="app"> -->
    <div>
      <router-view></router-view>
  
  </div>
</template>
  <script>
    export default {
      name: "App",

    
      computed: {
        users() {
          return this.$store.state.users;
        },
    
        parameters() {
          return {
            data: this.users,
            actionColumn: true,
            columns: this.keys.map(key => ({ key }))
          };
        }
      },
    
      data() {
        return {
          keys: ["name", "email", "gender", "country", "job", "city", "created_at"]
        };
      }
    };
    </script>
    