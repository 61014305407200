<template>
  <div class="shiftscheduling">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Daily Scheduling</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                                    <li class="breadcrumb-item"><router-link to="/employees">Employees</router-link></li>
                                    <li class="breadcrumb-item active">Shift Scheduling</li>
                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                <router-link to="/shift-list" class="btn add-btn m-r-5">Shifts</router-link>
                <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_schedule"> Assign Shifts</a>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Content Starts -->
          <!-- Search Filter -->
          <div class="row filter-row">
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input type="text" class="form-control floating">
                <label class="focus-label">Employee</label>
              </div>
            </div>
            
            <div class="col-sm-6 col-md-3"> 
              <div class="form-group form-focus select-focus">
                <select class="select floating"> 
                  <option>All Department</option>
                  <option value="1">Finance</option>
                                    <option value="2">Finance and Management</option>
                                    <option value="3">Hr & Finance</option>
                                    <option value="4">ITech</option>
                </select>
                <label class="focus-label">Department</label>
              </div>
                        </div>
                        <div class="col-sm-6 col-md-2">  
              <div class="form-group form-focus focused">
                <div class="cal-icon">
                  <input class="form-control floating datetimepicker" type="text">
                </div>
                <label class="focus-label">From</label>
              </div>
                        </div>
                        <div class="col-sm-6 col-md-2">  
              <div class="form-group form-focus focused">
                <div class="cal-icon">
                  <input class="form-control floating datetimepicker" type="text">
                </div>
                <label class="focus-label">To</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-2">  
              <a href="#" class="btn btn-success w-100"> Search </a>  
            </div>     
                    </div>
          <!-- Search Filter -->

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped custom-table datatable">
                  <thead>
                    <tr>
                      <th>Scheduled Shift</th>
                      <th>Fri 21</th>
                      <th>Sat 22</th>
                      <th>Sun 23</th>
                      <th>Mon 24</th>
                                            <th>Tue 25</th>
                                            <th>Wed 26</th>
                                            <th>Thu 27</th>
                                            <th>Fri 28</th>
                                            <th>Sat 29</th>
                    </tr>
                  </thead>
                  <tbody>
                                        <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></router-link>
                          <router-link to="/profile">John Doe <span>Web Designer</span></router-link>
                        </h2>
                      </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></router-link>
                          <router-link to="/profile">Richard Miles <span>Web Developer</span></router-link>
                        </h2>
                      </td>
                      
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                          
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></router-link>
                          <router-link to="/profile">John Smith <span>Android Developer</span></router-link>
                        </h2>
                      </td>
                      
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></router-link>
                          <router-link to="/profile">Mike Litorus <span>IOS Developer</span></router-link>
                        </h2>
                      </td>
                      
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-11.jpg"></router-link>
                          <router-link to="/profile">Wilmer Deluna <span>Team Leader</span></router-link>
                        </h2>
                      </td>
                      
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-12.jpg"></router-link>
                          <router-link to="/profile">Jeffrey Warden <span>Web Developer</span></router-link>
                        </h2>
                      </td>
                      
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-13.jpg"></router-link>
                          <router-link to="/profile">Bernardo Galaviz <span>Web Developer</span></router-link>
                        </h2>
                      </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- /Content End -->
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
      
        <!-- Add Schedule Modal -->
        <div id="add_schedule" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Schedule</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Department <span class="text-danger">*</span></label>
                        <select class="select">
                                                    <option value="">Select</option>
                          <option value="">Development</option>
                                                    <option value="1">Finance</option>
                                                    <option value="2">Finance and Management</option>
                                                    <option value="3">Hr & Finance</option>
                                                    <option value="4">ITech</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                                            <div class="form-group">
                        <label class="col-form-label">Employee Name <span class="text-danger">*</span></label>
                        <select class="select">
                          <option value="">Select </option>
                                                    <option value="1">Richard Miles </option>
                                                    <option value="2">John Smith</option>
                                                    <option value="3">Mike Litorus </option>
                                                    <option value="4">Wilmer Deluna</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="col-form-label">Date</label>
                                                <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                                            </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Shifts <span class="text-danger">*</span></label>
                        <select class="select">
                          <option value="">Select </option>
                                                    <option value="1">10'o clock Shift</option>
                                                    <option value="2">10:30 shift</option>
                                                    <option value="3">Daily Shift </option>
                                                    <option value="4">New Shift</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Min Start Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Start Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Max Start Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Min End Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">End Time   <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Max End Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Break Time  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Accept Extra Hours </label>
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="customSwitch1" checked="">
                          <label class="form-check-label" for="customSwitch1"></label>
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Publish </label>
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="customSwitch2" checked="">
                          <label class="form-check-label" for="customSwitch2"></label>
                          </div>
                      </div>
                    </div>
                  </div>
                
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
                <!-- /Add Schedule Modal -->
                
                <!-- Edit Schedule Modal -->
        <div id="edit_schedule" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Schedule</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Department <span class="text-danger">*</span></label>
                        <select class="select">
                                                    <option value="">Select</option>
                          <option selected value="">Development</option>
                                                    <option value="1">Finance</option>
                                                    <option value="2">Finance and Management</option>
                                                    <option value="3">Hr & Finance</option>
                                                    <option value="4">ITech</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                                            <div class="form-group">
                        <label class="col-form-label">Employee Name <span class="text-danger">*</span></label>
                        <select class="select">
                          <option value="">Select </option>
                                                    <option selected value="1">Richard Miles </option>
                                                    <option value="2">John Smith</option>
                                                    <option value="3">Mike Litorus </option>
                                                    <option value="4">Wilmer Deluna</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="col-form-label">Date <span class="text-danger">*</span></label>
                                                <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                                            </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Shifts <span class="text-danger">*</span></label>
                        <select class="select">
                          <option value="">Select </option>
                                                    <option value="1">10'o clock Shift</option>
                                                    <option value="2">10:30 shift</option>
                                                    <option value="3">Daily Shift </option>
                                                    <option  selected value="4">New Shift</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Min Start Time  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" value="06:11 am">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Start Time  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" value="06:30 am">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Max Start Time  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" value="08:12 am">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Min End Time  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" value="09:12 pm">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">End Time   <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" value="09:30 pm">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Max End Time <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" value="09:45 pm">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Break Time  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" value="45">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="custom-control form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck1">
                        <label class="form-check-label" for="customCheck1">Recurring Shift</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Repeat Every</label>
                        <select class="select">
                          <option value="">1 </option>
                                                    <option value="1">2</option>
                                                    <option value="2">3</option>
                                                    <option value="3">4</option>
                          <option  selected value="4">5</option>
                          <option value="3">6</option>
                        </select>
                        <label class="col-form-label">Week(s)</label>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group wday-box">
                        
                          <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="monday" class="days recurring" checked="" onclick="return false;"><span class="checkmark">M</span></label>
        
                          <label class="checkbox-inline ms-1"><input type="checkbox" name="week_days[]" value="tuesday" class="days recurring" checked="" onclick="return false;"><span class="checkmark">T</span></label>
                        
                          <label class="checkbox-inline ms-1"><input type="checkbox" name="week_days[]" value="wednesday" class="days recurring" checked="" onclick="return false;"><span class="checkmark">W</span></label>
                        
                          <label class="checkbox-inline ms-1"><input type="checkbox" name="week_days[]" value="thursday" class="days recurring" checked="" onclick="return false;"><span class="checkmark">T</span></label>
                        
                          <label class="checkbox-inline ms-1"><input type="checkbox" name="week_days[]" value="friday" class="days recurring" checked="" onclick="return false;"><span class="checkmark">F</span></label>
                        
                          <label class="checkbox-inline ms-1"><input type="checkbox" name="week_days[]" value="saturday" class="days recurring" onclick="return false;"><span class="checkmark">S</span></label>
                        
                          <label class="checkbox-inline ms-1"><input type="checkbox" name="week_days[]" value="sunday" class="days recurring" onclick="return false;"><span class="checkmark">S</span></label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="col-form-label">End On <span class="text-danger">*</span></label>
                                                <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                                            </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="custom-control form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck2">
                        <label class="form-check-label" for="customCheck2">Indefinite</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Accept Extra Hours </label>
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="customSwitch3" checked="">
                          <label class="form-check-label" for="customSwitch3"></label>
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Publish </label>
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="customSwitch4" checked="">
                          <label class="form-check-label" for="customSwitch4"></label>
                          </div>
                      </div>
                    </div>
                  </div>
                
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Schedule Modal -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  
  // Datatable

  if($('.datatable').length > 0) {
    $('.datatable').DataTable({
      "bFilter": false,
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'shiftscheduling'
  }
</Script>