<template>
  <div class="tablesbasic">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Basic Tables</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Basic Tables</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Basic Table</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>John</td>
                          <td>Doe</td>
                          <td>john@example.com</td>
                        </tr>
                        <tr>
                          <td>Mary</td>
                          <td>Moe</td>
                          <td>mary@example.com</td>
                        </tr>
                        <tr>
                          <td>July</td>
                          <td>Dooley</td>
                          <td>july@example.com</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Striped Rows</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>John</td>
                          <td>Doe</td>
                          <td>john@example.com</td>
                        </tr>
                        <tr>
                          <td>Mary</td>
                          <td>Moe</td>
                          <td>mary@example.com</td>
                        </tr>
                        <tr>
                          <td>July</td>
                          <td>Dooley</td>
                          <td>july@example.com</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Bordered Table</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>John</td>
                          <td>Doe</td>
                          <td>john@example.com</td>
                        </tr>
                        <tr>
                          <td>Mary</td>
                          <td>Moe</td>
                          <td>mary@example.com</td>
                        </tr>
                        <tr>
                          <td>July</td>
                          <td>Dooley</td>
                          <td>july@example.com</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Hover Rows</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>John</td>
                          <td>Doe</td>
                          <td>john@example.com</td>
                        </tr>
                        <tr>
                          <td>Mary</td>
                          <td>Moe</td>
                          <td>mary@example.com</td>
                        </tr>
                        <tr>
                          <td>July</td>
                          <td>Dooley</td>
                          <td>july@example.com</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Contextual Classes</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Default</td>
                          <td>Defaultson</td>
                          <td>def@somemail.com</td>
                        </tr>
                        <tr class="table-primary">
                          <td>Primary</td>
                          <td>Doe</td>
                          <td>john@example.com</td>
                        </tr>
                        <tr class="table-secondary">
                          <td>Secondary</td>
                          <td>Moe</td>
                          <td>mary@example.com</td>
                        </tr>
                        <tr class="table-success">
                          <td>Success</td>
                          <td>Dooley</td>
                          <td>july@example.com</td>
                        </tr>
                        <tr class="table-danger">
                          <td>Danger</td>
                          <td>Refs</td>
                          <td>bo@example.com</td>
                        </tr>
                        <tr class="table-warning">
                          <td>Warning</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                        <tr class="table-info">
                          <td>Info</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                        <tr class="table-light">
                          <td>Light</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                        <tr class="table-dark">
                          <td>Dark</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Responsive Tables</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Age</th>
                          <th>City</th>
                          <th>Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>      
      </div>
      <!-- /Main Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }

    },
    name: 'tablesbasic'
  }
</Script>