<template>
  <div class="filemanager">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <div class="row">
            <div class="col-sm-12">
              <div class="file-wrap">
                <div class="file-sidebar">
                  <div class="file-header justify-content-center">
                    <span>Projects</span>
                    <a href="javascript:void(0);" class="file-side-close"><i class="fa fa-times"></i></a>
                  </div>
                  <form class="file-search">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <i class="fa fa-search"></i>
                      </div>
                      <input type="text" class="form-control rounded-pill" placeholder="Search">
                    </div>
                  </form>
                  <div class="file-pro-list">
                    <div class="file-scroll">
                      <ul class="file-menu">
                        <li class="active">
                          <a href="#">All Projects</a>
                        </li>
                        <li>
                          <a href="#">Office Management</a>
                        </li>
                        <li>
                          <a href="#">Video Calling App</a>
                        </li>
                        <li>
                          <a href="#">Hospital Administration</a>
                        </li>
                        <li>
                          <a href="#">Virtual Host</a>
                        </li>
                      </ul>
                      <div class="show-more">
                        <a href="#">Show More</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="file-cont-wrap">
                  <div class="file-cont-inner">
                    <div class="file-cont-header">
                      <div class="file-options">
                        <a href="javascript:void(0)" id="file_sidebar_toggle" class="file-sidebar-toggle">
                          <i class="fa fa-bars"></i>
                        </a>
                      </div>
                      <span>File Manager</span>
                      <div class="file-options">
                        <span class="btn-file"><input type="file" class="upload"><i class="fa fa-upload"></i></span>
                      </div>
                    </div>
                    <div class="file-content">
                      <form class="file-search">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <i class="fa fa-search"></i>
                          </div>
                          <input type="text" class="form-control rounded-pill" placeholder="Search">
                        </div>
                      </form>
                      <div class="file-body">
                        <div class="file-scroll">
                          <div class="file-content-inner">
                            <h4>Recent Files</h4>
                            <div class="row row-sm">
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-pdf-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Sample.pdf</a></h6>
                                    <span>10.45kb</span>
                                  </div>
                                  <div class="card-footer">
                                    <span class="d-none d-sm-inline">Last Modified: </span>1 min ago
                                  </div>
                                </div>
                              </div>

                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-word-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Document.docx</a></h6>
                                    <span>22.67kb</span>
                                  </div>
                                  <div class="card-footer">
                                    <span class="d-none d-sm-inline">Last Modified: </span>30 mins ago
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-image-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">icon.png</a></h6>
                                    <span>12.47kb</span>
                                  </div>
                                  <div class="card-footer">
                                    <span class="d-none d-sm-inline">Last Modified: </span>1 hour ago
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-excel-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Users.xls</a></h6>
                                    <span>35.11kb</span>
                                  </div>
                                  <div class="card-footer">23 Jul 6:30 PM</div>
                                </div>
                              </div>

                            </div>

                            <h4>Files</h4>
                            <div class="row row-sm">
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-word-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Updates.docx</a></h6>
                                    <span>12mb</span>
                                  </div>
                                  <div class="card-footer">9 Aug 1:17 PM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-powerpoint-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Vision.ppt</a></h6>
                                    <span>72.50kb</span>
                                  </div>
                                  <div class="card-footer">6 Aug 11:42 AM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-audio-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Voice.mp3</a></h6>
                                    <span>2.17mb</span>
                                  </div>
                                  <div class="card-footer">
                                    <span class="d-none d-sm-inline">Last Modified: </span>30 Jul 9:00 PM
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-pdf-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Tutorials.pdf</a></h6>
                                    <span>8.2mb</span>
                                  </div>
                                  <div class="card-footer">21 Jul 10:45 PM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-excel-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Tasks.xls</a></h6>
                                    <span>92.82kb</span>
                                  </div>
                                  <div class="card-footer">16 Jun 4:59 PM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-image-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Page.psd</a></h6>
                                    <span>118.71mb</span>
                                  </div>
                                  <div class="card-footer">14 Jun 9:00 AM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-text-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">License.txt</a></h6>
                                    <span>18.7kb</span>
                                  </div>
                                  <div class="card-footer">5 May 8:21 PM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-word-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Expenses.docx</a></h6>
                                    <span>66.2kb</span>
                                  </div>
                                  <div class="card-footer">24 Apr 7:50 PM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-audio-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Music.mp3</a></h6>
                                    <span>3.6mb</span>
                                  </div>
                                  <div class="card-footer">13 Mar 2:00 PM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-text-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Installation.txt</a></h6>
                                    <span>43.9kb</span>
                                  </div>
                                  <div class="card-footer">26 Feb 5:01 PM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-video-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">Workflow.mp4</a></h6>
                                    <span>47.65mb</span>
                                  </div>
                                  <div class="card-footer">3 Jan 11:35 AM</div>
                                </div>
                              </div>
                              
                              <div class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                <div class="card card-file">
                                  <div class="dropdown-file">
                                    <a href="" class="dropdown-link" data-bs-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a href="#" class="dropdown-item">View Details</a>
                                      <a href="#" class="dropdown-item">Share</a>
                                      <a href="#" class="dropdown-item">Download</a>
                                      <a href="#" class="dropdown-item">Rename</a>
                                      <a href="#" class="dropdown-item">Delete</a>
                                    </div>
                                  </div>
                                  <div class="card-file-thumb">
                                    <i class="fa fa-file-code-o"></i>
                                  </div>
                                  <div class="card-body">
                                    <h6><a href="">index.html</a></h6>
                                    <span>23.7kb</span>
                                  </div>
                                  <div class="card-footer">1 Jan 8:55 AM</div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }

    },
    name: 'filemanager'
  }
</Script>