<template>
  <div class="employeedashboard">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="welcome-box">
                <div class="welcome-img">
                  <img alt="" src="../assets/img/profiles/avatar-02.jpg">
                </div>
                <div class="welcome-det">
                  <h3>Welcome, John Doe</h3>
                  <p>Monday, 20 May 2019</p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-lg-8 col-md-8">
              <section class="dash-section">
                <h1 class="dash-sec-title">Today</h1>
                <div class="dash-sec-content">
                  <div class="dash-info-list">
                    <a href="#" class="dash-card text-danger">
                      <div class="dash-card-container">
                        <div class="dash-card-icon">
                          <i class="fa fa-hourglass-o"></i>
                        </div>
                        <div class="dash-card-content">
                          <p>Richard Miles is off sick today</p>
                        </div>
                        <div class="dash-card-avatars">
                          <div class="e-avatar"><img src="../assets/img/profiles/avatar-09.jpg" alt=""></div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div class="dash-info-list">
                    <a href="#" class="dash-card">
                      <div class="dash-card-container">
                        <div class="dash-card-icon">
                          <i class="fa fa-suitcase"></i>
                        </div>
                        <div class="dash-card-content">
                          <p>You are away today</p>
                        </div>
                        <div class="dash-card-avatars">
                          <div class="e-avatar"><img src="../assets/img/profiles/avatar-02.jpg" alt=""></div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div class="dash-info-list">
                    <a href="#" class="dash-card">
                      <div class="dash-card-container">
                        <div class="dash-card-icon">
                          <i class="fa fa-building-o"></i>
                        </div>
                        <div class="dash-card-content">
                          <p>You are working from home today</p>
                        </div>
                        <div class="dash-card-avatars">
                          <div class="e-avatar"><img src="../assets/img/profiles/avatar-02.jpg" alt=""></div>
                        </div>
                      </div>
                    </a>
                  </div>

                </div>
              </section>

              <section class="dash-section">
                <h1 class="dash-sec-title">Tomorrow</h1>
                <div class="dash-sec-content">
                  <div class="dash-info-list">
                    <div class="dash-card">
                      <div class="dash-card-container">
                        <div class="dash-card-icon">
                          <i class="fa fa-suitcase"></i>
                        </div>
                        <div class="dash-card-content">
                          <p>2 people will be away tomorrow</p>
                        </div>
                        <div class="dash-card-avatars">
                          <a href="#" class="e-avatar"><img src="../assets/img/profiles/avatar-04.jpg" alt=""></a>
                          <a href="#" class="e-avatar"><img src="../assets/img/profiles/avatar-08.jpg" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="dash-section">
                <h1 class="dash-sec-title">Next seven days</h1>
                <div class="dash-sec-content">
                  <div class="dash-info-list">
                    <div class="dash-card">
                      <div class="dash-card-container">
                        <div class="dash-card-icon">
                          <i class="fa fa-suitcase"></i>
                        </div>
                        <div class="dash-card-content">
                          <p>2 people are going to be away</p>
                        </div>
                        <div class="dash-card-avatars">
                          <a href="#" class="e-avatar"><img src="../assets/img/profiles/avatar-05.jpg" alt=""></a>
                          <a href="#" class="e-avatar"><img src="../assets/img/profiles/avatar-07.jpg" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dash-info-list">
                    <div class="dash-card">
                      <div class="dash-card-container">
                        <div class="dash-card-icon">
                          <i class="fa fa-user-plus"></i>
                        </div>
                        <div class="dash-card-content">
                          <p>Your first day is going to be  on Thursday</p>
                        </div>
                        <div class="dash-card-avatars">
                          <div class="e-avatar"><img src="../assets/img/profiles/avatar-02.jpg" alt=""></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dash-info-list">
                    <a href="" class="dash-card">
                      <div class="dash-card-container">
                        <div class="dash-card-icon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <div class="dash-card-content">
                          <p>It's Spring Bank Holiday  on Monday</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-lg-4 col-md-4">
              <div class="dash-sidebar">
                <section>
                  <h5 class="dash-title">Projects</h5>
                  <div class="card">
                    <div class="card-body">
                      <div class="time-list">
                        <div class="dash-stats-list">
                          <h4>71</h4>
                          <p>Total Tasks</p>
                        </div>
                        <div class="dash-stats-list">
                          <h4>14</h4>
                          <p>Pending Tasks</p>
                        </div>
                      </div>
                      <div class="request-btn">
                        <div class="dash-stats-list">
                          <h4>2</h4>
                          <p>Total Projects</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 class="dash-title">Your Leave</h5>
                  <div class="card">
                    <div class="card-body">
                      <div class="time-list">
                        <div class="dash-stats-list">
                          <h4>4.5</h4>
                          <p>Leave Taken</p>
                        </div>
                        <div class="dash-stats-list">
                          <h4>12</h4>
                          <p>Remaining</p>
                        </div>
                      </div>
                      <div class="request-btn">
                        <a class="btn btn-primary" href="#">Apply Leave</a>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 class="dash-title">Your time off allowance</h5>
                  <div class="card">
                    <div class="card-body">
                      <div class="time-list">
                        <div class="dash-stats-list">
                          <h4>5.0 Hours</h4>
                          <p>Approved</p>
                        </div>
                        <div class="dash-stats-list">
                          <h4>15 Hours</h4>
                          <p>Remaining</p>
                        </div>
                      </div>
                      <div class="request-btn">
                        <a class="btn btn-primary" href="#">Apply Time Off</a>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 class="dash-title">Upcoming Holiday</h5>
                  <div class="card">
                    <div class="card-body text-center">
                      <h4 class="holiday-title mb-0">Mon 20 May 2019 - Ramzan</h4>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

        </div>
        <!-- /Page Content -->

            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    $(window).on ('load', function (){
  $('#loader').delay(100).fadeOut('slow');
  $('#loader-wrapper').delay(500).fadeOut('slow');
});
    },
    name: 'employeedashboard'
  }
</Script>