<template>
  <div class="userdashboard">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">User Job Dashboard</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item">Jobs</li>
                  <li class="breadcrumb-item">User Dashboard</li>
                  <li class="breadcrumb-item">User Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Content Starts -->
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Solid justified</h4> -->
              <ul class="nav nav-tabs nav-tabs-solid nav-justified">
                <li class="nav-item"><router-link class="nav-link active" to="/user-dashboard">Dashboard</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/user-all-jobs">All </router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/saved-jobs">Saved</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/applied-jobs">Applied</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/interviewing">Interviewing</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/offered-jobs">Offered</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/visited-jobs">Visitied </router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/archived-jobs">Archived </router-link></li>
              </ul>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-file-text-o"></i></span>
                  <div class="dash-widget-info">
                    <h3>110</h3>
                    <span>Offered</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-clipboard"></i></span>
                  <div class="dash-widget-info">
                    <h3>40</h3>
                    <span>Applied</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-retweet"></i></span>
                  <div class="dash-widget-info">
                    <h3>374</h3>
                    <span>Visited</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-floppy-o"></i></span>
                  <div class="dash-widget-info">
                    <h3>220</h3>
                    <span>Saved</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
                
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 text-center d-flex">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <h3 class="card-title">Overview</h3>
                      <canvas id="lineChart"></canvas>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <h3 class="card-title text-center">Latest Jobs</h3>
                      <ul class="list-group">
                          <li class="list-group-item list-group-item-action">UI Developer <span class="float-end text-sm text-muted">1 Hours ago</span></li>
                          <li class="list-group-item list-group-item-action">Android Developer <span class="float-end text-sm text-muted">1 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">IOS Developer<span class="float-end text-sm text-muted">2 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">PHP Developer<span class="float-end text-sm text-muted">3 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">UI Developer<span class="float-end text-sm text-muted">3 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">PHP Developer<span class="float-end text-sm text-muted">4 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">UI Developer<span class="float-end text-sm text-muted">4 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">Android Developer<span class="float-end text-sm text-muted">6 Days ago</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            
          <div class="row">
            <div class="col-md-12">
              <div class="card card-table">
                <div class="card-header">
                  <h3 class="card-title mb-0">Offered Jobs</h3>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-nowrap custom-table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Title</th>
                          <th>Department</th>
                          <th class="text-center">Job Type</th>
                          <th class="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td><router-link to="/job-details">Web Developer</router-link></td>
                          <td>Development</td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Full Time
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <a href="#" class="btn btn-sm btn-info download-offer"><span><i class="fa fa-download me-1"></i> Download Offer</span></a>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td><router-link to="/job-details">Web Designer</router-link></td>
                          <td>Designing</td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-success"></i> Part Time
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <a href="#" class="btn btn-sm btn-info download-offer"><span><i class="fa fa-download me-1"></i> Download Offer</span></a>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td><router-link to="/job-details">Android Developer</router-link></td>
                          <td>Android</td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Internship
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <a href="#" class="btn btn-sm btn-info download-offer"><span><i class="fa fa-download me-1"></i> Download Offer</span></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
                        
          <div class="row">
            <div class="col-md-12">
              <div class="card card-table">
                <div class="card-header">
                  <h3 class="card-title mb-0">Applied Jobs</h3>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-nowrap custom-table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Title</th>
                          <th>Department</th>
                          <th>Start Date</th>
                          <th>Expire Date</th>
                          <th class="text-center">Job Type</th>
                          <th class="text-center">Status</th>
                          <th class="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td><router-link to="/job-details">Web Developer</router-link></td>
                          <td>Development</td>
                          <td>3 Mar 2019</td>
                          <td>31 May 2019</td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Full Time
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Open
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td><router-link to="/job-details">Web Designer</router-link></td>
                          <td>Designing</td>
                          <td>3 Mar 2019</td>
                          <td>31 May 2019</td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-success"></i> Part Time
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-success"></i> Closed
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td><router-link to="/job-details">Android Developer</router-link></td>
                          <td>Android</td>
                          <td>3 Mar 2019</td>
                          <td>31 May 2019</td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Internship
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="action-label">
                              <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Cancelled
                              </a>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <!-- /Content End -->
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
      // Line Chart
  
  var ctx = document.getElementById("lineChart").getContext('2d');
  var lineChart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: ["Jan", "Feb",  "Mar",  "Apr",  "May"],
      datasets: [{
        label: 'UI Developer',
        data: [20,  10, 5,  5,  20],
        fill: false,
        borderColor: '#373651',
        backgroundColor: '#373651',
        borderWidth: 1
      },
            {
        label: 'Android',
        data: [2, 2,  3,  4,  1],
        fill: false,
        borderColor: '#E65A26',
        backgroundColor: '#E65A26',
        borderWidth: 1
      },
            {
        label: 'Web Designing',
        data: [1, 3,  6,  8,  10],
        fill: false,
        borderColor: '#a1a1a1',
        backgroundColor: '#a1a1a1',
        borderWidth: 1
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false
      }
    }
  });
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }

    },
    name: 'userdashboard'
  }
</Script>