import {createRouter, createWebHistory} from 'vue-router';
import index from '../components/index'
import employeedashboard from '../components/employeedashboard'
import activities from '../components/pages/activities'
import appliedjobs from '../components/jobs/appliedjobs'
import approvalsetting from '../components/setting/approvalsetting'
import apptituderesult from '../components/jobs/apptituderesult'
import archivedjobs from '../components/jobs/archivedjobs'
import assets1 from '../components/pages/assets1'
import attendanceemployee from '../components/employee/attendanceemployee'
import attendancereports from '../components/reports/attendancereports'
import attendance from '../components/employee/attendance'
import blankpage from '../components/pages/blankpage'
import budgetexpenses from '../components/accounting/budgetexpenses'
import budgetrevenues from '../components/accounting/budgetrevenues'
import budgets from '../components/accounting/budgets'
import candidates from '../components/jobs/candidates'
import categories from '../components/accounting/categories'
import changepassword from '../components/setting/changepassword'
import chat from '../components/pages/chat'
import clientprofile from '../components/profile/clientprofile'
import clientslist from '../components/pages/clientslist'
import clients from '../components/pages/clients'
import components from '../components/pages/components'
import compose from '../components/app/compose'
import contacts from '../components/app/contacts'
import createestimate from '../components/sales/createestimate'
import createinvoice from '../components/sales/createinvoice'
import cronsetting from '../components/setting/cronsetting'
import dailyreports from '../components/reports/dailyreports'
import datatables from '../components/table/datatables'
import departments from '../components/employee/departments'
import designations from '../components/employee/designations'
import editestimate from '../components/sales/editestimate'
import editinvoice from '../components/sales/editinvoice'
import emailsettings from '../components/setting/emailsettings'
import employeereports from '../components/reports/employeereports'
import employeeslist from '../components/employee/employeeslist'
import employees from '../components/employee/employees'
import error404 from '../components/pages/error404'
import error500 from '../components/pages/error500'
import estimateview from '../components/sales/estimateview'
import estimates from '../components/sales/estimates'
import events from '../components/app/events'
import expensereports from '../components/reports/expensereports'
import expenses from '../components/reports/expenses'
import experiancelevel from '../components/jobs/experiancelevel'
import faq from '../components/pages/faq'
import filemanager from '../components/app/filemanager'
import forgotpassword from '../components/pages/forgotpassword'
import formbasicinputs from '../components/form/formbasicinputs'
import formhorizontal from '../components/form/formhorizontal'
import forminputgroups from '../components/form/forminputgroups'
import formmask from '../components/form/formmask'
import formvalidation from '../components/form/formvalidation'
import formvertical from '../components/form/formvertical'
import goaltracking from '../components/goals/goaltracking'
import goaltype from '../components/goals/goaltype'
import holidays from '../components/employee/holidays'
import inbox from '../components/app/inbox'
import incomingcall from '../components/app/incomingcall'
import interviewquestions from '../components/jobs/interviewquestions'
import interviewing from '../components/jobs/interviewing'
import invoicereports from '../components/reports/invoicereports'
import invoicesettings from '../components/setting/invoicesettings'
import invoiceview from '../components/sales/invoiceview'
import invoices from '../components/sales/invoices'
import jobapplicants from '../components/jobs/jobapplicants'
import jobaptitude from '../components/jobs/jobaptitude'
import jobdetails from '../components/jobs/jobdetails'
import joblist from '../components/jobs/joblist'
import jobview from '../components/jobs/jobview'
import jobsdashboard from '../components/jobs/jobsdashboard'
import jobs from '../components/jobs/jobs'
import knowledgebaseview from '../components/pages/knowledgebaseview'
import knowledgebase from '../components/pages/knowledgebase'
import leads from '../components/pages/leads'
import leavereports from '../components/reports/leavereports'
import leavesettings from '../components/employee/leavesettings'
import leavetype from '../components/setting/leavetype'
import leavesemployee from '../components/employee/leavesemployee'
import leaves from '../components/employee/leaves'
import localization from '../components/setting/localization'
import lockscreen from '../components/pages/lockscreen'
import login from '../components/pages/login'
import mailview from '../components/pages/mailview'
import manageresumes from '../components/jobs/manageresumes'
import notificationssettings from '../components/setting/notificationssettings'
import offerapprovals from '../components/jobs/offerapprovals'
import offeredjobs from '../components/jobs/offeredjobs'
import otp from '../components/pages/otp'
import outgoingcall from '../components/app/outgoingcall'
import overtime from '../components/employee/overtime'
import paymentreports from '../components/reports/paymentreports'
import payments from '../components/sales/payments'
import payrollitems from '../components/payroll/payrollitems'
import payslipreports from '../components/reports/payslipreports'
import performanceappraisal from '../components/performance/performanceappraisal'
import performanceindicator from '../components/performance/performanceindicator'
import performancesetting from '../components/performance/performancesetting'
import performance from '../components/performance/performance'
import policies from '../components/pages/policies'
import privacypolicy from '../components/pages/privacypolicy'
import profile from '../components/profile/profile'
import projectlist from '../components/project/projectlist'
import projectreports from '../components/reports/projectreports'
import projectview from '../components/project/projectview'
import projects from '../components/project/projects'
import promotion from '../components/pages/promotion'
import providentfund from '../components/sales/providentfund'
import questions from '../components/jobs/questions'
import register from '../components/pages/register'
import resignation from '../components/pages/resignation'
import rolespermissions from '../components/setting/rolespermissions'
import salarysettings from '../components/setting/salarysettings'
import salaryview from '../components/payroll/salaryview'
import salary from '../components/payroll/salary'
import savedjobs from '../components/jobs/savedjobs'
import scheduletiming from '../components/jobs/scheduletiming'
import search from '../components/pages/search'
import settings from '../components/setting/settings'
import shiftlist from '../components/employee/shiftlist'
import shiftscheduling from '../components/employee/shiftscheduling'
import shortlistcandidates from '../components/jobs/shortlistcandidates'
import subcategory from '../components/accounting/subcategory'
import subscribedcompanies from '../components/subscriptions/subscribedcompanies'
import subscriptionscompany from '../components/subscriptions/subscriptionscompany'
import subscriptions from '../components/subscriptions/subscriptions'
import tablesbasic from '../components/table/tablesbasic'
import taskboard from '../components/project/taskboard'
import taskreports from  '../components/reports/taskreports'
import tasks from '../components/pages/tasks'
import taxes from '../components/sales/taxes'
import termination from '../components/pages/termination'
import terms from '../components/pages/terms'
import themesettings from '../components/setting/themesettings'
import ticketview from '../components/pages/ticketview'
import tickets from '../components/pages/tickets'
import timesheet from '../components/employee/timesheet'
import toxboxsetting from '../components/setting/toxboxsetting'
import trainers from '../components/training/trainers'
import trainingtype from '../components/training/trainingtype'
import training from '../components/training/training'
import useralljobs from '../components/jobs/useralljobs'
import userdashboard from '../components/jobs/userdashboard'
import userreports from '../components/reports/userreports'
import users from '../components/reports/users'
import videocall from '../components/app/videocall'
import visitedjobs from '../components/jobs/visitedjobs'
import voicecall from '../components/app/voicecall'
const routes = [
    {
      path: '/',
      name: 'index',
      component: index,
    },
    {
      path: '/index',
      name: 'indexs',
      component: index,
    },
    {
      path: '/employee-dashboard',
      name: 'employee-dashboard',
      component: employeedashboard,
    },
    {
      path: '/activities',
      name: 'activities',
      component: activities,
    },
    {
      path: '/applied-jobs',
      name: 'applied-jobs',
      component: appliedjobs,
    },
    {
      path: '/approval-setting',
      name: 'approval-setting',
      component: approvalsetting,
    },
    {
      path: '/apptitude-result',
      name: 'apptitude-result',
      component: apptituderesult,
    },
    {
      path: '/archived-jobs',
      name: 'archived-jobs',
      component: archivedjobs,
    },
    {
      path: '/assets',
      name: 'assets',
      component: assets1,
    },
    {
      path: '/attendance-employee',
      name: 'attendance-employee',
      component: attendanceemployee,
    },
    {
      path: '/attendance-reports',
      name: 'attendance-reports',
      component: attendancereports,
    },
    {
      path: '/attendance',
      name: 'attendance',
      component: attendance,
    },
    {
      path: '/blank-page',
      name: 'blank-page',
      component: blankpage,
    },
    {
      path: '/budget-expenses',
      name: 'budget-expenses',
      component: budgetexpenses,
    },
    {
      path: '/budget-revenues',
      name: 'budget-revenues',
      component: budgetrevenues,
    },
    {
      path: '/budgets',
      name: 'budgets',
      component: budgets,
    },
    {
      path: '/candidates',
      name: 'candidates',
      component: candidates,
    },
    {
      path: '/categories',
      name: 'categories',
      component: categories,
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: changepassword,
    },
    {
      path: '/chat',
      name: 'chat',
      component: chat,
    },
    {
      path: '/client-profile',
      name: 'client-profile',
      component: clientprofile,
    },
    {
      path: '/clients-list',
      name: 'clients-list',
      component: clientslist,
    },
    {
      path: '/clients',
      name: 'clients',
      component: clients,
    },
    {
      path: '/components',
      name: 'components',
      component: components,
    },
    {
      path: '/compose',
      name: 'compose',
      component: compose,
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: contacts,
    },
    {
      path: '/create-estimate',
      name: 'create-estimate',
      component: createestimate,
    },
    {
      path: '/create-invoice',
      name: 'create-invoice',
      component: createinvoice,
    },
    {
      path: '/cron-setting',
      name: 'cron-setting',
      component: cronsetting,
    },
    {
      path: '/daily-reports',
      name: 'daily-reports',
      component: dailyreports,
    },
     {
      path: '/data-tables',
      name: 'data-tables',
      component: datatables,
    },
     {
      path: '/departments',
      name: 'departments',
      component: departments,
    },
    {
      path: '/designations',
      name: 'designations',
      component: designations,
    },
    {
      path: '/edit-estimate',
      name: 'edit-estimate',
      component: editestimate,
    },
    {
      path: '/edit-invoice',
      name: 'edit-invoice',
      component: editinvoice,
    },
    {
      path: '/email-settings',
      name: 'email-settings',
      component: emailsettings,
    },
    {
      path: '/employee-reports',
      name: 'employee-reports',
      component: employeereports,
    },
    {
      path: '/employees-list',
      name: 'employees-list',
      component: employeeslist,
    },
    {
      path: '/employees',
      name: 'employees',
      component: employees,
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: error404,
    },
    {
      path: '/error-500',
      name: 'error-500',
      component: error500,
    },
    {
      path: '/estimate-view',
      name: 'estimate-view',
      component: estimateview,
    },
    {
      path: '/estimates',
      name: 'estimates',
      component: estimates,
    },
    {
      path: '/events',
      name: 'events',
      component: events,
    },
    {
      path: '/expense-reports',
      name: 'expense-reports',
      component: expensereports,
    },
    {
      path: '/expenses',
      name: 'expenses',
      component: expenses,
    },
    {
      path: '/experiance-level',
      name: 'experiance-level',
      component: experiancelevel,
    },
    {
      path: '/faq',
      name: 'faq',
      component: faq,
    },
    {
      path: '/file-manager',
      name: 'file-manager',
      component: filemanager,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: forgotpassword,
    },
     {
      path: '/form-basic-inputs',
      name: 'form-basic-inputs',
      component: formbasicinputs,
    },
    {
      path: '/form-horizontal',
      name: 'form-horizontal',
      component: formhorizontal,
    },
    {
      path: '/form-input-groups',
      name: 'form-input-groups',
      component: forminputgroups,
    },
    {
      path: '/form-mask',
      name: 'form-mask',
      component: formmask,
    },
     {
      path: '/form-validation',
      name: 'form-validation',
      component: formvalidation,
    },
    {
      path: '/form-vertical',
      name: 'form-vertical',
      component: formvertical,
    },
    {
      path: '/goal-tracking',
      name: 'goal-tracking',
      component: goaltracking,
    },
    {
      path: '/goal-type',
      name: 'goal-type',
      component: goaltype,
    },
    {
      path: '/holidays',
      name: 'holidays',
      component: holidays,
    },
    {
      path: '/inbox',
      name: 'inbox',
      component: inbox,
    },
    {
      path: '/incoming-call',
      name: 'incoming-call',
      component: incomingcall,
    },
    {
      path: '/interview-questions',
      name: 'interview-questions',
      component: interviewquestions,
    },
    {
      path: '/interviewing',
      name: 'interviewing',
      component: interviewing,
    },
    {
      path: '/invoice-reports',
      name: 'invoice-reports',
      component: invoicereports,
    },
    {
      path: '/invoice-settings',
      name: 'invoice-settings',
      component: invoicesettings,
    },
    {
      path: '/invoice-view',
      name: 'invoice-view',
      component: invoiceview,
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: invoices,
    },
    {
      path: '/job-applicants',
      name: 'job-applicants',
      component: jobapplicants,
    },
     {
      path: '/job-aptitude',
      name: 'job-aptitude',
      component: jobaptitude,
    },
    {
      path: '/job-details',
      name: 'job-details',
      component: jobdetails,
    },
    {
      path: '/job-list',
      name: 'job-list',
      component: joblist,
    },
    {
      path: '/job-view',
      name: 'job-view',
      component: jobview,
    },
    {
      path: '/jobs-dashboard',
      name: 'jobs-dashboard',
      component: jobsdashboard,
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: jobs,
    },
    {
      path: '/knowledgebase-view',
      name: 'knowledgebase-view',
      component: knowledgebaseview,
    },
    {
      path: '/knowledgebase',
      name: 'knowledgebase',
      component: knowledgebase,
    },
    {
      path: '/leads',
      name: 'leads',
      component: leads,
    },
     {
      path: '/leave-reports',
      name: 'leave-reports',
      component: leavereports,
    },
    {
      path: '/leave-settings',
      name: 'leave-settings',
      component: leavesettings,
    },
    {
      path: '/leave-type',
      name: 'leave-type',
      component: leavetype,
    },
    {
      path: '/leaves-employee',
      name: 'leaves-employee',
      component: leavesemployee,
    },
    {
      path: '/leaves',
      name: 'leaves',
      component: leaves,
    },
    {
      path: '/localization',
      name: 'localization',
      component: localization,
    },
     {
      path: '/lock-screen',
      name: 'lock-screen',
      component: lockscreen,
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/mail-view',
      name: 'mail-view',
      component: mailview,
    },
    {
      path: '/manage-resumes',
      name: 'manage-resumes',
      component: manageresumes,
    },
    {
      path: '/notifications-settings',
      name: 'notifications-settings',
      component: notificationssettings,
    },
    {
      path: '/offer_approvals',
      name: 'offer_approvals',
      component: offerapprovals,
    },
    {
      path: '/offered-jobs',
      name: 'offered-jobs',
      component: offeredjobs,
    },
    {
      path: '/otp',
      name: 'otp',
      component: otp,
    },
    {
      path: '/outgoing-call',
      name: 'outgoing-call',
      component: outgoingcall,
    },
    {
      path: '/overtime',
      name: 'overtime',
      component: overtime,
    },
    {
      path: '/payments-reports',
      name: 'payment-reports',
      component: paymentreports,
    },
    {
      path: '/payments',
      name: 'payments',
      component: payments,
    },
    {
      path: '/payroll-items',
      name: 'payroll-items',
      component: payrollitems,
    },
    {
      path: '/payslip-reports',
      name: 'payslip-reports',
      component: payslipreports,
    },
    {
      path: '/performance-appraisal',
      name: 'performance-appraisal',
      component: performanceappraisal,
    },
     {
      path: '/performance-indicator',
      name: 'performance-indicator',
      component: performanceindicator,
    },
     {
      path: '/performance-setting',
      name: 'performance-setting',
      component: performancesetting,
    },
    {
      path: '/performance',
      name: 'performance',
      component: performance,
    },
    {
      path: '/policies',
      name: 'policies',
      component: policies,
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: privacypolicy,
    },
    {
      path: '/profile',
      name: 'profile',
      component: profile,
    },
    {
      path: '/project-list',
      name: 'project-list',
      component: projectlist,
    },
    {
      path: '/project-reports',
      name: 'project-reports',
      component: projectreports,
    },
    {
      path: '/project-view',
      name: 'project-view',
      component: projectview,
    },
    {
      path: '/projects',
      name: 'projects',
      component: projects,
    },
    {
      path: '/promotion',
      name: 'promotion',
      component: promotion,
    },
    {
      path: '/provident-fund',
      name: 'provident-fund',
      component: providentfund,
    },
     {
      path: '/questions',
      name: 'questions',
      component: questions,
    },
     {
      path: '/register',
      name: 'register',
      component: register,
    },
    {
      path: '/resignation',
      name: 'resignation',
      component: resignation,
    },
    {
      path: '/roles-permissions',
      name: 'roles-permissions',
      component: rolespermissions,
    },
    {
      path: '/salary-settings',
      name: 'salary-settings',
      component: salarysettings,
    },
    {
      path: '/salary-view',
      name: 'salary-view',
      component: salaryview,
    },
    {
      path: '/salary',
      name: 'salary',
      component: salary,
    },
    {
      path: '/saved-jobs',
      name: 'saved-jobs',
      component: savedjobs,
    },
    {
      path: '/schedule-timing',
      name: 'schedule-timing',
      component: scheduletiming,
    },
    {
      path: '/search',
      name: 'search',
      component: search,
    },
    {
      path: '/settings',
      name: 'settings',
      component: settings,
    },
     {
      path: '/shift-list',
      name: 'shift-list',
      component: shiftlist,
    },
    {
      path: '/shift-scheduling',
      name: 'shift-scheduling',
      component: shiftscheduling,
    },
    {
      path: '/shortlist-candidates',
      name: 'shortlist-candidates',
      component: shortlistcandidates,
    },
    {
      path: '/sub-category',
      name: 'sub-category',
      component: subcategory,
    },
    {
      path: '/subscribed-companies',
      name: 'subscribed-companies',
      component: subscribedcompanies,
    },
    {
      path: '/subscriptions-company',
      name: 'subscriptions-company',
      component: subscriptionscompany,
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: subscriptions,
    },
    {
      path: '/tables-basic',
      name: 'tables-basic',
      component: tablesbasic,
    },
    {
      path: '/task-board',
      name: 'task-board',
      component: taskboard,
    },
    {
      path: '/task-reports',
      name: 'task-reports',
      component: taskreports,
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: tasks,
    },
    {
      path: '/taxes',
      name: 'taxes',
      component: taxes,
    },
    {
      path: '/termination',
      name: 'termination',
      component: termination,
    },
    {
      path: '/terms',
      name: 'terms',
      component: terms,
    },
    {
      path: '/theme-settings',
      name: 'theme-settings',
      component: themesettings,
    },
    {
      path: '/ticket-view',
      name: 'ticket-view',
      component: ticketview,
    },
     {
      path: '/tickets',
      name: 'tickets',
      component: tickets,
    },
    {
      path: '/timesheet',
      name: 'timesheet',
      component: timesheet,
    },
    {
      path: '/toxbox-setting',
      name: 'toxbox-setting',
      component: toxboxsetting,
    },
    {
      path: '/trainers',
      name: 'trainers',
      component: trainers,
    },
    {
      path: '/training-type',
      name: 'training-type',
      component: trainingtype,
    },
    {
      path: '/training',
      name: 'training',
      component: training,
    },
    {
      path: '/user-all-jobs',
      name: 'user-all-jobs',
      component: useralljobs,
    },
    {
      path: '/user-dashboard',
      name: 'user-dashboard',
      component: userdashboard,
    },
    {
      path: '/user-reports',
      name: 'user-reports',
      component: userreports,
    },
    {
      path: '/users',
      name: 'users',
      component: users,
    },
    {
      path: '/video-call',
      name: 'video-call',
      component: videocall,
    },
    {
      path: '/visited-jobs',
      name: 'visited-jobs',
      component: visitedjobs,
    },
     {
      path: '/voice-call',
      name: 'voice-call',
      component: voicecall,
    },
];

export const router = createRouter({
    history: createWebHistory('light'),
    linkActiveClass: 'active',
    routes
});