<template>
  <div class="login account-page" style="height: 100vh;">
   <!-- Main Wrapper -->
        <div class="main-wrapper">
      <div class="account-content">
        <router-link to="/job-list" class="btn btn-primary apply-btn">Apply Job</router-link>
        <div class="container">
        
          <!-- Account Logo -->
          <div class="account-logo">
            <router-link to="/index"><img src="../../assets/img/logo2.png" alt="Dreamguy's Technologies"></router-link>
          </div>
          <!-- /Account Logo -->
          
          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Login</h3>
              <p class="account-subtitle">Access to our dashboard</p>
              
              <!-- Account Form -->
              <form action="/index">
                <div class="form-group">
                  <label>Email Address</label>
                  <input class="form-control" type="text">
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label>Password</label>
                    </div>
                    <div class="col-auto">
                      <router-link class="text-muted" to="/forgot-password">
                        Forgot password?
                      </router-link>
                    </div>
                  </div>
                  <input class="form-control" type="password">
                </div>
                <div class="form-group text-center">
                  <button class="btn btn-primary account-btn" type="submit">Login</button>
                </div>
                <div class="account-footer">
                  <p>Don't have an account yet? <router-link to="/register">Register</router-link></p>
                </div>
              </form>
              <!-- /Account Form -->
              
            </div>
          </div>
        </div>
      </div>
        </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
    },
    name: 'login'
  }
</Script>