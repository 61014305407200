<template>
  <div class="knowledgebaseview">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">Knowledgebase</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Knowledgebase</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Content Starts -->
          <div class="row">
            <div class="col-lg-8">
              <div class="card">
                <div class="card-body">
                  <article class="post">
                    <h1 class="post-title">Lorem ipsum dolor sit amet </h1>
                    <ul class="meta">
                      <li><span>Created :</span> Feb, 04, 2016</li>
                      <li><span>Category:</span> <a href="#">Category 1</a>, <a href="#">Category 2</a></li>
                      <li><span>Last Updated:</span> April, 15, 2016</li>
                      <li><span>Comments :</span> <a href="#">5</a></li>
                      <li><span>Views :</span> 1198</li>
                    </ul>
                    <p>
                       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                    <h2>Sed ut perspiciatis unde omnis iste</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                    <h2>Sed ut perspiciatis unde omnis iste</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                  </article>
                  <div class="feedback">
                    <h3>Was this article helpful to you?</h3>
                    <p>
                      <a href="#" class="btn btn-success"><i class="fa fa-thumbs-up"></i></a>
                      <a href="#" class="btn btn-danger"><i class="fa fa-thumbs-down"></i></a>
                    </p>
                    <p>29 found this helpful</p>
                  </div>
                  
                  <div class="comment-section">
                    <div class="comments-area clearfix">
                      <h3>( 2 ) Comments</h3>
                      <ul class="comment-list">
                        <li>
                          <div class="comment">
                            <div class="comment-author">
                              <img width="86" height="86" class="avatar avatar-86 photo" src="../../assets/img/user.jpg" alt="">
                            </div>
                            <div class="comment-details">
                              <div class="author-name">
                                <a class="url" href="#">John Doe</a> <span class="commentmetadata">October 25, 2016 at 01:10 pm</span>
                              </div>
                              <div class="comment-body">
                                <p>Integer id dolor libero. Cras in turpis nulla. Vivamus at tellus erat. Nulla ligula sem, eleifend vitae semper et, blandit a elit. Nam et ultrices lectus. Ut sit amet risus eget neque scelerisque consectetur.</p>
                              </div>
                              <div class="reply"><a href="#" class="comment-reply-link" rel="nofollow"><i class="fa fa-reply"></i> Reply</a></div>
                            </div>
                          </div>
                          <ul class="children">
                            <li>
                              <div class="comment">
                                <div class="comment-author">
                                  <img width="86" height="86" class="avatar avatar-86 photo" src="../../assets/img/user.jpg" alt="">
                                </div>
                                <div class="comment-details">
                                  <div class="author-name">
                                    <a href="#" class="comment-reply-link"></a><a class="url" href="#">Astin Robert</a> <span class="commentmetadata">October 25, 2016 at 01:10 pm</span>
                                  </div>
                                  <div class="comment-body">
                                    <p>Mauris hendrerit consequat quam, sit amet fermentum metus cursus in. Nunc ac justo suscipit erat sagittis maximus a at tellus. Pellentesque congue nisi a nisl volutpat tempor.</p>
                                  </div>
                                  <div class="reply"><a href="#" class="comment-reply-link"><i class="fa fa-reply"></i> Reply</a></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div class="comment-reply">
                        <h3 class="comment-reply-title">Leave a Reply</h3>
                        <form>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" id="name" name="name" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" id="email" name="email" class="form-control">
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="website">Website</label>
                                <input type="text" id="website" name="website" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="comment">Comment *</label>
                            <textarea id="comment" name="comment" class="form-control" rows="3" cols="5"></textarea>
                          </div>
                          <div class="submit-section">
                            <button type="submit" class="btn btn-primary submit-btn">Post Comment</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="w-sidebar">
                <div class="widget widget-category">
                  <h4 class="widget-title"><i class="fa fa-folder-o"></i> Categories</h4>
                  <ul>
                    <li>
                      <a href="#">Categories 1</a>
                    </li>
                    <li>
                      <a href="#">Categories 2</a>
                    </li>
                    <li>
                      <a href="#">Categories 3</a>
                    </li>
                    <li>
                      <a href="#">Categories 4</a>
                    </li>
                    <li>
                      <a href="#">Categories 5</a>
                    </li>
                  </ul>
                </div>
                
                <div class="widget widget-category">
                  <h4 class="widget-title"><i class="fa fa-folder-o"></i> Popular Articles</h4>
                  <ul>
                    <li><a href="#"> Installation &amp; Activation </a></li>
                    <li><a href="#"> Premium Members Features </a></li>
                    <li><a href="#"> API Usage &amp; Guide lines </a></li>
                    <li><a href="#"> Getting Started &amp; What is next. </a></li>
                    <li><a href="#"> Installation &amp; Activation </a></li>
                    <li><a href="#"> Premium Members Features </a></li>
                    <li><a href="#"> API Usage &amp; Guide lines </a></li>
                    <li><a href="#"> Getting Started &amp; What is next. </a></li>
                  </ul>
                </div>
                
                <div class="widget widget-category">
                  <h4 class="widget-title"><i class="fa fa-folder-o"></i> Latest Articles</h4>
                  <ul>
                    <li><a href="#"> Installation &amp; Activation </a></li>
                    <li><a href="#"> Premium Members Features </a></li>
                    <li><a href="#"> API Usage &amp; Guide lines </a></li>
                    <li><a href="#"> Getting Started &amp; What is next. </a></li>
                    <li><a href="#"> Installation &amp; Activation </a></li>
                    <li><a href="#"> Premium Members Features </a></li>
                    <li><a href="#"> API Usage &amp; Guide lines </a></li>
                    <li><a href="#"> Getting Started &amp; What is next. </a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Content End -->
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'knowledgebaseview'
  }
</Script>