<template>
  <div class="subscriptions">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header">
            
            <div class="row">
              <div class="col">
                <h3 class="page-title">Subscriptions</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Subscriptions</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_plan"><i class="fa fa-plus"></i> Add Subscription</a>
              </div>
            </div>
      
        
          </div>
          <!-- /Page Header -->
        
          <div class="row">
            <div class="col-lg-10 mx-auto">
            
              <!-- Plan Tab -->
              <div class="row justify-content-center mb-4">
                <div class="col-auto">
                  <nav class="nav btn-group">
                    <a href="#monthly" class="btn btn-outline-secondary active show" data-bs-toggle="tab">Monthly Plan</a>
                    <a href="#annual" class="btn btn-outline-secondary" data-bs-toggle="tab">Annual Plan</a>
                  </nav>
                </div>
              </div>
              <!-- /Plan Tab -->

              <!-- Plan Tab Content -->
              <div class="tab-content">
              
                <!-- Monthly Tab -->
                <div class="tab-pane fade active show" id="monthly">
                
                  <div class="row mb-30 equal-height-cards">
                    <div class="col-md-4">
                      <div class="card pricing-box">
                        <div class="card-body d-flex flex-column">
                          <div class="mb-4">
                            <h3>Free</h3>
                            <span class="display-4">$0</span>
                          </div>
                          <ul>
                            <li><i class="fa fa-check"></i> <b>1 User</b></li>
                            <li><i class="fa fa-check"></i> 5 Projects </li>
                            <li><i class="fa fa-check"></i> 5 GB Storage</li>
                            <li><i class="fa fa-check"></i> Unlimited Message</li>
                          </ul>
                          <a href="#" class="btn btn-lg btn-secondary mt-auto" data-bs-toggle="modal" data-bs-target="#edit_plan">Edit</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card pricing-box">
                        <div class="card-body d-flex flex-column">
                          <div class="mb-4">
                            <h3>Professional</h3>
                            <span class="display-4">$21</span>
                            <span>/mo</span>
                          </div>
                          <ul>
                            <li><i class="fa fa-check"></i> <b>30 Users</b></li>
                            <li><i class="fa fa-check"></i> 50 Projects</li>
                            <li><i class="fa fa-check"></i> 100 GB Storage</li>
                            <li><i class="fa fa-check"></i> Unlimited Message</li>
                            <li><i class="fa fa-check"></i> 24/7 Customer Support</li>
                          </ul>
                          <a href="#" class="btn btn-lg btn-secondary mt-auto" data-bs-toggle="modal" data-bs-target="#edit_plan">Edit</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card pricing-box">
                        <div class="card-body d-flex flex-column">
                          <div class="mb-4">
                            <h3>Enterprise</h3>
                            <span class="display-4">$38</span>
                            <span>/mo</span>
                          </div>
                          <ul>
                            <li><i class="fa fa-check"></i> <b>Unlimited Users </b></li>
                            <li><i class="fa fa-check"></i> Unlimited Projects</li>
                            <li><i class="fa fa-check"></i> 500 GB Storage</li>
                            <li><i class="fa fa-check"></i> Unlimited Message</li>
                            <li><i class="fa fa-check"></i> Voice and Video Call</li>
                            <li><i class="fa fa-check"></i> 24/7 Customer Support</li>
                          </ul>
                          <a href="#" class="btn btn-lg btn-secondary mt-auto" data-bs-toggle="modal" data-bs-target="#edit_plan">Edit</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Monthly Plan Details -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-table mb-0">
                        <div class="card-header">
                          <h4 class="card-title mb-0">Plan Details</h4>
                        </div>
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Plan</th>
                                  <th>Plan Type</th>
                                  <th>Create Date</th>
                                  <th>Modified Date</th>
                                  <th>Amount</th>
                                  <th>Subscribed Users</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Free Trial</td>
                                  <td>Monthly</td>
                                  <td>9 Nov 2019</td>
                                  <td>8 Dec 2019</td>
                                  <td>Free</td>
                                  <td><router-link class="btn btn-info btn-sm" to="/subscribed-companies">30 Users</router-link></td>
                                </tr>
                                <tr>
                                  <td>Professional</td>
                                  <td>Monthly</td>
                                  <td>9 Nov 2019</td>
                                  <td>8 Dec 2019</td>
                                  <td>$21</td>
                                  <td><router-link class="btn btn-info btn-sm" to="/subscribed-companies">97 Users</router-link></td>
                                </tr>
                                <tr>
                                  <td>Enterprise</td>
                                  <td>Monthly</td>
                                  <td>9 Nov 2019</td>
                                  <td>8 Dec 2019</td>
                                  <td>$38</td>
                                  <td><router-link class="btn btn-info btn-sm" to="/subscribed-companies">125 Users</router-link></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Monthly Plan Details -->
                
                </div>
                <!-- /Monthly Tab -->
                
                <!-- Annual Plan Tab -->
                <div class="tab-pane fade" id="annual">
                  <div class="row mb-30 equal-height-cards">
                    <div class="col-md-4">
                      <div class="card pricing-box">
                        <div class="card-body d-flex flex-column">
                          <div class="mb-4">
                            <h3>Free</h3>
                            <span class="display-4">$0</span>
                          </div>
                          <ul>
                            <li><i class="fa fa-check"></i> <b>1 User</b></li>
                            <li><i class="fa fa-check"></i> 5 Projects </li>
                            <li><i class="fa fa-check"></i> 5 GB Storage</li>
                          </ul>
                          <a href="#" class="btn btn-lg btn-secondary mt-auto" data-bs-toggle="modal" data-bs-target="#edit_plan">Edit</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card pricing-box">
                        <div class="card-body d-flex flex-column">
                          <div class="mb-4">
                            <h3>Professional</h3>
                            <span class="display-4">$199</span>
                            <span>/mo</span>
                          </div>
                          <ul>
                            <li><i class="fa fa-check"></i> <b>30 Users</b></li>
                            <li><i class="fa fa-check"></i> 50 Projects</li>
                            <li><i class="fa fa-check"></i> 100 GB Storage</li>
                            <li><i class="fa fa-check"></i> Unlimited Message</li>
                            <li><i class="fa fa-check"></i> 24/7 Customer Support</li>
                          </ul>
                          <a href="#" class="btn btn-lg btn-secondary mt-auto" data-bs-toggle="modal" data-bs-target="#edit_plan">Edit</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card pricing-box">
                        <div class="card-body d-flex flex-column">
                          <div class="mb-4">
                            <h3>Enterprise</h3>
                            <span class="display-4">$399</span>
                            <span>/mo</span>
                          </div>
                          <ul>
                            <li><i class="fa fa-check"></i> <b>Unlimited Users </b></li>
                            <li><i class="fa fa-check"></i> Unlimited Projects</li>
                            <li><i class="fa fa-check"></i> 500 GB Storage</li>
                            <li><i class="fa fa-check"></i> Unlimited Message</li>
                            <li><i class="fa fa-check"></i> Voice and Video Call</li>
                            <li><i class="fa fa-check"></i> 24/7 Customer Support</li>
                          </ul>
                          <a href="#" class="btn btn-lg btn-secondary mt-auto" data-bs-toggle="modal" data-bs-target="#edit_plan">Edit</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Yearly Plan Details -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-table mb-0">
                        <div class="card-header">
                          <h4 class="card-title mb-0">Plan Details</h4>
                        </div>
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Plan</th>
                                  <th>Plan Type</th>
                                  <th>Create Date</th>
                                  <th>Modified Date</th>
                                  <th>Amount</th>
                                  <th>Subscribed Users</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Free Trial</td>
                                  <td>Yearly</td>
                                  <td>9 Nov 2019</td>
                                  <td>8 Dec 2019</td>
                                  <td>Free</td>
                                  <td><router-link class="btn btn-info btn-sm" to="/subscribed-companies">62 Users</router-link></td>
                                </tr>
                                <tr>
                                  <td>Professional</td>
                                  <td>Yearly</td>
                                  <td>9 Nov 2019</td>
                                  <td>8 Dec 2019</td>
                                  <td>$199</td>
                                  <td><router-link class="btn btn-info btn-sm" to="/subscribed-companies">157 Users</router-link></td>
                                </tr>
                                <tr>
                                  <td>Enterprise</td>
                                  <td>Yearly</td>
                                  <td>9 Nov 2019</td>
                                  <td>8 Dec 2019</td>
                                  <td>$399</td>
                                  <td><router-link class="btn btn-info btn-sm" to="/subscribed-companies">241 Users</router-link></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Yearly Plan Details -->
                
                </div>
                <!-- /Annual Plan Tab -->
                
              </div>
              <!-- /Plan Tab Content -->
              
              <!-- Add Plan Modal -->
              <div class="modal custom-modal fade" id="add_plan" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                  <div class="modal-content">
                    <button type="button" class="close" data-bs-dismiss="modal"><i class="fa fa-close"></i></button>
                    <div class="modal-body">
                      <h5 class="modal-title text-center mb-3">Add Plan</h5>
                      <form>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Plan Name</label>
                              <input type="text" placeholder="Free Trial" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Plan Amount</label>
                              <input type="text" class="form-control">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Plan Type</label>
                              <select class="select"> 
                                <option> Monthly </option>
                                <option> Yearly </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>No of Users</label>
                              <select class="select"> 
                                <option> 5 Users </option>
                                <option> 50 Users </option>
                                <option> Unlimited </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>No of Projects</label>
                              <select class="select"> 
                                <option> 5 Projects </option>
                                <option> 50 Projects </option>
                                <option> Unlimited </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>No of Storage Space</label>
                              <select class="select"> 
                                <option> 5 GB </option>
                                <option> 100 GB </option>
                                <option> 500 GB </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Plan Description</label>
                          <textarea class="form-control" rows="4" cols="30"></textarea>
                        </div>
                        <div class="form-group">
                          <label class="d-block">Status</label>
                          <div class="status-toggle">
                            <input type="checkbox" id="add_plan_status" class="check">
                            <label for="add_plan_status" class="checktoggle">checkbox</label>
                          </div>
                        </div>
                        <div class="m-t-20 text-center">
                          <button class="btn btn-primary submit-btn">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Add Plan Modal -->

              <!-- Edit Plan Modal -->
              <div class="modal custom-modal fade" id="edit_plan" role="dialog">
                <div class="modal-dialog modal-md modal-dialog-centered">
                  <div class="modal-content">
                    <button type="button" class="close" data-bs-dismiss="modal"><i class="fa fa-close"></i></button>
                    <div class="modal-body">
                      <h5 class="modal-title text-center mb-3">Edit Plan</h5>
                      <form>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Plan Name</label>
                              <input type="text" placeholder="Free Trial" class="form-control" value="Free Trial">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Plan Amount</label>
                              <input type="text" class="form-control" value="$500">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Plan Type</label>
                              <select class="select"> 
                                <option> Monthly </option>
                                <option> Yearly </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>No of Users</label>
                              <select class="select"> 
                                <option> 5 Users </option>
                                <option> 50 Users </option>
                                <option> Unlimited </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>No of Projects</label>
                              <select class="select"> 
                                <option> 5 Projects </option>
                                <option> 50 Projects </option>
                                <option> Unlimited </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>No of Storage Space</label>
                              <select class="select"> 
                                <option> 5 GB </option>
                                <option> 100 GB </option>
                                <option> 500 GB </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Plan Description</label>
                          <textarea class="form-control" rows="4" cols="30"></textarea>
                        </div>
                        <div class="form-group">
                          <label class="d-block">Status</label>
                          <div class="status-toggle">
                            <input type="checkbox" id="edit_plan_status" class="check">
                            <label for="edit_plan_status" class="checktoggle">checkbox</label>
                          </div>
                        </div>
                        <div class="m-t-20 text-center">
                          <button class="btn btn-primary submit-btn">Save</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Edit Plan Modal -->
              
            </div>
          </div>
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
        // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }

    },
    name: 'subscriptions'
  }
</Script>