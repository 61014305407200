<template>
  <div class="inbox">
    <div class="main-wrapper">
      <layout-headerEmployee></layout-headerEmployee>
      <layout-sidebarApp></layout-sidebarApp>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Inbox</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Inbox</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <router-link to="/compose" class="btn add-btn"><i class="fa fa-plus"></i> Compose</router-link>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <div class="row">
            <div class="col-md-12">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="email-header">
                    <div class="row">
                      <div class="col top-action-left">
                        <div class="float-start">
                          <div class="btn-group dropdown-action">
                            <button type="button" class="btn btn-white dropdown-toggle" data-bs-toggle="dropdown">Select <i class="fa fa-angle-down "></i></button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="#">All</a>
                              <a class="dropdown-item" href="#">None</a>
                              <div class="dropdown-divider"></div> 
                              <a class="dropdown-item" href="#">Read</a>
                              <a class="dropdown-item" href="#">Unread</a>
                            </div>
                          </div>
                          <div class="btn-group dropdown-action ms-1">
                            <button type="button" class="btn btn-white dropdown-toggle" data-bs-toggle="dropdown">Actions <i class="fa fa-angle-down "></i></button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="#">Reply</a>
                              <a class="dropdown-item" href="#">Forward</a>
                              <a class="dropdown-item" href="#">Archive</a>
                              <div class="dropdown-divider"></div> 
                              <a class="dropdown-item" href="#">Mark As Read</a>
                              <a class="dropdown-item" href="#">Mark As Unread</a>
                              <div class="dropdown-divider"></div> 
                              <a class="dropdown-item" href="#">Delete</a>
                            </div>
                          </div>
                          <div class="btn-group dropdown-action ms-1">
                            <button type="button" class="btn btn-white dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-folder"></i> <i class="fa fa-angle-down"></i></button>
                            <div role="menu" class="dropdown-menu">
                              <a class="dropdown-item" href="#">Social</a>
                              <a class="dropdown-item" href="#">Forums</a>
                              <a class="dropdown-item" href="#">Updates</a>
                              <div class="dropdown-divider"></div> 
                              <a class="dropdown-item" href="#">Spam</a>
                              <a class="dropdown-item" href="#">Trash</a>
                              <div class="dropdown-divider"></div> 
                              <a class="dropdown-item" href="#">New</a>
                            </div>
                          </div>
                          <div class="btn-group dropdown-action ms-1">
                            <button type="button" data-bs-toggle="dropdown" class="btn btn-white dropdown-toggle"><i class="fa fa-tags"></i> <i class="fa fa-angle-down"></i></button>
                            <div role="menu" class="dropdown-menu">
                              <a class="dropdown-item" href="#">Work</a>
                              <a class="dropdown-item" href="#">Family</a>
                              <a class="dropdown-item" href="#">Social</a>
                              <div class="dropdown-divider"></div> 
                              <a class="dropdown-item" href="#">Primary</a>
                              <a class="dropdown-item" href="#">Promotions</a>
                              <a class="dropdown-item" href="#">Forums</a>
                            </div>
                          </div>
                        </div>
                        <div class="float-start d-none d-sm-block">
                          <input type="text" placeholder="Search Messages" class="form-control search-message">
                        </div>
                      </div>
                      <div class="col-auto top-action-right">
                        <div class="text-end">
                          <button type="button" title="Refresh" data-bs-toggle="tooltip" class="btn btn-white d-none d-md-inline-block me-1"><i class="fa fa-refresh"></i></button>
                          <div class="btn-group">
                            <a class="btn btn-white"><i class="fa fa-angle-left"></i></a>
                            <a class="btn btn-white"><i class="fa fa-angle-right"></i></a>
                          </div>
                        </div>
                        <div class="text-end">
                          <span class="text-muted d-none d-md-inline-block">Showing 10 of 112 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="email-content">
                    <div class="table-responsive">
                      <table class="table table-inbox table-hover">
                        <thead>
                          <tr>
                            <th colspan="6">
                              <input type="checkbox" class="checkbox-all">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="unread clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star starred"></i></span></td>
                            <td class="name">John Doe</td>
                            <td class="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                            <td><i class="fa fa-paperclip"></i></td>
                            <td class="mail-date">13:14</td>
                          </tr>
                          <tr class="unread clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">Envato Account</td>
                            <td class="subject">Important account security update from Envato</td>
                            <td></td>
                            <td class="mail-date">8:42</td>
                          </tr>
                          <tr class="clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">Twitter</td>
                            <td class="subject">HRMS Bootstrap Admin Template</td>
                            <td></td>
                            <td class="mail-date">30 Nov</td>
                          </tr>
                          <tr class="unread clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">Richard Parker</td>
                            <td class="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                            <td></td>
                            <td class="mail-date">18 Sep</td>
                          </tr>
                          <tr class="clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">John Smith</td>
                            <td class="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                            <td></td>
                            <td class="mail-date">21 Aug</td>
                          </tr>
                          <tr class="clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">me, Robert Smith (3)</td>
                            <td class="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                            <td></td>
                            <td class="mail-date">1 Aug</td>
                          </tr>
                          <tr class="unread clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">Codecanyon</td>
                            <td class="subject">Welcome To Codecanyon</td>
                            <td></td>
                            <td class="mail-date">Jul 13</td>
                          </tr>
                          <tr class="clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">Richard Miles</td>
                            <td class="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                            <td><i class="fa fa-paperclip"></i></td>
                            <td class="mail-date">May 14</td>
                          </tr>
                          <tr class="unread clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star-o"></i></span></td>
                            <td class="name">John Smith</td>
                            <td class="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                            <td></td>
                            <td class="mail-date">11/11/16</td>
                          </tr>
                          <tr class="clickable-row" data-href="mail-view">
                            <td>
                              <input type="checkbox" class="checkmail">
                            </td>
                            <td><span class="mail-important"><i class="fa fa-star starred"></i></span></td>
                            <td class="name">Mike Litorus</td>
                            <td class="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                            <td></td>
                            <td class="mail-date">10/31/16</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }

    },
    name: 'inbox'
  }
</Script>