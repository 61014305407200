<template>
  <div class="index">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">Welcome Admin!</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
        
          <div class="row">
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
                  <div class="dash-widget-info">
                    <h3>112</h3>
                    <span>Projects</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-usd"></i></span>
                  <div class="dash-widget-info">
                    <h3>44</h3>
                    <span>Clients</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-diamond"></i></span>
                  <div class="dash-widget-info">
                    <h3>37</h3>
                    <span>Tasks</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-user"></i></span>
                  <div class="dash-widget-info">
                    <h3>218</h3>
                    <span>Employees</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 text-center">
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title">Total Revenue</h3>
                      <div id="bar-chart"></div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 text-center">
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title">Sales Overview</h3>
                      <div id="line-chart"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-12">
              <div class="card-group m-b-30">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                      <div>
                        <span class="d-block">New Employees</span>
                      </div>
                      <div>
                        <span class="text-success">+10%</span>
                      </div>
                    </div>
                    <h3 class="mb-3">10</h3>
                    <div class="progress mb-2" style="height: 5px;">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 70%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p class="mb-0">Overall Employees 218</p>
                  </div>
                </div>
              
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                      <div>
                        <span class="d-block">Earnings</span>
                      </div>
                      <div>
                        <span class="text-success">+12.5%</span>
                      </div>
                    </div>
                    <h3 class="mb-3">$1,42,300</h3>
                    <div class="progress mb-2" style="height: 5px;">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 70%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p class="mb-0">Previous Month <span class="text-muted">$1,15,852</span></p>
                  </div>
                </div>
              
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                      <div>
                        <span class="d-block">Expenses</span>
                      </div>
                      <div>
                        <span class="text-danger">-2.8%</span>
                      </div>
                    </div>
                    <h3 class="mb-3">$8,500</h3>
                    <div class="progress mb-2" style="height: 5px;">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 70%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p class="mb-0">Previous Month <span class="text-muted">$7,500</span></p>
                  </div>
                </div>
              
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                      <div>
                        <span class="d-block">Profit</span>
                      </div>
                      <div>
                        <span class="text-danger">-75%</span>
                      </div>
                    </div>
                    <h3 class="mb-3">$1,12,000</h3>
                    <div class="progress mb-2" style="height: 5px;">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 70%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p class="mb-0">Previous Month <span class="text-muted">$1,42,000</span></p>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          
          <!-- Statistics Widget -->
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-4 d-flex">
              <div class="card flex-fill dash-statistics">
                <div class="card-body">
                  <h5 class="card-title">Statistics</h5>
                  <div class="stats-list">
                    <div class="stats-info">
                      <p>Today Leave <strong>4 <small>/ 65</small></strong></p>
                      <div class="progress">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 31%" aria-valuenow="31" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="stats-info">
                      <p>Pending Invoice <strong>15 <small>/ 92</small></strong></p>
                      <div class="progress">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 31%" aria-valuenow="31" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="stats-info">
                      <p>Completed Projects <strong>85 <small>/ 112</small></strong></p>
                      <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" style="width: 62%" aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="stats-info">
                      <p>Open Tickets <strong>190 <small>/ 212</small></strong></p>
                      <div class="progress">
                        <div class="progress-bar bg-danger" role="progressbar" style="width: 62%" aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="stats-info">
                      <p>Closed Tickets <strong>22 <small>/ 212</small></strong></p>
                      <div class="progress">
                        <div class="progress-bar bg-info" role="progressbar" style="width: 22%" aria-valuenow="22" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-12 col-lg-6 col-xl-4 d-flex">
              <div class="card flex-fill">
                <div class="card-body">
                  <h4 class="card-title">Task Statistics</h4>
                  <div class="statistics">
                    <div class="row">
                      <div class="col-md-6 col-6 text-center">
                        <div class="stats-box mb-4">
                          <p>Total Tasks</p>
                          <h3>385</h3>
                        </div>
                      </div>
                      <div class="col-md-6 col-6 text-center">
                        <div class="stats-box mb-4">
                          <p>Overdue Tasks</p>
                          <h3>19</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="progress mb-4">
                    <div class="progress-bar bg-purple" role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">30%</div>
                    <div class="progress-bar bg-warning" role="progressbar" style="width: 22%" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">22%</div>
                    <div class="progress-bar bg-success" role="progressbar" style="width: 24%" aria-valuenow="12" aria-valuemin="0" aria-valuemax="100">24%</div>
                    <div class="progress-bar bg-danger" role="progressbar" style="width: 26%" aria-valuenow="14" aria-valuemin="0" aria-valuemax="100">21%</div>
                    <div class="progress-bar bg-info" role="progressbar" style="width: 10%" aria-valuenow="14" aria-valuemin="0" aria-valuemax="100">10%</div>
                  </div>
                  <div>
                    <p><i class="fa fa-dot-circle-o text-purple me-2"></i>Completed Tasks <span class="float-end">166</span></p>
                    <p><i class="fa fa-dot-circle-o text-warning me-2"></i>Inprogress Tasks <span class="float-end">115</span></p>
                    <p><i class="fa fa-dot-circle-o text-success me-2"></i>On Hold Tasks <span class="float-end">31</span></p>
                    <p><i class="fa fa-dot-circle-o text-danger me-2"></i>Pending Tasks <span class="float-end">47</span></p>
                    <p class="mb-0"><i class="fa fa-dot-circle-o text-info me-2"></i>Review Tasks <span class="float-end">5</span></p>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-12 col-lg-6 col-xl-4 d-flex">
              <div class="card flex-fill">
                <div class="card-body">
                  <h4 class="card-title">Today Absent <span class="badge bg-inverse-danger ms-2">5</span></h4>
                  <div class="leave-info-box">
                    <div class="media d-flex align-items-center">
                      <router-link to="/profile" class="avatar"><img alt="" src="../assets/img/user.jpg"></router-link>
                      <div class="media-body flex-grow-1">
                        <div class="text-sm my-0">Martin Lewis</div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-6">
                        <h6 class="mb-0">4 Sep 2019</h6>
                        <span class="text-sm text-muted">Leave Date</span>
                      </div>
                      <div class="col-6 text-end">
                        <span class="badge bg-inverse-danger">Pending</span>
                      </div>
                    </div>
                  </div>
                  <div class="leave-info-box">
                    <div class="media d-flex align-items-center">
                      <router-link to="/profile" class="avatar"><img alt="" src="../assets/img/user.jpg"></router-link>
                      <div class="media-body flex-grow-1">
                        <div class="text-sm my-0">Martin Lewis</div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-6">
                        <h6 class="mb-0">4 Sep 2019</h6>
                        <span class="text-sm text-muted">Leave Date</span>
                      </div>
                      <div class="col-6 text-end">
                        <span class="badge bg-inverse-success">Approved</span>
                      </div>
                    </div>
                  </div>
                  <div class="load-more text-center">
                    <a class="text-dark" href="javascript:void(0);">Load More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Statistics Widget -->
          
          <div class="row">
            <div class="col-md-6 d-flex">
              <div class="card card-table flex-fill">
                <div class="card-header">
                  <h3 class="card-title mb-0">Invoices</h3>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-nowrap custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Invoice ID</th>
                          <th>Client</th>
                          <th>Due Date</th>
                          <th>Total</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><router-link to="/invoice-view">#INV-0001</router-link></td>
                          <td>
                            <h2><a href="#">Global Technologies</a></h2>
                          </td>
                          <td>11 Mar 2019</td>
                          <td>$380</td>
                          <td>
                            <span class="badge bg-inverse-warning">Partially Paid</span>
                          </td>
                        </tr>
                        <tr>
                          <td><router-link to="/invoice-view">#INV-0002</router-link></td>
                          <td>
                            <h2><a href="#">Delta Infotech</a></h2>
                          </td>
                          <td>8 Feb 2019</td>
                          <td>$500</td>
                          <td>
                            <span class="badge bg-inverse-success">Paid</span>
                          </td>
                        </tr>
                        <tr>
                          <td><router-link to="/invoice-view">#INV-0003</router-link></td>
                          <td>
                            <h2><a href="#">Cream Inc</a></h2>
                          </td>
                          <td>23 Jan 2019</td>
                          <td>$60</td>
                          <td>
                            <span class="badge bg-inverse-danger">Unpaid</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                  <router-link to="/invoices">View all invoices</router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="card card-table flex-fill">
                <div class="card-header">
                  <h3 class="card-title mb-0">Payments</h3>
                </div>
                <div class="card-body">
                  <div class="table-responsive">  
                    <table class="table custom-table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Invoice ID</th>
                          <th>Client</th>
                          <th>Payment Type</th>
                          <th>Paid Date</th>
                          <th>Paid Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><router-link to="/invoice-view">#INV-0001</router-link></td>
                          <td>
                            <h2><a href="#">Global Technologies</a></h2>
                          </td>
                          <td>Paypal</td>
                          <td>11 Mar 2019</td>
                          <td>$380</td>
                        </tr>
                        <tr>
                          <td><router-link to="/invoice-view">#INV-0002</router-link></td>
                          <td>
                            <h2><a href="#">Delta Infotech</a></h2>
                          </td>
                          <td>Paypal</td>
                          <td>8 Feb 2019</td>
                          <td>$500</td>
                        </tr>
                        <tr>
                          <td><router-link to="/invoice-view">#INV-0003</router-link></td>
                          <td>
                            <h2><a href="#">Cream Inc</a></h2>
                          </td>
                          <td>Paypal</td>
                          <td>23 Jan 2019</td>
                          <td>$60</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                  <router-link to="/payments">View all payments</router-link>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6 d-flex">
              <div class="card card-table flex-fill">
                <div class="card-header">
                  <h3 class="card-title mb-0">Clients</h3>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th class="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h2 class="table-avatar">
                              <a href="#" class="avatar"><img alt="" src="../assets/img/profiles/avatar-19.jpg"></a>
                              <router-link to="/client-profile">Barry Cuda <span>CEO</span></router-link>
                            </h2>
                          </td>
                          <td>barrycuda@example.com</td>
                          <td>
                            <div class="dropdown action-label">
                              <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-success"></i> Active
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                              </div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2 class="table-avatar">
                              <a href="#" class="avatar"><img alt="" src="../assets/img/profiles/avatar-19.jpg"></a>
                              <router-link to="/client-profile">Tressa Wexler <span>Manager</span></router-link>
                            </h2>
                          </td>
                          <td>tressawexler@example.com</td>
                          <td>
                            <div class="dropdown action-label">
                              <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Inactive
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                              </div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2 class="table-avatar">
                              <router-link to="/client-profile" class="avatar"><img alt="" src="../assets/img/profiles/avatar-07.jpg"></router-link>
                              <router-link to="/client-profile">Ruby Bartlett <span>CEO</span></router-link>
                            </h2>
                          </td>
                          <td>rubybartlett@example.com</td>
                          <td>
                            <div class="dropdown action-label">
                              <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Inactive
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                              </div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2 class="table-avatar">
                              <router-link to="/client-profile" class="avatar"><img alt="" src="../assets/img/profiles/avatar-06.jpg"></router-link>
                              <router-link to="/client-profile"> Misty Tison <span>CEO</span></router-link>
                            </h2>
                          </td>
                          <td>mistytison@example.com</td>
                          <td>
                            <div class="dropdown action-label">
                              <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-success"></i> Active
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                              </div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2 class="table-avatar">
                              <router-link to="/client-profile" class="avatar"><img alt="" src="../assets/img/profiles/avatar-14.jpg"></router-link>
                              <router-link to="/client-profile"> Daniel Deacon <span>CEO</span></router-link>
                            </h2>
                          </td>
                          <td>danieldeacon@example.com</td>
                          <td>
                            <div class="dropdown action-label">
                              <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-dot-circle-o text-danger"></i> Inactive
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                                <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                              </div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                  <router-link to="/clients">View all clients</router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="card card-table flex-fill">
                <div class="card-header">
                  <h3 class="card-title mb-0">Recent Projects</h3>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Project Name </th>
                          <th>Progress</th>
                          <th class="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h2><router-link to="/project-view">Office Management</router-link></h2>
                            <small class="block text-ellipsis">
                              <span>1</span> <span class="text-muted">open tasks, </span>
                              <span>9</span> <span class="text-muted">tasks completed</span>
                            </small>
                          </td>
                          <td>
                            <div class="progress progress-xs progress-striped">
                              <div class="progress-bar" role="progressbar" data-bs-toggle="tooltip" title="65%" style="width: 65%"></div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2><router-link to="/project-view">Project Management</router-link></h2>
                            <small class="block text-ellipsis">
                              <span>2</span> <span class="text-muted">open tasks, </span>
                              <span>5</span> <span class="text-muted">tasks completed</span>
                            </small>
                          </td>
                          <td>
                            <div class="progress progress-xs progress-striped">
                              <div class="progress-bar" role="progressbar" data-bs-toggle="tooltip" title="15%" style="width: 15%"></div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2><router-link to="/project-view">Video Calling App</router-link></h2>
                            <small class="block text-ellipsis">
                              <span>3</span> <span class="text-muted">open tasks, </span>
                              <span>3</span> <span class="text-muted">tasks completed</span>
                            </small>
                          </td>
                          <td>
                            <div class="progress progress-xs progress-striped">
                              <div class="progress-bar" role="progressbar" data-bs-toggle="tooltip" title="49%" style="width: 49%"></div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2><router-link to="/project-view">Hospital Administration</router-link></h2>
                            <small class="block text-ellipsis">
                              <span>12</span> <span class="text-muted">open tasks, </span>
                              <span>4</span> <span class="text-muted">tasks completed</span>
                            </small>
                          </td>
                          <td>
                            <div class="progress progress-xs progress-striped">
                              <div class="progress-bar" role="progressbar" data-bs-toggle="tooltip" title="88%" style="width: 88%"></div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2><router-link to="/project-view">Digital Marketplace</router-link></h2>
                            <small class="block text-ellipsis">
                              <span>7</span> <span class="text-muted">open tasks, </span>
                              <span>14</span> <span class="text-muted">tasks completed</span>
                            </small>
                          </td>
                          <td>
                            <div class="progress progress-xs progress-striped">
                              <div class="progress-bar" role="progressbar" data-bs-toggle="tooltip" title="100%" style="width: 100%"></div>
                            </div>
                          </td>
                          <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                  <router-link to="/projects">View all projects</router-link>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <!-- /Page Content -->

            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
      $(document).ready(function () {
        barChart();
        lineChart();

        $(window).resize(function () {
          window.barChart.redraw();
          window.lineChart.redraw();
        });
      });
      function barChart() {
        window.barChart = Morris.Bar({
          element: 'bar-chart',
          data: [
      { y: '2006', a: 100, b: 90 },
      { y: '2007', a: 75,  b: 65 },
      { y: '2008', a: 50,  b: 40 },
      { y: '2009', a: 75,  b: 65 },
      { y: '2010', a: 50,  b: 40 },
      { y: '2011', a: 75,  b: 65 },
      { y: '2012', a: 100, b: 90 }
    ],
    xkey: 'y',
    ykeys: ['a', 'b'],
    labels: ['Total Income', 'Total Outcome'],
    lineColors: ['#ff9b44','#fc6075'],
    lineWidth: '3px',
    barColors: ['#ff9b44','#fc6075'],
    resize: true,
    redraw: true
        });
      }
      function lineChart() {
        window.lineChart = Morris.Line({
          element: 'line-chart',
         data: [
      { y: '2006', a: 50, b: 90 },
      { y: '2007', a: 75,  b: 65 },
      { y: '2008', a: 50,  b: 40 },
      { y: '2009', a: 75,  b: 65 },
      { y: '2010', a: 50,  b: 40 },
      { y: '2011', a: 75,  b: 65 },
      { y: '2012', a: 100, b: 50 }
    ],
    xkey: 'y',
    ykeys: ['a', 'b'],
    labels: ['Total Sales', 'Total Revenue'],
    lineColors: ['#ff9b44','#fc6075'],
    lineWidth: '3px',
    resize: true,
    redraw: true
        });
      }
    },
    methods: {
    },
    name: 'index'
  }
</Script>