    import $ from 'jquery'
    $(document).ready(function() {  
    // Variables declarations
    // var $wrapper = $('.main-wrapper');
    var $pageWrapper = $('.page-wrapper');
    // Sidebar
    var Sidemenu = function() {
    this.$menuItem = $('#sidebar-menu a');
    };
    function init() {    
    var $this = Sidemenu;
    }
    // Sidebar Initiate
    init();
    // Chat sidebar overlay
    $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
    }); 
    // Modal Popup hide show
    if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
    var $element = $(this);
    var $uniques = $(modalUniqueClass + ':visible').not($(this));
    if ($uniques.length) {
    $uniques.modal('hide');
    $uniques.one('hidden.bs.modal', function(e) {
    $element.modal('show');
    });
    return false;
    }
    });
    }
    // Page Content Height
    var pHeight = $(window).height();
    $pageWrapper.css('min-height', pHeight);
    $(window).resize(function() {
    var prHeight = $(window).height();
    $pageWrapper.css('min-height', prHeight);
    });
    // Placeholder Hide
    if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
    $(this).data('placeholder', $(this).attr('placeholder'))
    .attr('placeholder', '');
    }).blur(function () {
    $(this).attr('placeholder', $(this).data('placeholder'));
    });
    }
    // Small Sidebar
    $(document).on('click', '#toggle_btn', function() {
    if($('body').hasClass('mini-sidebar')) {
    $('body').removeClass('mini-sidebar');
    $('.subdrop + ul').slideDown();
    } else {
    $('body').addClass('mini-sidebar');
    $('.subdrop + ul').slideUp();
    }
    return false;
    });
    $(document).on('mouseover', function(e) {
    e.stopPropagation();
    if($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
    var targ = $(e.target).closest('.sidebar').length;
    if(targ) {
    $('body').addClass('expand-menu');
    $('.subdrop + ul').slideDown();
    } else {
    $('body').removeClass('expand-menu');
    $('.subdrop + ul').slideUp();
    }
    return false;
    }
    });
  });