<template>
  <div class="tasks">
    <div class="main-wrapper">
      <layout-headerEmployee></layout-headerEmployee>
      <layout-sidebarReports></layout-sidebarReports>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
        <div class="chat-main-row">
          <div class="chat-main-wrapper">
            <div class="col-lg-7 message-view task-view task-left-sidebar">
              <div class="chat-window">
                <div class="fixed-header">
                  <div class="navbar">
                    <div class="float-start me-auto">
                      <div class="add-task-btn-wrapper">
                        <span class="add-task-btn btn btn-white btn-sm">
                          Add Task
                        </span>
                      </div>
                    </div>
                    <a class="task-chat profile-rightbar float-end" id="task_chat" href="#task_window"><i class="fa fa fa-comment"></i></a>
                    <ul class="nav float-end custom-menu">
                      <li class="nav-item dropdown dropdown-action">
                        <a href="" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-cog"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" href="javascript:void(0)">Pending Tasks</a>
                          <a class="dropdown-item" href="javascript:void(0)">Completed Tasks</a>
                          <a class="dropdown-item" href="javascript:void(0)">All Tasks</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="chat-contents">
                  <div class="chat-content-wrap">
                    <div class="chat-wrap-inner">
                      <div class="chat-box">
                        <div class="task-wrapper">
                          <div class="task-list-container">
                            <div class="task-list-body">
                              <ul id="task-list">
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Patient appointment booking</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Appointment booking with payment gateway</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="completed task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label">Doctor available module</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Patient and Doctor video conferencing</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Private chat module</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Patient Profile add</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="task-list-footer">
                              <div class="new-task-wrapper">
                                <textarea  id="new-task" placeholder="Enter new task here. . ."></textarea>
                                <span class="error-message hidden">You need to enter a task first</span>
                                <span class="add-new-task-btn btn" id="add-task">Add Task</span>
                                <span class="btn" id="close-task-panel">Close</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="notification-popup hide">
                          <p>
                            <span class="task"></span>
                            <span class="notification-text"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 message-view task-chat-view task-right-sidebar" id="task_window">
              <div class="chat-window">
                <div class="fixed-header">
                  <div class="navbar">
                    <div class="task-assign">
                      <a class="task-complete-btn" id="task_complete" href="javascript:void(0);">
                        <i class="material-icons">check</i> Mark Complete
                      </a>
                    </div>
                    <ul class="nav float-end custom-menu">
                      <li class="dropdown dropdown-action">
                        <a href="" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" href="javascript:void(0)">Delete Task</a>
                          <a class="dropdown-item" href="javascript:void(0)">Settings</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="chat-contents task-chat-contents">
                  <div class="chat-content-wrap">
                    <div class="chat-wrap-inner">
                      <div class="chat-box">
                        <div class="chats">
                          <h4>Hospital Administration Phase 1</h4>
                          <div class="task-header">
                            <div class="assignee-info">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#assignee">
                                <div class="avatar">
                                  <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                                </div>
                                <div class="assigned-info">
                                  <div class="task-head-title">Assigned To</div>
                                  <div class="task-assignee">John Doe</div>
                                </div>
                              </a>
                              <span class="remove-icon">
                                <i class="fa fa-close"></i>
                              </span>
                            </div>
                            <div class="task-due-date">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#assignee">
                                <div class="due-icon">
                                  <span>
                                    <i class="material-icons">date_range</i>
                                  </span>
                                </div>
                                <div class="due-info">
                                  <div class="task-head-title">Due Date</div>
                                  <div class="due-date">Mar 26, 2019</div>
                                </div>
                              </a>
                              <span class="remove-icon">
                                <i class="fa fa-close"></i>
                              </span>
                            </div>
                          </div>
                          <hr class="task-line">
                          <div class="task-desc">
                            <div class="task-desc-icon">
                              <i class="material-icons">subject</i>
                            </div>
                            <div class="task-textarea">
                              <textarea class="form-control" placeholder="Description"></textarea>
                            </div>
                          </div>
                          <hr class="task-line">
                          <div class="task-information">
                            <span class="task-info-line"><a class="task-user" href="#">Lesley Grauer</a> <span class="task-info-subject">created task</span></span>
                            <div class="task-time">Jan 20, 2019</div>
                          </div>
                          <div class="task-information">
                            <span class="task-info-line"><a class="task-user" href="#">Lesley Grauer</a> <span class="task-info-subject">added to Hospital Administration</span></span>
                            <div class="task-time">Jan 20, 2019</div>
                          </div>
                          <div class="task-information">
                            <span class="task-info-line"><a class="task-user" href="#">Lesley Grauer</a> <span class="task-info-subject">assigned to John Doe</span></span>
                            <div class="task-time">Jan 20, 2019</div>
                          </div>
                          <hr class="task-line">
                          <div class="task-information">
                            <span class="task-info-line"><a class="task-user" href="#">John Doe</a> <span class="task-info-subject">changed the due date to Sep 28</span> </span>
                            <div class="task-time">9:09pm</div>
                          </div>
                          <div class="task-information">
                            <span class="task-info-line"><a class="task-user" href="#">John Doe</a> <span class="task-info-subject">assigned to you</span></span>
                            <div class="task-time">9:10pm</div>
                          </div>
                          <div class="chat chat-left">
                            <div class="chat-avatar">
                              <router-link to="/profile" class="avatar">
                                <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                              </router-link>
                            </div>
                            <div class="chat-body">
                              <div class="chat-bubble">
                                <div class="chat-content">
                                  <span class="task-chat-user">John Doe</span> <span class="chat-time">8:35 am</span>
                                  <p>I'm just looking around.</p>
                                  <p>Will you tell me something about yourself? </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="completed-task-msg"><span class="task-success"><a href="#">John Doe</a> completed this task.</span> <span class="task-time">Today at 9:27am</span></div>
                          <div class="chat chat-left">
                            <div class="chat-avatar">
                              <router-link to="/profile" class="avatar">
                                <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                              </router-link>
                            </div>
                            <div class="chat-body">
                              <div class="chat-bubble">
                                <div class="chat-content">
                                  <span class="task-chat-user">John Doe</span> <span class="file-attached">attached 3 files <i class="fa fa-paperclip"></i></span> <span class="chat-time">Feb 17, 2019 at 4:32am</span>
                                  <ul class="attach-list">
                                    <li><i class="fa fa-file"></i> <a href="#">project_document.avi</a></li>
                                    <li><i class="fa fa-file"></i> <a href="#">video_conferencing.psd</a></li>
                                    <li><i class="fa fa-file"></i> <a href="#">landing_page.psd</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="chat chat-left">
                            <div class="chat-avatar">
                              <router-link to="/profile" class="avatar">
                                <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                              </router-link>
                            </div>
                            <div class="chat-body">
                              <div class="chat-bubble">
                                <div class="chat-content">
                                  <span class="task-chat-user">Jeffery Lalor</span> <span class="file-attached">attached file <i class="fa fa-paperclip"></i></span> <span class="chat-time">Yesterday at 9:16pm</span>
                                  <ul class="attach-list">
                                    <li class="pdf-file"><i class="fa fa-file-pdf-o"></i> <a href="#">Document_2016.pdf</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="chat chat-left">
                            <div class="chat-avatar">
                              <router-link to="/profile" class="avatar">
                                <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                              </router-link>
                            </div>
                            <div class="chat-body">
                              <div class="chat-bubble">
                                <div class="chat-content">
                                  <span class="task-chat-user">Jeffery Lalor</span> <span class="file-attached">attached file <i class="fa fa-paperclip"></i></span> <span class="chat-time">Today at 12:42pm</span>
                                  <ul class="attach-list">
                                    <li class="img-file">
                                      <div class="attach-img-download"><a href="#">avatar-1.jpg</a></div>
                                      <div class="task-attach-img"><img src="../../assets/img/user.jpg" alt=""></div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="task-information">
                            <span class="task-info-line">
                              <a class="task-user" href="#">John Doe</a>
                              <span class="task-info-subject">marked task as incomplete</span>
                            </span>
                            <div class="task-time">1:16pm</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chat-footer">
                  <div class="message-bar">
                    <div class="message-inner">
                      <a class="link attach-icon" href="#"><img src="../../assets/img/attachment.png" alt=""></a>
                      <div class="message-area">
                        <div class="input-group">
                          <textarea class="form-control" placeholder="Type message..."></textarea>
                          <button class="btn btn-primary" type="button"><i class="fa fa-send"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="project-members task-followers">
                    <span class="followers-title">Followers</span>
                    <a class="avatar" href="#" data-bs-toggle="tooltip" title="Jeffery Lalor">
                      <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                    </a>
                    <a class="avatar" href="#" data-bs-toggle="tooltip" title="Richard Miles">
                      <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                    </a>
                    <a class="avatar" href="#" data-bs-toggle="tooltip" title="John Smith">
                      <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                    </a>
                    <a class="avatar" href="#" data-bs-toggle="tooltip" title="Mike Litorus">
                      <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                    </a>
                    <a href="#" class="followers-add" data-bs-toggle="modal" data-bs-target="#task_followers"><i class="material-icons">add</i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Create Project Modal -->
        <div id="create_project" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Create Project</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Project Name</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Client</label>
                        <select class="select">
                          <option>Global Technologies</option>
                          <option>Delta Infotech</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Start Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>End Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Rate</label>
                        <input placeholder="$50" class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <select class="select">
                          <option>Hourly</option>
                          <option>Fixed</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Priority</label>
                        <select class="select">
                          <option>High</option>
                          <option>Medium</option>
                          <option>Low</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Project Leader</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Leader</label>
                        <div class="project-members">
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="Jeffery Lalor">
                            <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Team</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Members</label>
                        <div class="project-members">
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="John Doe">
                            <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                          </a>
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="Richard Miles">
                            <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                          </a>
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="John Smith">
                            <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                          </a>
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="Mike Litorus">
                            <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                          </a>
                          <span class="all-team">+2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea rows="4" class="form-control summernote" placeholder="Enter your message here"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Upload Files</label>
                    <input class="form-control" type="file">
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Create Project Modal -->
        
        <!-- Assignee Modal -->
        <div id="assignee" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Assign to this task</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="input-group m-b-30">
                  <input placeholder="Search to add" class="form-control search-input" type="text">
                  <button class="btn btn-primary">Search</button>
                </div>
                <div>
                  <ul class="chat-user-list">
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">Richard Miles</div>
                            <span class="designation">Web Developer</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">John Smith</div>
                            <span class="designation">Android Developer</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar">
                            <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                          </span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">Jeffery Lalor</div>
                            <span class="designation">Team Leader</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="submit-section">
                  <button class="btn btn-primary submit-btn">Assign</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Assignee Modal -->
        
        <!-- Task Followers Modal -->
        <div id="task_followers" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add followers to this task</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="input-group m-b-30">
                  <input placeholder="Search to add" class="form-control search-input" type="text">
                  <button class="btn btn-primary">Search</button>
                </div>
                <div>
                  <ul class="chat-user-list">
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></span>
                          <div class="media-body media-middle text-nowrap">
                            <div class="user-name">Jeffery Lalor</div>
                            <span class="designation">Team Leader</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-08.jpg"></span>
                          <div class="media-body media-middle text-nowrap">
                            <div class="user-name">Catherine Manseau</div>
                            <span class="designation">Android Developer</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-26.jpg"></span>
                          <div class="media-body media-middle text-nowrap">
                            <div class="user-name">Wilmer Deluna</div>
                            <span class="designation">Team Leader</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="submit-section">
                  <button class="btn btn-primary submit-btn">Add to Follow</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Task Followers Modal -->
        
            </div>
      <!-- /Page Wrapper -->

    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
                  // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
      var notificationTimeout;

    //Shows updated notification popup 
    var updateNotification = function(task, notificationText, newClass){
      var notificationPopup = $('.notification-popup ');
      notificationPopup.find('.task').text(task);
      notificationPopup.find('.notification-text').text(notificationText);
      notificationPopup.removeClass('hide success');
      // If a custom class is provided for the popup, add It
      if(newClass)
        notificationPopup.addClass(newClass);
      // If there is already a timeout running for hiding current popup, clear it.
      if(notificationTimeout)
        clearTimeout(notificationTimeout);
      // Init timeout for hiding popup after 3 seconds
      notificationTimeout = setTimeout(function(){
        notificationPopup.addClass('hide');
      }, 3000);
    };

    // Adds a new Task to the todo list 
    var addTask = function(){
      // Get the new task entered by user
      var newTask = $('#new-task').val();
      // If new task is blank show error message
      if(newTask === ''){
        $('#new-task').addClass('error');
        $('.new-task-wrapper .error-message').removeClass('hidden');
      }
      else{
        var todoListScrollHeight = $('.task-list-body').prop('scrollHeight');
        // Make a new task template
        var newTemplate = $(taskTemplate).clone();
        // update the task label in the new template
        newTemplate.find('.task-label').text(newTask);
        // Add new class to the template
        newTemplate.addClass('new');
        // Remove complete class in the new Template in case it is present
        newTemplate.removeClass('completed');
        //Append the new template to todo list
        $('#task-list').append(newTemplate);
        // Clear the text in textarea
        $('#new-task').val('');
        // As a new task is added, hide the mark all tasks as incomplete button & show the mark all finished button
        $('#mark-all-finished').removeClass('move-up');
        $('#mark-all-incomplete').addClass('move-down');
        // Show notification
        updateNotification(newTask, 'added to list');
        // Smoothly scroll the todo list to the end
        $('.task-list-body').animate({ scrollTop: todoListScrollHeight}, 1000);
      }
    };

    // Closes the panel for entering new tasks & shows the button for opening the panel
    var closeNewTaskPanel = function(){
      $('.add-task-btn').toggleClass('visible');
      $('.new-task-wrapper').toggleClass('visible');
      if($('#new-task').hasClass('error')){
        $('#new-task').removeClass('error');
        $('.new-task-wrapper .error-message').addClass('hidden');
      }
    };

    // Initalizes HTML template for a given task 
    //var taskTemplate = $($('#task-template').html());
    var taskTemplate = '<li class="task"><div class="task-container"><span class="task-action-btn task-check"><span class="action-circle large complete-btn" title="Mark Complete"><i class="material-icons">check</i></span></span><span class="task-label" contenteditable="true"></span><span class="task-action-btn task-btn-right"><span class="action-circle large" title="Assign"><i class="material-icons">person_add</i></span> <span class="action-circle large delete-btn" title="Delete Task"><i class="material-icons">delete</i></span></span></div></li>';
    // Shows panel for entering new tasks
    $('.add-task-btn').click(function() {
        var newTaskWrapperOffset = $('.new-task-wrapper').offset().top;
        $(this).toggleClass('visible');
        $('.new-task-wrapper').toggleClass('visible');
        // Focus on the text area for typing in new task
        $('#new-task').focus();
        // Smoothly scroll to the text area to bring the text are in view
        $('body').animate({
            scrollTop: newTaskWrapperOffset
        }, 1000);
    });

    // Deletes task on click of delete button
    $('#task-list').on('click', '.task-action-btn .delete-btn', function(){
      var task = $(this).closest('.task');
      var taskText = task.find('.task-label').text();
      task.remove();
      updateNotification(taskText, ' has been deleted.');
    });

    // Marks a task as complete
    $('#task-list').on('click', '.task-action-btn .complete-btn', function(){
      var task = $(this).closest('.task');
      var taskText = task.find('.task-label').text();
      var newTitle = task.hasClass('completed') ? 'Mark Complete' : 'Mark Incomplete';
      $(this).attr('title', newTitle);
      task.hasClass('completed') ? updateNotification(taskText, 'marked as Incomplete.') : updateNotification(taskText, ' marked as complete.', 'success');
      task.toggleClass('completed');
    });

    // Adds a task on hitting Enter key, hides the panel for entering new task on hitting Esc. key
    $('#new-task').keydown(function(event){
      // Get the code of the key that is pressed
      var keyCode = event.keyCode;
      var enterKeyCode = 13;
      var escapeKeyCode = 27;
      // If error message is already displayed, hide it.
      if($('#new-task').hasClass('error')){
        $('#new-task').removeClass('error');
        $('.new-task-wrapper .error-message').addClass('hidden');
      }
      // If key code is that of Enter Key then call addTask Function
      if(keyCode == enterKeyCode){
        event.preventDefault();
        addTask();
      }
      // If key code is that of Esc Key then call closeNewTaskPanel Function
      else if(keyCode == escapeKeyCode)
        closeNewTaskPanel();

    });

    // Add new task on click of add task button
    $('#add-task').click(addTask);

    // Close new task panel on click of close panel button
    $('#close-task-panel').click(closeNewTaskPanel);

    // Mark all tasks as complete on click of mark all finished button
    $('#mark-all-finished').click(function(){
      $('#task-list .task').addClass('completed');
      $('#mark-all-incomplete').removeClass('move-down');
      $(this).addClass('move-up');
      updateNotification('All tasks', 'marked as complete.', 'success');
    });

    // Mark all tasks as incomplete on click of mark all incomplete button
    $('#mark-all-incomplete').click(function(){
      $('#task-list .task').removeClass('completed');
      $(this).addClass('move-down');
      $('#mark-all-finished').removeClass('move-up');
      updateNotification('All tasks', 'marked as Incomplete.');
    });
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'tasks'
  }
</Script>