<template>
  <div class="contacts">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Contact Main Row -->
        <div class="chat-main-row">
        
          <!-- Contact Wrapper -->
          <div class="chat-main-wrapper">
            <div class="col-lg-12 message-view">
              <div class="chat-window">
                <div class="fixed-header">
                  <div class="row">
                    <div class="col-6">
                      <h4 class="page-title mb-0">Contacts</h4>
                    </div>
                     <div class="col-6">
                      <div class="navbar justify-content-end">
                        <div class="search-box m-t-0">
                          <div class="input-group input-group-sm">
                            <input type="text" class="form-control" placeholder="Search">
                            <button class="btn" type="button"><i class="fa fa-search"></i></button>
                          </div>
                        </div>
                        <ul class="nav float-end custom-menu">
                          <li class="nav-item dropdown dropdown-action">
                            <a href="" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-cog"></i></a>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="javascript:void(0)">Menu 1</a>
                              <a class="dropdown-item" href="javascript:void(0)">Menu 2</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chat-contents">
                  <div class="chat-content-wrap">
                    <div class="chat-wrap-inner">
                      <div class="contact-box">
                      <div class="row">
                        <div class="contact-cat col-sm-4 col-lg-3">
                          <a href="#" class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#add_contact"><i class="fa fa-plus"></i> Add Contact</a>
                          <div class="roles-menu">
                            <ul>
                              <li class="active"><a href="javascript:void(0);">All</a></li>
                              <li><a href="#">Company</a></li>
                              <li><a href="#">Client</a></li>
                              <li><a href="#">Staff</a></li>
                            </ul>
                          </div>
                        </div>
                        <div class="contacts-list col-sm-8 col-lg-9">
                          <ul class="contact-list">
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/index" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-02.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">John Doe</span>
                                  <span class="contact-date">Web Developer</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-09.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Richard Miles</span>
                                  <span class="contact-date">Web Developer</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-10.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">John Smith</span>
                                  <span class="contact-date">Android Developer</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-05.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Mike Litorus</span>
                                  <span class="contact-date">IOS Developer</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-11.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Wilmer Deluna</span>
                                  <span class="contact-date">Team Leader</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-12.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Jeffrey Warden</span>
                                  <span class="contact-date">Web Developer</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-13.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Bernardo Galaviz</span>
                                  <span class="contact-date">Web Developer</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-01.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Lesley Grauer</span>
                                  <span class="contact-date">Team Leader</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-16.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Jeffery Lalor</span>
                                  <span class="contact-date">Team Leader</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <div class="contact-cont">
                                <div class="float-start user-img">
                                  <router-link to="/profile" class="avatar">
                                    <img class="rounded-circle" alt="" src="../../assets/img/profiles/avatar-16.jpg">
                                    <span class="status online"></span>
                                  </router-link>
                                </div>
                                <div class="contact-info">
                                  <span class="contact-name text-ellipsis">Loren Gatlin</span>
                                  <span class="contact-date">Android Developer</span>
                                </div>
                                <ul class="contact-action">
                                  <li class="dropdown dropdown-action">
                                    <a href="" class="dropdown-toggle action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_contact">Edit</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#delete_contact">Delete</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="contact-alphapets">
                          <div class="alphapets-inner">
                            <a href="#">A</a>
                            <a href="#">B</a>
                            <a href="#">C</a>
                            <a href="#">D</a>
                            <a href="#">E</a>
                            <a href="#">F</a>
                            <a href="#">G</a>
                            <a href="#">H</a>
                            <a href="#">I</a>
                            <a href="#">J</a>
                            <a href="#">K</a>
                            <a href="#">L</a>
                            <a href="#">M</a>
                            <a href="#">N</a>
                            <a href="#">O</a>
                            <a href="#">P</a>
                            <a href="#">Q</a>
                            <a href="#">R</a>
                            <a href="#">S</a>
                            <a href="#">T</a>
                            <a href="#">U</a>
                            <a href="#">V</a>
                            <a href="#">W</a>
                            <a href="#">X</a>
                            <a href="#">Y</a>
                            <a href="#">Z</a>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contact Wrapper -->
          
        </div>
        <!-- /Contact Main Row -->
        
        <!-- Add Contact Modal -->
        <div class="modal custom-modal fade" id="add_contact" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Contact</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Name <span class="text-danger">*</span></label>
                    <input class="form-control" type="text">
                  </div>
                  <div class="form-group">
                    <label>Email Address</label>
                    <input class="form-control" type="email">
                  </div>
                  <div class="form-group">
                    <label>Contact Number <span class="text-danger">*</span></label>
                    <input class="form-control" type="text">
                  </div>
                  <div class="form-group">
                    <label class="d-block">Status</label>
                    <div class="status-toggle">
                      <input type="checkbox" id="contact_status" class="check">
                      <label for="contact_status" class="checktoggle">checkbox</label>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Add Contact Modal -->
        
        <!-- Edit Contact Modal -->
        <div class="modal custom-modal fade" id="edit_contact" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Contact</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Name <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="John Doe">
                  </div>
                  <div class="form-group">
                    <label>Email Address</label>
                    <input class="form-control" type="email" value="johndoe@example.com">
                  </div>
                  <div class="form-group">
                    <label>Contact Number <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="9876543210">
                  </div>
                  <div class="form-group">
                    <label class="d-block">Status</label>
                    <div class="status-toggle">
                      <input type="checkbox" id="edit_contact_status" class="check">
                      <label for="edit_contact_status" class="checktoggle">checkbox</label>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Contact Modal -->

        <!-- Delete Contact Modal -->
        <div class="modal custom-modal fade" id="delete_contact" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Delete Contact</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Delete Contact Modal -->
        
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }

    },
    name: 'contacts'
  }
</Script>