<template>
  <div class="interviewing">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
      <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">Interviewing</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item">Jobs</li>
                  <li class="breadcrumb-item">User Dashboard</li>
                  <li class="breadcrumb-item active">Interviewing</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Content Starts -->
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Solid justified</h4> -->
              <ul class="nav nav-tabs nav-tabs-solid nav-justified">
                <li class="nav-item"><router-link class="nav-link" to="/user-dashboard">Dashboard</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/user-all-jobs">All </router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/saved-jobs">Saved</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/applied-jobs">Applied</router-link></li>
                <li class="nav-item"><router-link class="nav-link active" to="/interviewing">Interviewing</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/offered-jobs">Offered</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/visited-jobs">Visitied </router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/archived-jobs">Archived </router-link></li>
              </ul>
            </div>
          </div>  

          <div class="row">
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                <ul class="nav nav-tabs nav-tabs-solid nav-justified flex-column">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#home">Apptitude</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#menu1">Schedule Interview</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="card">
              <div class="card-body">
                <div class="tab-content">
                  <div id="home" class="tab-pane show active">
                      <div class="card-box">
                      <div class="table-responsive">
                        <table class="table table-striped custom-table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Job Title</th>
                              <th>Department</th>
                              <th class="text-center">Job Type</th>
                              <th class="text-center">Aptitude Test</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td><a href="#">Web Developer</a></td>
                              <td>Development</td>
                              <td class="text-center">
                                <div class="action-label">
                                  <a class="btn btn-white btn-sm btn-rounded" href="#">
                                  <i class="fa fa-dot-circle-o text-danger"></i> Full Time
                                  </a>
                                </div>
                              </td>
                              <td class="text-center">
                                <router-link to="/job-aptitude" class="btn btn-primary aptitude-btn"><span>Click Here</span></router-link>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><a href="#">Web Developer</a></td>
                              <td>Development</td>
                              <td class="text-center">
                                <div class="action-label">
                                  <a class="btn btn-white btn-sm btn-rounded" href="#">
                                  <i class="fa fa-dot-circle-o text-warning"></i> Part Time
                                  </a>
                                </div>
                              </td>
                              <td class="text-center">
                                <router-link to="/job-aptitude" class="btn btn-primary aptitude-btn"><span>Click Here</span></router-link>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><a href="#">Web Designing</a></td>
                              <td>Development</td>
                              <td class="text-center">
                                <div class="action-label">
                                  <a class="btn btn-white btn-sm btn-rounded" href="#">
                                  <i class="fa fa-dot-circle-o text-warning"></i> Part Time
                                  </a>
                                </div>
                              </td>
                              <td class="text-center">
                                <router-link to="/job-aptitude" class="btn btn-primary aptitude-btn"><span>Click Here</span></router-link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div id="menu1" class="tab-pane fade">
                      <div class="card-box">
                      <div class="table-responsive">
                        <table class="table table-striped custom-table mb-0 datatable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Job Title</th>
                              <th>Department</th>
                              <th class="text-center">Job Type</th>
                              <th class="text-center">Aptitude Test</th>
                              <th class="text-center">Schedule Interview</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td><a href="#">Web Developer</a></td>
                              <td>Development</td>
                              <td class="text-center">
                                <div class="action-label">
                                  <a class="btn btn-white btn-sm btn-rounded" href="#">
                                  <i class="fa fa-dot-circle-o text-danger"></i> Full Time
                                  </a>
                                </div>
                              </td>
                              <td class="text-center">
                                <a href="javascript:void(0);" class="btn btn-primary disabled"><span>Selected</span></a>
                              </td>
                              <td class="text-center">
                                <a href="javascript:void(0);" class="btn btn-primary aptitude-btn" data-bs-toggle="modal" data-bs-target="#selectMyTime"><span>Select Your Time Here</span></a>
                              </td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><a href="#">Web Designing</a></td>
                              <td>Development</td>
                              <td class="text-center">
                                <div class="action-label">
                                  <a class="btn btn-white btn-sm btn-rounded" href="#">
                                  <i class="fa fa-dot-circle-o text-warning"></i> Part Time
                                  </a>
                                </div>
                              </td>
                              <td class="text-center">
                                <a href="javascript:void(0);" class="btn btn-primary disabled"><span>Selected</span></a>
                              </td>
                              <td class="text-center">
                                <a href="javascript:void(0);" class="btn btn-primary aptitude-btn" data-bs-toggle="modal" data-bs-target="#selectMyTime"><span>Select Your Time Here</span></a>
                              </td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><a href="#">Web Developer</a></td>
                              <td>Development</td>
                              <td class="text-center">
                                <div class="action-label">
                                  <a class="btn btn-white btn-sm btn-rounded" href="#">
                                  <i class="fa fa-dot-circle-o text-warning"></i> Part Time
                                  </a>
                                </div>
                              </td>
                              <td class="text-center">
                                <a href="javascript:void(0);" class="btn btn-primary disabled"><span>Selected</span></a>
                              </td>
                              <td class="text-center">
                                <a href="javascript:void(0);" class="btn btn-primary aptitude-btn" data-bs-toggle="modal" data-bs-target="#selectMyTime"><span>Select Your Time Here</span></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Content End -->
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
    <div id="selectMyTime" class="modal  custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">

        <!-- Modal content-->
          <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Select Your Time</h4>
            <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Day1 <span class="text-danger">*</span></label>
                  <select class="form-control" name="designations" id="designations">
                    <option value="">11.00am - 11.30am (24 Feb 2020) </option>
                    <option value="">1.00pm - 1.30pm (25 Feb 2020) </option>
                    <option value="">3.00pm - 3.30pm (26 Feb 2020) </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-btn delete-action mt-3">
              <div class="row">
                <div class="col-6">
                  <button type="button" class="btn btn-primary w-100 cancel-btn">Submit</button>
                </div>
                <div class="col-6">
                  <button type="button" class="btn btn-primary w-100 cancel-btn" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
    // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'interviewing'
  }
</Script>