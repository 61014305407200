<template>
  <div class="performanceappraisal">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Performance Appraisal</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Performance</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_appraisal"><i class="fa fa-plus"></i> Add New</a>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped custom-table mb-0 datatable">
                  <thead>
                    <tr>
                      <th style="width: 30px;">#</th>
                      <th>Employee</th>
                      <th>Designation</th>
                      <th>Department</th>
                      <th>Appraisal Date</th>
                      <th>Status</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></router-link>
                          <router-link to="/profile">John Doe </router-link>
                        </h2>
                      </td>
                      <td>Web Designer </td>
                      <td>Designing</td>
                      <td>
                        7 May 2019
                      </td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_appraisal"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_appraisal"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></router-link>
                          <router-link to="/profile">Mike Litorus </router-link>
                        </h2>
                      </td>
                      <td>IOS Developer </td>
                      <td>IOS</td>
                      <td>
                        7 May 2019
                      </td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_appraisal"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_appraisal"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-13.jpg"></router-link>
                          <router-link to="/profile">Bernardo Galaviz </router-link>
                        </h2>
                      </td>
                      <td>Web Developer </td>
                      <td>Designing</td>
                      <td>
                        7 May 2019
                      </td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_appraisal"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_appraisal"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-12.jpg"></router-link>
                          <router-link to="/profile">Jeffrey Warden </router-link>
                        </h2>
                      </td>
                      <td>Web Developer </td>
                      <td>Designing</td>
                      <td>
                        7 May 2019
                      </td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_appraisal"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_appraisal"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-11.jpg"></router-link>
                          <router-link to="/profile">Wilmer Deluna </router-link>
                        </h2>
                      </td>
                      <td>Team Leader </td>
                      <td>IOS</td>
                      <td>
                        7 May 2019
                      </td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Active</a>
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Inactive</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_appraisal"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_appraisal"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
                </div>
        <!-- /Page Content -->

        <!-- Add Performance Appraisal Modal -->
        <div id="add_appraisal" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Give Performance Appraisal</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Employee</label>
                        <select class="select">
                          <option>Select Employee</option>
                          <option>John Doe</option>
                          <option>Mike Litorus</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label>Select Date <span class="text-danger">*</span></label>
                        <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="tab-box">
                            <div class="row user-tabs">
                              <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                                <ul class="nav nav-tabs nav-tabs-solid">
                                  <li class="nav-item"><a href="#appr_technical" data-bs-toggle="tab" class="nav-link active">Technical</a></li>
                                  <li class="nav-item"><a href="#appr_organizational" data-bs-toggle="tab" class="nav-link">Organizational</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="tab-content">
                            <div id="appr_technical" class="pro-overview tab-pane fade show active">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="bg-white">
                                    <table class="table">
                                      <thead>
                                        <tr>
                                        <th>Technical Competencies</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                        <th colspan="2">Indicator</th>
                                        <th colspan="2">Expected Value</th>
                                        <th>Set Value</th>
                                        </tr>
                                        <tr>
                                        <td colspan="2">Customer Experience</td>
                                        <td colspan="2">Intermediate</td>
                                        <td><select name="customer_experience" class="form-control">
                                          <option value="">None</option>
                                          <option value="1"> Beginner</option>
                                          <option value="2"> Intermediate</option>
                                          <option value="3"> Advanced</option>
                                          <option value="4"> Expert / Leader</option>
                                          </select></td>
                                        </tr>
                                        <tr>
                                        <td colspan="2">Marketing</td>
                                        <td colspan="2">Advanced</td>
                                        <td><select name="marketing" class="form-control">
                                          <option value="">None</option>
                                          <option value="1"> Beginner</option>
                                          <option value="2"> Intermediate</option>
                                          <option value="3"> Advanced</option>
                                          <option value="4"> Expert / Leader</option>
                                          </select></td>
                                        </tr>
                                        <tr>
                                        <td colspan="2">Management</td>
                                        <td colspan="2">Advanced</td>
                                        <td><select name="management" class="form-control">
                                          <option value="">None</option>
                                          <option value="1"> Beginner</option>
                                          <option value="2"> Intermediate</option>
                                          <option value="3"> Advanced</option>
                                          <option value="4"> Expert / Leader</option>
                                          </select></td>
                                        </tr>
                                        <tr>
                                        <td colspan="2">Administration</td>
                                        <td colspan="2">Advanced</td>
                                        <td><select name="administration" class="form-control">
                                          <option value="">None</option>
                                          <option value="1"> Beginner</option>
                                          <option value="2"> Intermediate</option>
                                          <option value="3"> Advanced</option>
                                          <option value="4"> Expert / Leader</option>
                                          </select></td>
                                        </tr>
                                        <tr>
                                        <td colspan="2">Presentation Skill</td>
                                        <td colspan="2">Expert / Leader</td>
                                        <td><select name="presentation_skill" class="form-control">
                                          <option value="">None</option>
                                          <option value="1"> Beginner</option>
                                          <option value="2"> Intermediate</option>
                                          <option value="3"> Advanced</option>
                                          <option value="4"> Expert / Leader</option>
                                          </select></td>
                                        </tr>
                                        <tr>
                                        <td colspan="2">Quality Of Work</td>
                                        <td colspan="2">Expert / Leader</td>
                                        <td><select name="quality_of_work" class="form-control">
                                          <option value="">None</option>
                                          <option value="1"> Beginner</option>
                                          <option value="2"> Intermediate</option>
                                          <option value="3"> Advanced</option>
                                          <option value="4"> Expert / Leader</option>
                                          </select></td>
                                        </tr>
                                        <tr>
                                        <td colspan="2">Efficiency</td>
                                        <td colspan="2">Expert / Leader</td>
                                        <td><select name="efficiency" class="form-control">
                                          <option value="">None</option>
                                          <option value="1"> Beginner</option>
                                          <option value="2"> Intermediate</option>
                                          <option value="3"> Advanced</option>
                                          <option value="4"> Expert / Leader</option>
                                          </select></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="appr_organizational">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="bg-white">
                                    <table class="table">
                                      <thead>
                                        <tr>
                                          <th>Organizational Competencies</th>
                                          <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                          <th colspan="2">Indicator</th>
                                          <th colspan="2">Expected Value</th>
                                          <th>Set Value</th>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Integrity</td>
                                          <td colspan="2">Beginner</td>
                                          <td>
                                            <select name="integrity" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Professionalism</td>
                                          <td colspan="2">Beginner</td>
                                          <td>
                                            <select name="professionalism" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Team Work</td>
                                          <td colspan="2">Intermediate</td>
                                          <td>
                                            <select name="team_work" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Critical Thinking</td>
                                          <td colspan="2">Advanced</td>
                                          <td>
                                            <select name="critical_thinking" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Conflict Management</td>
                                          <td colspan="2">Intermediate</td>
                                          <td>
                                            <select name="conflict_management" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Attendance</td>
                                          <td colspan="2">Intermediate</td>
                                          <td>
                                            <select name="attendance" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Ability To Meet Deadline</td>
                                          <td colspan="2">Advanced</td>
                                          <td>
                                            <select name="ability_to_meet_deadline" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Status</label>
                        <select class="select">
                          <option>Active</option>
                          <option>Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Add Performance Appraisal Modal -->
        
        <!-- Edit Performance Appraisal Modal -->
        <div id="edit_appraisal" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Performance Appraisal</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Employee</label>
                        <select class="select">
                          <option>Select Employee</option>
                          <option>John Doe</option>
                          <option selected>Mike Litorus</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label>Select Date <span class="text-danger">*</span></label>
                        <div class="cal-icon"><input class="form-control datetimepicker" value="7/08/2019" type="text"></div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="tab-box">
                            <div class="row user-tabs">
                              <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                                <ul class="nav nav-tabs nav-tabs-solid">
                                  <li class="nav-item"><a href="#appr_technical1" data-bs-toggle="tab" class="nav-link active">Technical</a></li>
                                  <li class="nav-item"><a href="#appr_organizational1" data-bs-toggle="tab" class="nav-link">Organizational</a></li>
                                  
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="tab-content">
                            <div id="appr_technical1" class="pro-overview tab-pane fade show active">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="bg-white">
                                    <table class="table">
                                      <thead>
                                        <tr>
                                          <th>Technical Competencies</th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th colspan="2">Indicator</th>
                                          <th colspan="2">Expected Value</th>
                                          <th>Set Value</th>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Customer Experience</td>
                                          <td colspan="2">Intermediate</td>
                                          <td>
                                            <select name="customer_experience" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                              <option value="4"> Expert / Leader</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Marketing</td>
                                          <td colspan="2">Advanced</td>
                                          <td>
                                            <select name="marketing" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                              <option value="4"> Expert / Leader</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Management</td>
                                          <td colspan="2">Advanced</td>
                                          <td>
                                            <select name="management" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                              <option value="4"> Expert / Leader</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Administration</td>
                                          <td colspan="2">Advanced</td>
                                          <td>
                                            <select name="administration" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                              <option value="4"> Expert / Leader</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Presentation Skill</td>
                                          <td colspan="2">Expert / Leader</td>
                                          <td>
                                            <select name="presentation_skill" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                              <option value="4"> Expert / Leader</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Quality Of Work</td>
                                          <td colspan="2">Expert / Leader</td>
                                          <td>
                                            <select name="quality_of_work" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                              <option value="4"> Expert / Leader</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Efficiency</td>
                                          <td colspan="2">Expert / Leader</td>
                                          <td>
                                            <select name="efficiency" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                              <option value="4"> Expert / Leader</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="appr_organizational1">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="bg-white">
                                    <table class="table">
                                      <thead>
                                        <tr>
                                          <th>Organizational Competencies</th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th colspan="2">Indicator</th>
                                          <th colspan="2">Expected Value</th>
                                          <th>Set Value</th>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Integrity</td>
                                          <td colspan="2">Beginner</td>
                                          <td>
                                            <select name="integrity" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Professionalism</td>
                                          <td colspan="2">Beginner</td>
                                          <td>
                                            <select name="professionalism" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Team Work</td>
                                          <td colspan="2">Intermediate</td>
                                          <td>
                                            <select name="team_work" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Critical Thinking</td>
                                          <td colspan="2">Advanced</td>
                                          <td>
                                            <select name="critical_thinking" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Conflict Management</td>
                                          <td colspan="2">Intermediate</td>
                                          <td>
                                            <select name="conflict_management" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Attendance</td>
                                          <td colspan="2">Intermediate</td>
                                          <td>
                                            <select name="attendance" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="2">Ability To Meet Deadline</td>
                                          <td colspan="2">Advanced</td>
                                          <td>
                                            <select name="ability_to_meet_deadline" class="form-control">
                                              <option value="">None</option>
                                              <option value="1"> Beginner</option>
                                              <option value="2"> Intermediate</option>
                                              <option value="3"> Advanced</option>
                                            </select>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Status</label>
                        <select class="select">
                          <option>Active</option>
                          <option>Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Performance Appraisal Modal -->
        
        <!-- Delete Performance Appraisal Modal -->
        <div class="modal custom-modal fade" id="delete_appraisal" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Delete Performance Appraisal List</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Delete Performance Appraisal Modal -->
      
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  
  // Datatable

  if($('.datatable').length > 0) {
    $('.datatable').DataTable({
      "bFilter": false,
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'performanceappraisal'
  }
</Script>