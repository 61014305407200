import { createApp } from 'vue';
import { router } from './router';
import './assets/css/bootstrap.min.css'
import './assets/css/font-awesome.min.css'
import './assets/css/line-awesome.min.css'
import { BarChart } from 'vue-morris'
import { LineChart } from 'vue-morris'
import App from "./App.vue";
import LayoutHeader from './components/layouts/Header.vue'
import LayoutHeaderEmployee from './components/layouts/HeaderEmployee.vue'
import LayoutHeaderApp from './components/layouts/HeaderApp.vue'
import LayoutSidebar from './components/layouts/Sidebar.vue'
import LayoutSidebarEmployee from './components/layouts/SidebarEmployee.vue'
import LayoutSidebarApp from './components/layouts/SidebarApp.vue'
import LayoutSidebarApp1 from './components/layouts/SidebarApp1.vue'
import LayoutSidebarApp2 from './components/layouts/SidebarApp2.vue'
import LayoutSidebarReports from './components/layouts/SidebarReports.vue'
import LayoutSidebarPromotion from './components/layouts/SidebarPromotion.vue'
import LayoutSidebarGoals from './components/layouts/SidebarGoals.vue'
import jquery from 'jquery'; 
window.$ = jquery
import './assets/css/select2.min.css'
import './assets/js/select2.min.js'
import './assets/css/bootstrap-datetimepicker.min.css'
import './assets/js/bootstrap-datetimepicker.min.js'
import './assets/css/fullcalendar.min.css'
import './assets/css/style.css'
import './assets/js/sticky-kit-master/dist/sticky-kit.min.js'
import './assets/js/bootstrap.bundle.min.js'
import './assets/js/Chart.min.js'
import './assets/js/multiselect.min.js'
import './assets/js/jquery.maskedinput.min.js'
import './assets/js/jquery-ui.min.js'
import './assets/js/fullcalendar.min.js'
import './assets/css/bootstrap4.min.css'
import './assets/css/dataTables.bootstrap4.min.css'
import './assets/css/jquery.dataTables.min.js'
import './assets/css/dataTables.bootstrap4.min.js'
import './components/main.js'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
const app = createApp(App)
app.component('layout-header', LayoutHeader);
app.component('layout-headerEmployee', LayoutHeaderEmployee);
app.component('layout-headerApp', LayoutHeaderApp);
app.component('layout-sidebar', LayoutSidebar);
app.component('layout-sidebarEmployee', LayoutSidebarEmployee);
app.component('layout-sidebarApp', LayoutSidebarApp);
app.component('layout-sidebarApp1', LayoutSidebarApp1);
app.component('layout-sidebarApp2', LayoutSidebarApp2);
app.component('layout-sidebarReports', LayoutSidebarReports);
app.component('layout-sidebarPromotion', LayoutSidebarPromotion);
app.component('layout-sidebarGoals', LayoutSidebarGoals);
app.use(router)
    .mount('#app');