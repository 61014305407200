<template>
  <div class="employees">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Employee</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Employee</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_employee"><i class="fa fa-plus"></i> Add Employee</a>
                <div class="view-icons">
                  <router-link to="/employees" class="grid-view btn btn-link active me-2"><i class="fa fa-th"></i></router-link>
                  <router-link to="/employees-list" class="list-view btn btn-link"><i class="fa fa-bars"></i></router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Search Filter -->
          <div class="row filter-row">
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input type="text" class="form-control floating">
                <label class="focus-label">Employee ID</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input type="text" class="form-control floating">
                <label class="focus-label">Employee Name</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3"> 
              <div class="form-group form-focus select-focus">
                <select class="select floating"> 
                  <option>Select Designation</option>
                  <option>Web Developer</option>
                  <option>Web Designer</option>
                  <option>Android Developer</option>
                  <option>Ios Developer</option>
                </select>
                <label class="focus-label">Designation</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="d-grid">
                <a href="#" class="btn btn-success w-100"> Search </a>  
              </div>  
            </div>
                    </div>
          <!-- Search Filter -->
          
          <div class="row staff-grid-row">
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-02.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">John Doe</router-link></h4>
                <div class="small text-muted">Web Designer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-09.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Richard Miles</router-link></h4>
                <div class="small text-muted">Web Developer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-10.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">John Smith</router-link></h4>
                <div class="small text-muted">Android Developer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-05.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Mike Litorus</router-link></h4>
                <div class="small text-muted">IOS Developer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-11.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Wilmer Deluna</router-link></h4>
                <div class="small text-muted">Team Leader</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-12.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Jeffrey Warden</router-link></h4>
                <div class="small text-muted">Web Developer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-13.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Bernardo Galaviz</router-link></h4>
                <div class="small text-muted">Web Developer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-01.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Lesley Grauer</router-link></h4>
                <div class="small text-muted">Team Leader</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-16.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Jeffery Lalor</router-link></h4>
                <div class="small text-muted">Team Leader</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-04.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Loren Gatlin</router-link></h4>
                <div class="small text-muted">Android Developer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-03.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Tarah Shropshire</router-link></h4>
                <div class="small text-muted">Android Developer</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div class="profile-widget">
                <div class="profile-img">
                  <router-link to="/profile" class="avatar"><img src="../../assets/img/profiles/avatar-08.jpg" alt=""></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                  </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/profile">Catherine Manseau</router-link></h4>
                <div class="small text-muted">Android Developer</div>
              </div>
            </div>
          </div>
                </div>
        <!-- /Page Content -->
        
        <!-- Add Employee Modal -->
        <div id="add_employee" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Employee</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Last Name</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Username <span class="text-danger">*</span></label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Email <span class="text-danger">*</span></label>
                        <input class="form-control" type="email">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <input class="form-control" type="password">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Confirm Password</label>
                        <input class="form-control" type="password">
                      </div>
                    </div>
                    <div class="col-sm-6">  
                      <div class="form-group">
                        <label class="col-form-label">Employee ID <span class="text-danger">*</span></label>
                        <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-sm-6">  
                      <div class="form-group">
                        <label class="col-form-label">Joining Date <span class="text-danger">*</span></label>
                        <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Phone </label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Company</label>
                        <select class="select">
                          <option value="">Global Technologies</option>
                          <option value="1">Delta Infotech</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Department <span class="text-danger">*</span></label>
                        <select class="select">
                          <option>Select Department</option>
                          <option>Web Development</option>
                          <option>IT Management</option>
                          <option>Marketing</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Designation <span class="text-danger">*</span></label>
                        <select class="select">
                          <option>Select Designation</option>
                          <option>Web Designer</option>
                          <option>Web Developer</option>
                          <option>Android Developer</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive m-t-15">
                    <table class="table table-striped custom-table">
                      <thead>
                        <tr>
                          <th>Module Permission</th>
                          <th class="text-center">Read</th>
                          <th class="text-center">Write</th>
                          <th class="text-center">Create</th>
                          <th class="text-center">Delete</th>
                          <th class="text-center">Import</th>
                          <th class="text-center">Export</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Holidays</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Leaves</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Clients</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Projects</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Tasks</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Chats</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Assets</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Timing Sheets</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Add Employee Modal -->
        
        <!-- Edit Employee Modal -->
        <div id="edit_employee" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Employee</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                        <input class="form-control" value="John" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Last Name</label>
                        <input class="form-control" value="Doe" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Username <span class="text-danger">*</span></label>
                        <input class="form-control" value="johndoe" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Email <span class="text-danger">*</span></label>
                        <input class="form-control" value="johndoe@example.com" type="email">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <input class="form-control" value="johndoe" type="password">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Confirm Password</label>
                        <input class="form-control" value="johndoe" type="password">
                      </div>
                    </div>
                    <div class="col-sm-6">  
                      <div class="form-group">
                        <label class="col-form-label">Employee ID <span class="text-danger">*</span></label>
                        <input type="text" value="FT-0001" readonly class="form-control floating">
                      </div>
                    </div>
                    <div class="col-sm-6">  
                      <div class="form-group">
                        <label class="col-form-label">Joining Date <span class="text-danger">*</span></label>
                        <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Phone </label>
                        <input class="form-control" value="9876543210" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Company</label>
                        <select class="select">
                          <option>Global Technologies</option>
                          <option>Delta Infotech</option>
                          <option selected>International Software Inc</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Department <span class="text-danger">*</span></label>
                        <select class="select">
                          <option>Select Department</option>
                          <option>Web Development</option>
                          <option>IT Management</option>
                          <option>Marketing</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Designation <span class="text-danger">*</span></label>
                        <select class="select">
                          <option>Select Designation</option>
                          <option>Web Designer</option>
                          <option>Web Developer</option>
                          <option>Android Developer</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive m-t-15">
                    <table class="table table-striped custom-table">
                      <thead>
                        <tr>
                          <th>Module Permission</th>
                          <th class="text-center">Read</th>
                          <th class="text-center">Write</th>
                          <th class="text-center">Create</th>
                          <th class="text-center">Delete</th>
                          <th class="text-center">Import</th>
                          <th class="text-center">Export</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Holidays</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Leaves</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Clients</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Projects</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Tasks</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Chats</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Assets</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                        <tr>
                          <td>Timing Sheets</td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input checked="" type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                          <td class="text-center">
                            <input type="checkbox">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Employee Modal -->
        
        <!-- Delete Employee Modal -->
        <div class="modal custom-modal fade" id="delete_employee" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Delete Employee</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Delete Employee Modal -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  
  // Datatable

  if($('.datatable').length > 0) {
    $('.datatable').DataTable({
      "bFilter": false,
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'employees'
  }
</Script>