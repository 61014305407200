<template>
  <div class="compose">
    <div class="main-wrapper">
      <layout-headerEmployee></layout-headerEmployee>
      <layout-sidebarApp1></layout-sidebarApp1>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">Compose</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Compose</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <form>
                    <div class="form-group">
                      <input type="email" placeholder="To" class="form-control">
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="email" placeholder="Cc" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="email" placeholder="Bcc" class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input type="text" placeholder="Subject" class="form-control">
                    </div>
                    <div class="form-group">
                      <textarea rows="4" class="form-control summernote" placeholder="Enter your message here"></textarea>
                    </div>
                    <div class="form-group mb-0">
                      <div class="text-center">
                        <button class="btn btn-primary"><span>Send</span> <i class="fa fa-send m-l-5"></i></button>
                        <button class="btn btn-success m-l-5" type="button"><span>Draft</span> <i class="fa fa-floppy-o m-l-5"></i></button>
                        <button class="btn btn-success m-l-5" type="button"><span>Delete</span> <i class="fa fa-trash-o m-l-5"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {

    // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }


  // Datatable

  if($('.datatable').length > 0) {
    $('.datatable').DataTable({
      "bFilter": false,
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'compose'
  }
</Script>