<template>
  <div class="projects">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Projects</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Projects</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#create_project"><i class="fa fa-plus"></i> Create Project</a>
                <div class="view-icons">
                  <router-link to="/projects" class="grid-view btn btn-link active me-2"><i class="fa fa-th"></i></router-link>
                  <router-link to="/project-list" class="list-view btn btn-link"><i class="fa fa-bars"></i></router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Search Filter -->
          <div class="row filter-row">
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input type="text" class="form-control floating">
                <label class="focus-label">Project Name</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus">
                <input type="text" class="form-control floating">
                <label class="focus-label">Employee Name</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3"> 
              <div class="form-group form-focus select-focus">
                <select class="select floating"> 
                  <option>Select Roll</option>
                  <option>Web Developer</option>
                  <option>Web Designer</option>
                  <option>Android Developer</option>
                  <option>Ios Developer</option>
                </select>
                <label class="focus-label">Designation</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">  
              <a href="#" class="btn btn-success w-100"> Search </a>  
            </div>     
                    </div>
          <!-- Search Filter -->
          
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
              <div class="card">
                <div class="card-body">
                  <div class="dropdown dropdown-action profile-action">
                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                    </div>
                  </div>
                  <h4 class="project-title"><router-link to="/project-view">Office Management</router-link></h4>
                  <small class="block text-ellipsis m-b-15">
                    <span class="text-xs">1</span> <span class="text-muted">open tasks, </span>
                    <span class="text-xs">9</span> <span class="text-muted">tasks completed</span>
                  </small>
                  <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. When an unknown printer took a galley of type and
                    scrambled it...
                  </p>
                  <div class="pro-deadline m-b-15">
                    <div class="sub-title">
                      Deadline:
                    </div>
                    <div class="text-muted">
                      17 Apr 2019
                    </div>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Project Leader :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                      </li>
                    </ul>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Team :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="#">1</a></li>
                              <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                  <div class="progress progress-xs mb-0">
                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
              <div class="card">
                <div class="card-body">
                  <div class="dropdown dropdown-action profile-action">
                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                    </div>
                  </div>
                  <h4 class="project-title"><router-link to="/project-view">Project Management</router-link></h4>
                  <small class="block text-ellipsis m-b-15">
                    <span class="text-xs">2</span> <span class="text-muted">open tasks, </span>
                    <span class="text-xs">5</span> <span class="text-muted">tasks completed</span>
                  </small>
                  <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. When an unknown printer took a galley of type and
                    scrambled it...
                  </p>
                  <div class="pro-deadline m-b-15">
                    <div class="sub-title">
                      Deadline:
                    </div>
                    <div class="text-muted">
                      17 Apr 2019
                    </div>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Project Leader :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                      </li>
                    </ul>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Team :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="#">1</a></li>
                              <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                  <div class="progress progress-xs mb-0">
                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
              <div class="card">
                <div class="card-body">
                  <div class="dropdown dropdown-action profile-action">
                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                    </div>
                  </div>
                  <h4 class="project-title"><router-link to="/project-view">Video Calling App</router-link></h4>
                  <small class="block text-ellipsis m-b-15">
                    <span class="text-xs">3</span> <span class="text-muted">open tasks, </span>
                    <span class="text-xs">3</span> <span class="text-muted">tasks completed</span>
                  </small>
                  <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. When an unknown printer took a galley of type and
                    scrambled it...
                  </p>
                  <div class="pro-deadline m-b-15">
                    <div class="sub-title">
                      Deadline:
                    </div>
                    <div class="text-muted">
                      17 Apr 2019
                    </div>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Project Leader :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                      </li>
                    </ul>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Team :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="#">1</a></li>
                              <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                  <div class="progress progress-xs mb-0">
                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
              <div class="card">
                <div class="card-body">
                  <div class="dropdown dropdown-action profile-action">
                    <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                    </div>
                  </div>
                  <h4 class="project-title"><router-link to="/project-view">Hospital Administration</router-link></h4>
                  <small class="block text-ellipsis m-b-15">
                    <span class="text-xs">12</span> <span class="text-muted">open tasks, </span>
                    <span class="text-xs">4</span> <span class="text-muted">tasks completed</span>
                  </small>
                  <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. When an unknown printer took a galley of type and
                    scrambled it...
                  </p>
                  <div class="pro-deadline m-b-15">
                    <div class="sub-title">
                      Deadline:
                    </div>
                    <div class="text-muted">
                      17 Apr 2019
                    </div>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Project Leader :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                      </li>
                    </ul>
                  </div>
                  <div class="project-members m-b-15">
                    <div>Team :</div>
                    <ul class="team-members">
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                      </li>
                      <li class="dropdown avatar-dropdown">
                        <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <div class="avatar-group">
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                            </a>
                            <a class="avatar avatar-xs" href="#">
                              <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                            </a>
                          </div>
                          <div class="avatar-pagination">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">«</span>
                                  <span class="visually-hidden">Previous</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="#">1</a></li>
                              <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">»</span>
                                <span class="visually-hidden">Next</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                  <div class="progress progress-xs mb-0">
                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                </div>
        <!-- /Page Content -->
        
        <!-- Create Project Modal -->
        <div id="create_project" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Create Project</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Project Name</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Client</label>
                        <select class="select">
                          <option>Global Technologies</option>
                          <option>Delta Infotech</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Start Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>End Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Rate</label>
                        <input placeholder="$50" class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <select class="select">
                          <option>Hourly</option>
                          <option>Fixed</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Priority</label>
                        <select class="select">
                          <option>High</option>
                          <option>Medium</option>
                          <option>Low</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Project Leader</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Leader</label>
                        <div class="project-members">
                          <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor" class="avatar">
                            <img src="../../assets/img/profiles/avatar-16.jpg" alt="">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Team</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Members</label>
                        <div class="project-members">
                          <a href="#" data-bs-toggle="tooltip" title="John Doe" class="avatar">
                            <img src="../../assets/img/profiles/avatar-16.jpg" alt="">
                          </a>
                          <a href="#" data-bs-toggle="tooltip" title="Richard Miles" class="avatar">
                            <img src="../../assets/img/profiles/avatar-09.jpg" alt="">
                          </a>
                          <a href="#" data-bs-toggle="tooltip" title="John Smith" class="avatar">
                            <img src="../../assets/img/profiles/avatar-10.jpg" alt="">
                          </a>
                          <a href="#" data-bs-toggle="tooltip" title="Mike Litorus" class="avatar">
                            <img src="../../assets/img/profiles/avatar-05.jpg" alt="">
                          </a>
                          <span class="all-team">+2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea rows="4" class="form-control summernote" placeholder="Enter your message here"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Upload Files</label>
                    <input class="form-control" type="file">
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Create Project Modal -->
        
        <!-- Edit Project Modal -->
        <div id="edit_project" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Project</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Project Name</label>
                        <input class="form-control" value="Project Management" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Client</label>
                        <select class="select">
                          <option>Global Technologies</option>
                          <option>Delta Infotech</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Start Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>End Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Rate</label>
                        <input placeholder="$50" class="form-control" value="$5000" type="text">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <select class="select">
                          <option>Hourly</option>
                          <option selected>Fixed</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Priority</label>
                        <select class="select">
                          <option selected>High</option>
                          <option>Medium</option>
                          <option>Low</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Project Leader</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Leader</label>
                        <div class="project-members">
                          <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor" class="avatar">
                            <img src="../../assets/img/profiles/avatar-16.jpg" alt="">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Team</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Members</label>
                        <div class="project-members">
                          <a href="#" data-bs-toggle="tooltip" title="John Doe" class="avatar">
                            <img src="../../assets/img/profiles/avatar-16.jpg" alt="">
                          </a>
                          <a href="#" data-bs-toggle="tooltip" title="Richard Miles" class="avatar">
                            <img src="../../assets/img/profiles/avatar-09.jpg" alt="">
                          </a>
                          <a href="#" data-bs-toggle="tooltip" title="John Smith" class="avatar">
                            <img src="../../assets/img/profiles/avatar-10.jpg" alt="">
                          </a>
                          <a href="#" data-bs-toggle="tooltip" title="Mike Litorus" class="avatar">
                            <img src="../../assets/img/profiles/avatar-05.jpg" alt="">
                          </a>
                          <span class="all-team">+2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea rows="4" class="form-control" placeholder="Enter your message here"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Upload Files</label>
                    <input class="form-control" type="file">
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Project Modal -->
        
        <!-- Delete Project Modal -->
        <div class="modal custom-modal fade" id="delete_project" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Delete Project</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Delete Project Modal -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
  
    },
    name: 'projects'
  }
</Script>