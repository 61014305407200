<template>
  <div class="approvalsetting">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebarPromotion></layout-sidebarPromotion>
       <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-md-12">

                            <!-- Page Header -->
                            <div class="page-header mb-2">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">Approval Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->

                            <!-- Nav tabs -->
                            <ul class="nav nav-tabs nav-tabs-bottom" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                    Expenses Approval
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Leave Approval</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="messages-tab" data-bs-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Offer Approval</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="settings-tab" data-bs-toggle="tab" href="#settings" role="tab" aria-controls="settings" aria-selected="false">Resignation Notice</a>
                                </li>
                            </ul>

                            <!-- Tab panes -->
                            <div class="tab-content">
                                <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <h4>Expense Approval Settings</h4>
                                    <div class="form-group row">
                                        <label class="control-label col-md-12">Default Expense Approval</label>
                                        <div class="col-md-12 approval-option">
                                            <label class="radio-inline">
                                            <input id="radio-single1" class="me-2 default_expense_approval" value="seq-approver" checked="" name="default_expense_approval" type="radio">Sequence Approval (Chain) <sup> <span class="badge info-badge"><i class="fa fa-info" aria-hidden="true"></i></span></sup>
                                            </label>
                                            <label class="radio-inline ms-2">
                                            <input id="radio-multiple3" class="me-2 default_expense_approval" value="sim-approver" name="default_expense_approval" type="radio">Simultaneous Approval <sup><span class="badge info-badge"><i class="fa fa-info" aria-hidden="true"></i></span></sup>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group  form-row row approver seq-approver" style="display: block;">
                                        <label class="control-label col-sm-3">Expense Approvers</label>
                                        <div class="col-sm-9 ">
                                            <label class="ex_exp_approvers_1 control-label mb-2 exp_appr" style="padding-left:0">Approver 1</label>
                                            <div class="row ex_exp_approvers_1 form-group">
                                                <div class="col-md-6">
                                                    <select class="select form-control expense_approvers" style="width:260px" name="expense_approvers[]" tabindex="-1" aria-hidden="true">
                                                        <option value="">Select Approvers</option>
                                                        <option value="1">CEO</option>
                                                        <option value="9">Direct Manager</option>
                                                        <option value="11">Development Manager 
                                                        </option>
                                                        <option value="6">Finance</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <label class="ex_exp_approvers_2 control-label mb-2 exp_appr" style="padding-left:0">Approver 2</label>
                                            <div class="row ex_exp_approvers_2 form-group">
                                                <div class="col-md-6">
                                                    <select class="select form-control expense_approvers " style="width:260px" name="expense_approvers[]" tabindex="-1" aria-hidden="true">
                                                        <option value="">Select Approvers</option>
                                                        <option value="1">CEO</option>
                                                        <option value="9">Direct Manager</option>
                                                        <option value="11">Development Manager 
                                                        </option>
                                                        <option value="6">Finance</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2"><a class="remove_ex_exp_approver btn rounded border text-danger" data-id="2"><i class="fa fa-times" aria-hidden="true"></i></a></div>
                                            </div>
                                            <label class="ex_exp_approvers_3 control-label m-b-10 exp_appr" style="padding-left:0">Approver 3</label>
                                            <div class="row ex_exp_approvers_3 form-group">
                                                <div class="col-md-6">
                                                    <select class="select form-control expense_approvers" style="width:260px" name="expense_approvers[]" tabindex="-1" aria-hidden="true">
                                                        <option value="">Select Approvers</option>
                                                        <option value="1">CEO</option>
                                                        <option value="9">Direct Manager</option>
                                                        <option value="11">Development Manager 
                                                        </option>
                                                        <option value="6">Finance</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2"><a class="remove_ex_exp_approver btn rounded border text-danger" data-id="3"><i class="fa fa-times" aria-hidden="true"></i></a></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-9 col-md-offset-3 m-t-10">
                                                <a id="add_expense_approvers" href="javascript:void(0)" class="add-more">+ Add Approver</a>
                                            </div>
                                        </div>
                                        <div class="m-t-30">
                                            <div class="col-md-12 submit-section">
                                                <button id="expense_approval_set_btn" type="submit" class="btn btn-primary submit-btn">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="form-group row">
                                        <label class="control-label col-md-12">Default Leave Approval</label>
                                        <div class="col-md-12 approval-option">
                                            <label class="radio-inline">
                                            <input id="radio-single" class="me-2 default_offer_approval" value="seq-approver" name="default_leave_approval" type="radio">Sequence Approval (Chain) <sup> <span class="badge info-badge"><i class="fa fa-info" aria-hidden="true"></i></span></sup>
                                            </label>
                                            <label class="radio-inline ms-2">
                                            <input id="radio-multiple1" class="me-2 default_offer_approval" value="sim-approver" checked="" name="default_leave_approval" type="radio">Simultaneous Approval <sup><span class="badge info-badge"><i class="fa fa-info" aria-hidden="true"></i></span></sup>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12">leave Approvers</label>
                                        <div class="col-sm-6">
                                            <label class="control-label" style="margin-bottom:10px;padding-left:0">Simultaneous Approval </label>
                                            <select class="select form-control" multiple>
                                                <option>Select</option>
                                                <option>Test Lead</option>
                                                <option>UI/UX Designer</option>
                                                <option>Sox Analyst</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="m-t-30 row ">
                                        <div class="col-md-12 submit-section">
                                            <button id="leave_approval_set_btn" type="submit" class="btn btn-primary submit-btn">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                                    <form>
                                        <h4>Offer Approval Settings</h4>
                                        <div class="form-group">
                                            <label class="control-label col-md-12">Default Offer Approval</label>
                                            <div class="col-md-12 approval-option">
                                                <label class="radio-inline">
                                                <input id="radio-single2" class="me-2 default_offer_approval" value="seq-approver" name="default_offer_approval" type="radio">Sequence Approval (Chain) <sup> <span class="badge info-badge"><i class="fa fa-info" aria-hidden="true"></i></span></sup>
                                                </label>
                                                <label class="radio-inline ms-2">
                                                <input id="radio-multiple2" class="me-2 default_offer_approval" value="sim-approver" checked="" name="default_offer_approval" type="radio">Simultaneous Approval <sup><span class="badge info-badge"><i class="fa fa-info" aria-hidden="true"></i></span></sup>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="m-t-30">
                                            <div class="col-md-12 submit-section">
                                                <button id="offer_approval_set_btn" type="submit" class="btn btn-primary submit-btn">Save Changes</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                    <form >
                                        <h3>Resignation Notice</h3>
                                        <div class="form-group row">
                                            <label class="col-sm-12">Email Notification <span class="text-danger">*</span></label>
                                            <div class="col-sm-6">
                                                <label class="control-label">Simultaneous Approval </label>
                                                <select class="select form-control" multiple>
                                                    <option>Select</option>
                                                    <option>Test Lead</option>
                                                    <option>UI/UX Designer</option>
                                                    <option>Sox Analyst</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12">Notice Days <span class="text-danger">*</span></label>
                                            <div class="col-md-6 approval-option">
                                                <input type="number" name="notice_days" class="form-control notice_days" value="15">
                                            </div>
                                        </div>
                                        <div class="m-t-30">
                                            <div class="col-md-12 submit-section">
                                                <button id="resignation_notice_set_btn" type="submit" class="btn btn-primary submit-btn">Save Changes</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
    // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  
  // Datatable

  if($('.datatable').length > 0) {
    $('.datatable').DataTable({
      "bFilter": false,
    });
  }
  // Chat sidebar overlay
    
    $(document).on('click', '#task_chat', function() {
        $('.sidebar-overlay').toggleClass('opened');
        $('#task_window').addClass('opened');
        return false;
    });
// Modal Popup hide show

    if($('.modal').length > 0 ){
        var modalUniqueClass = ".modal";
        $('.modal').on('show.bs.modal', function(e) {
          var $element = $(this);
          var $uniques = $(modalUniqueClass + ':visible').not($(this));
          if ($uniques.length) {
            $uniques.modal('hide');
            $uniques.one('hidden.bs.modal', function(e) {
              $element.modal('show');
            });
            return false;
          }
        });
    }
    // Email Inbox

    if($('.clickable-row').length > 0 ){
        $(".clickable-row").click(function() {
            window.location = $(this).data("href");
        });
    }

    // Check all email
    
    $(document).on('click', '#check_all', function() {
        $('.checkmail').click();
        return false;
    });
    if($('.checkmail').length > 0) {
        $('.checkmail').each(function() {
            $(this).on('click', function() {
                if($(this).closest('tr').hasClass('checked')) {
                    $(this).closest('tr').removeClass('checked');
                } else {
                    $(this).closest('tr').addClass('checked');
                }
            });
        });
    }
    
    // Mail important
    
    $(document).on('click', '.mail-important', function() {
        $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
    });
// Task Complete
    
    $(document).on('click', '#task_complete', function() {
        $(this).toggleClass('task-completed');
        return false;
    });
    
    // Multiselect

    if($('#customleave_select').length > 0) {
        $('#customleave_select').multiselect();
    }
    if($('#edit_customleave_select').length > 0) {
        $('#edit_customleave_select').multiselect();
    }
// Leave Settings button show
    
    $(document).on('click', '.leave-edit-btn', function() {
        $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
        $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
        $(this).parent().parent().find("input").prop('disabled', false);
        return false;
    });
    $(document).on('click', '.leave-cancel-btn', function() {
        $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
        $(this).closest("div.leave-right").find(".leave-save-btn").remove();
        $(this).parent().parent().find("input").prop('disabled', true);
        return false;
    });
    
    $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
        var id = $(this).attr('id').split('_')[1];
        if ($(this).prop("checked") == true) {
            $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
            $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
        }
        else {
            $("#leave_"+id+" .leave-action .btn").prop('disabled', true);   
            $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
            $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
            $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
            $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
        }
    });
    
    $('.leave-box .onoffswitch-checkbox').each(function() {
        var id = $(this).attr('id').split('_')[1];
        if ($(this).prop("checked") == true) {
            $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
            $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
        }
        else {
            $("#leave_"+id+" .leave-action .btn").prop('disabled', true);   
            $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
            $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
            $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
            $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
        }
    });
    
    // Placeholder Hide

    if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
        $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
            $(this).data('placeholder', $(this).attr('placeholder'))
                   .attr('placeholder', '');
        }).blur(function () {
            $(this).attr('placeholder', $(this).data('placeholder'));
        });
    }
    
    // OTP Input
    
    if ($('.otp-input').length > 0) {
        $(".otp-input").keyup(function(e) {
            if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
                $(e.target).next('.otp-input').focus();
            } else if (e.which == 8) {
                $(e.target).prev('.otp-input').focus();
            }
        });
    }
$(document).on('click', '.top-nav-search .responsive-search', function() {
        $('.top-nav-search').toggleClass('active');
    });
    
    $(document).on('click', '#file_sidebar_toggle', function() {
        $('.file-wrap').toggleClass('file-sidebar-toggle');
    });
    
    $(document).on('click', '.file-side-close', function() {
        $('.file-wrap').removeClass('file-sidebar-toggle');
    });
    
    if($('.kanban-wrap').length > 0) {
        $(".kanban-wrap").sortable({
            connectWith: ".kanban-wrap",
            handle: ".kanban-box",
            placeholder: "drag-placeholder"
        });
    }
    },
    name: 'approvalsetting'
  }
</Script>