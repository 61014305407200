<template>
  <div class="clientprofile">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
      <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">Profile</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Profile</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <div class="card mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="profile-view">
                    <div class="profile-img-wrap">
                      <div class="profile-img">
                        <a href="">
                          <img src="../../assets/img/profiles/avatar-19.jpg" alt="">
                        </a>
                      </div>
                    </div>
                    <div class="profile-basic">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="profile-info-left">
                            <h3 class="user-name m-t-0">Global Technologies</h3>
                            <h5 class="company-role m-t-0 mb-0">Barry Cuda</h5>
                            <small class="text-muted">CEO</small>
                            <div class="staff-id">Employee ID : CLT-0001</div>
                            <div class="staff-msg"><router-link to="/chat" class="btn btn-custom">Send Message</router-link></div>
                          </div>
                        </div>
                        <div class="col-md-7">
                          <ul class="personal-info">
                            <li>
                              <span class="title">Phone:</span>
                              <span class="text"><a href="">9876543210</a></span>
                            </li>
                            <li>
                              <span class="title">Email:</span>
                              <span class="text"><a href="">barrycuda@example.com</a></span>
                            </li>
                            <li>
                              <span class="title">Birthday:</span>
                              <span class="text">2nd August</span>
                            </li>
                            <li>
                              <span class="title">Address:</span>
                              <span class="text">5754 Airport Rd, Coosada, AL, 36020</span>
                            </li>
                            <li>
                              <span class="title">Gender:</span>
                              <span class="text">Male</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card tab-box">
            <div class="row user-tabs">
              <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul class="nav nav-tabs nav-tabs-bottom">
                  <li class="nav-item col-sm-3"><a class="nav-link active" data-bs-toggle="tab" href="#myprojects">Projects</a></li>
                  <li class="nav-item col-sm-3"><a class="nav-link" data-bs-toggle="tab" href="#tasks">Tasks</a></li>
                </ul>
              </div>
            </div>
          </div>

                    <div class="row">
                        <div class="col-lg-12"> 
              <div class="tab-content profile-tab-content">
                
                <!-- Projects Tab -->
                <div id="myprojects" class="tab-pane fade show active">
                  <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div class="card">
                        <div class="card-body">
                          <div class="dropdown profile-action">
                            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                            </div>
                          </div>
                          <h4 class="project-title"><router-link to="/project-view">Office Management</router-link></h4>
                          <small class="block text-ellipsis m-b-15">
                            <span class="text-xs">1</span> <span class="text-muted">open tasks, </span>
                            <span class="text-xs">9</span> <span class="text-muted">tasks completed</span>
                          </small>
                          <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div class="pro-deadline m-b-15">
                            <div class="sub-title">
                              Deadline:
                            </div>
                            <div class="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Project Leader :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                              </li>
                            </ul>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Team :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                              </li>
                              <li class="dropdown avatar-dropdown">
                                <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <div class="avatar-group">
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                                    </a>
                                  </div>
                                  <div class="avatar-pagination">
                                    <ul class="pagination">
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                          <span aria-hidden="true">«</span>
                                          <span class="visually-hidden">Previous</span>
                                        </a>
                                      </li>
                                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                          <span aria-hidden="true">»</span>
                                        <span class="visually-hidden">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li> 
                            </ul>
                          </div>
                          <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                          <div class="progress progress-xs mb-0">
                            <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div class="card">
                        <div class="card-body">
                          <div class="dropdown profile-action">
                            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                            </div>
                          </div>
                          <h4 class="project-title"><router-link to="/project-view">Project Management</router-link></h4>
                          <small class="block text-ellipsis m-b-15">
                            <span class="text-xs">2</span> <span class="text-muted">open tasks, </span>
                            <span class="text-xs">5</span> <span class="text-muted">tasks completed</span>
                          </small>
                          <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div class="pro-deadline m-b-15">
                            <div class="sub-title">
                              Deadline:
                            </div>
                            <div class="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Project Leader :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                              </li>
                            </ul>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Team :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                              </li>
                              <li class="dropdown avatar-dropdown">
                                <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <div class="avatar-group">
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                                    </a>
                                  </div>
                                  <div class="avatar-pagination">
                                    <ul class="pagination">
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                          <span aria-hidden="true">«</span>
                                          <span class="visually-hidden">Previous</span>
                                        </a>
                                      </li>
                                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                          <span aria-hidden="true">»</span>
                                        <span class="visually-hidden">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                          <div class="progress progress-xs mb-0">
                            <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div class="card">
                        <div class="card-body">
                          <div class="dropdown profile-action">
                            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                            </div>
                          </div>
                          <h4 class="project-title"><router-link to="/project-view">Video Calling App</router-link></h4>
                          <small class="block text-ellipsis m-b-15">
                            <span class="text-xs">3</span> <span class="text-muted">open tasks, </span>
                            <span class="text-xs">3</span> <span class="text-muted">tasks completed</span>
                          </small>
                          <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div class="pro-deadline m-b-15">
                            <div class="sub-title">
                              Deadline:
                            </div>
                            <div class="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Project Leader :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                              </li>
                            </ul>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Team :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                              </li>
                              <li class="dropdown avatar-dropdown">
                                <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <div class="avatar-group">
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                                    </a>
                                  </div>
                                  <div class="avatar-pagination">
                                    <ul class="pagination">
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                          <span aria-hidden="true">«</span>
                                          <span class="visually-hidden">Previous</span>
                                        </a>
                                      </li>
                                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                          <span aria-hidden="true">»</span>
                                        <span class="visually-hidden">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                          <div class="progress progress-xs mb-0">
                            <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div class="card">
                        <div class="card-body">
                          <div class="dropdown profile-action">
                            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_project"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                            </div>
                          </div>
                          <h4 class="project-title"><router-link to="/project-view">Hospital Administration</router-link></h4>
                          <small class="block text-ellipsis m-b-15">
                            <span class="text-xs">12</span> <span class="text-muted">open tasks, </span>
                            <span class="text-xs">4</span> <span class="text-muted">tasks completed</span>
                          </small>
                          <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div class="pro-deadline m-b-15">
                            <div class="sub-title">
                              Deadline:
                            </div>
                            <div class="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Project Leader :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src="../../assets/img/profiles/avatar-16.jpg"></a>
                              </li>
                            </ul>
                          </div>
                          <div class="project-members m-b-15">
                            <div>Team :</div>
                            <ul class="team-members">
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></a>
                              </li>
                              <li>
                                <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src="../../assets/img/profiles/avatar-05.jpg"></a>
                              </li>
                              <li class="dropdown avatar-dropdown">
                                <a href="#" class="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <div class="avatar-group">
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-11.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-12.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-13.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-01.jpg">
                                    </a>
                                    <a class="avatar avatar-xs" href="#">
                                      <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                                    </a>
                                  </div>
                                  <div class="avatar-pagination">
                                    <ul class="pagination">
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                          <span aria-hidden="true">«</span>
                                          <span class="visually-hidden">Previous</span>
                                        </a>
                                      </li>
                                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                                      <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                          <span aria-hidden="true">»</span>
                                        <span class="visually-hidden">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                          <div class="progress progress-xs mb-0">
                            <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Projects Tab -->
                
                <!-- Task Tab -->
                <div id="tasks" class="tab-pane fade">
                  <div class="project-task">
                    <ul class="nav nav-tabs nav-tabs-top nav-justified mb-0">
                      <li class="nav-item"><a class="nav-link active" href="#all_tasks" data-bs-toggle="tab" aria-expanded="true">All Tasks</a></li>
                      <li class="nav-item"><a class="nav-link" href="#pending_tasks" data-bs-toggle="tab" aria-expanded="false">Pending Tasks</a></li>
                      <li class="nav-item"><a class="nav-link" href="#completed_tasks" data-bs-toggle="tab" aria-expanded="false">Completed Tasks</a></li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane show active" id="all_tasks">
                        <div class="task-wrapper">
                          <div class="task-list-container">
                            <div class="task-list-body">
                              <ul id="task-list">
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Patient appointment booking</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Appointment booking with payment gateway</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="completed task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label">Doctor available module</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Patient and Doctor video conferencing</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Private chat module</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                                <li class="task">
                                  <div class="task-container">
                                    <span class="task-action-btn task-check">
                                      <span class="action-circle large complete-btn" title="Mark Complete">
                                        <i class="material-icons">check</i>
                                      </span>
                                    </span>
                                    <span class="task-label" contenteditable="true">Patient Profile add</span>
                                    <span class="task-action-btn task-btn-right">
                                      <span class="action-circle large" title="Assign">
                                        <i class="material-icons">person_add</i>
                                      </span>
                                      <span class="action-circle large delete-btn" title="Delete Task">
                                        <i class="material-icons">delete</i>
                                      </span>
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="task-list-footer">
                              <div class="new-task-wrapper">
                                <textarea  id="new-task" placeholder="Enter new task here. . ."></textarea>
                                <span class="error-message hidden">You need to enter a task first</span>
                                <span class="add-new-task-btn btn" id="add-task">Add Task</span>
                                <span class="btn" id="close-task-panel">Close</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="pending_tasks"></div>
                      <div class="tab-pane" id="completed_tasks"></div>
                    </div>
                  </div>
                </div>
                <!-- /Task Tab -->
                
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->
        
            </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  var notificationTimeout;

    //Shows updated notification popup 
    var updateNotification = function(task, notificationText, newClass){
      var notificationPopup = $('.notification-popup ');
      notificationPopup.find('.task').text(task);
      notificationPopup.find('.notification-text').text(notificationText);
      notificationPopup.removeClass('hide success');
      // If a custom class is provided for the popup, add It
      if(newClass)
        notificationPopup.addClass(newClass);
      // If there is already a timeout running for hiding current popup, clear it.
      if(notificationTimeout)
        clearTimeout(notificationTimeout);
      // Init timeout for hiding popup after 3 seconds
      notificationTimeout = setTimeout(function(){
        notificationPopup.addClass('hide');
      }, 3000);
    };

    // Adds a new Task to the todo list 
    var addTask = function(){
      // Get the new task entered by user
      var newTask = $('#new-task').val();
      // If new task is blank show error message
      if(newTask === ''){
        $('#new-task').addClass('error');
        $('.new-task-wrapper .error-message').removeClass('hidden');
      }
      else{
        var todoListScrollHeight = $('.task-list-body').prop('scrollHeight');
        // Make a new task template
        var newTemplate = $(taskTemplate).clone();
        // update the task label in the new template
        newTemplate.find('.task-label').text(newTask);
        // Add new class to the template
        newTemplate.addClass('new');
        // Remove complete class in the new Template in case it is present
        newTemplate.removeClass('completed');
        //Append the new template to todo list
        $('#task-list').append(newTemplate);
        // Clear the text in textarea
        $('#new-task').val('');
        // As a new task is added, hide the mark all tasks as incomplete button & show the mark all finished button
        $('#mark-all-finished').removeClass('move-up');
        $('#mark-all-incomplete').addClass('move-down');
        // Show notification
        updateNotification(newTask, 'added to list');
        // Smoothly scroll the todo list to the end
        $('.task-list-body').animate({ scrollTop: todoListScrollHeight}, 1000);
      }
    };

    // Closes the panel for entering new tasks & shows the button for opening the panel
    var closeNewTaskPanel = function(){
      $('.add-task-btn').toggleClass('visible');
      $('.new-task-wrapper').toggleClass('visible');
      if($('#new-task').hasClass('error')){
        $('#new-task').removeClass('error');
        $('.new-task-wrapper .error-message').addClass('hidden');
      }
    };

    // Initalizes HTML template for a given task 
    //var taskTemplate = $($('#task-template').html());
    var taskTemplate = '<li class="task"><div class="task-container"><span class="task-action-btn task-check"><span class="action-circle large complete-btn" title="Mark Complete"><i class="material-icons">check</i></span></span><span class="task-label" contenteditable="true"></span><span class="task-action-btn task-btn-right"><span class="action-circle large" title="Assign"><i class="material-icons">person_add</i></span> <span class="action-circle large delete-btn" title="Delete Task"><i class="material-icons">delete</i></span></span></div></li>';
    // Shows panel for entering new tasks
    $('.add-task-btn').click(function() {
        var newTaskWrapperOffset = $('.new-task-wrapper').offset().top;
        $(this).toggleClass('visible');
        $('.new-task-wrapper').toggleClass('visible');
        // Focus on the text area for typing in new task
        $('#new-task').focus();
        // Smoothly scroll to the text area to bring the text are in view
        $('body').animate({
            scrollTop: newTaskWrapperOffset
        }, 1000);
    });

    // Deletes task on click of delete button
    $('#task-list').on('click', '.task-action-btn .delete-btn', function(){
      var task = $(this).closest('.task');
      var taskText = task.find('.task-label').text();
      task.remove();
      updateNotification(taskText, ' has been deleted.');
    });

    // Marks a task as complete
    $('#task-list').on('click', '.task-action-btn .complete-btn', function(){
      var task = $(this).closest('.task');
      var taskText = task.find('.task-label').text();
      var newTitle = task.hasClass('completed') ? 'Mark Complete' : 'Mark Incomplete';
      $(this).attr('title', newTitle);
      task.hasClass('completed') ? updateNotification(taskText, 'marked as Incomplete.') : updateNotification(taskText, ' marked as complete.', 'success');
      task.toggleClass('completed');
    });

    // Adds a task on hitting Enter key, hides the panel for entering new task on hitting Esc. key
    $('#new-task').keydown(function(event){
      // Get the code of the key that is pressed
      var keyCode = event.keyCode;
      var enterKeyCode = 13;
      var escapeKeyCode = 27;
      // If error message is already displayed, hide it.
      if($('#new-task').hasClass('error')){
        $('#new-task').removeClass('error');
        $('.new-task-wrapper .error-message').addClass('hidden');
      }
      // If key code is that of Enter Key then call addTask Function
      if(keyCode == enterKeyCode){
        event.preventDefault();
        addTask();
      }
      // If key code is that of Esc Key then call closeNewTaskPanel Function
      else if(keyCode == escapeKeyCode)
        closeNewTaskPanel();

    });

    // Add new task on click of add task button
    $('#add-task').click(addTask);

    // Close new task panel on click of close panel button
    $('#close-task-panel').click(closeNewTaskPanel);

    // Mark all tasks as complete on click of mark all finished button
    $('#mark-all-finished').click(function(){
      $('#task-list .task').addClass('completed');
      $('#mark-all-incomplete').removeClass('move-down');
      $(this).addClass('move-up');
      updateNotification('All tasks', 'marked as complete.', 'success');
    });

    // Mark all tasks as incomplete on click of mark all incomplete button
    $('#mark-all-incomplete').click(function(){
      $('#task-list .task').removeClass('completed');
      $(this).addClass('move-down');
      $('#mark-all-finished').removeClass('move-up');
      updateNotification('All tasks', 'marked as Incomplete.');
    });
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'clientprofile'
  }
</Script>