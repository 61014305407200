<template>
  <div class="shiftlist">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Shift List</h3>
                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                                    <li class="breadcrumb-item"><a href="#">Employees</a></li>
                  <li class="breadcrumb-item active">Shift List</li>
                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_shift">Add Shifts</a>
                <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_schedule"> Assign Shifts</a>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Content Starts -->
                    <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped custom-table datatable">
                  <thead>
                    <tr>
                                            <th>#</th>
                      <th>Shift Name</th>
                      <th>Min Start Time</th>
                      <th>Start Time</th>
                                            <th>Max Start Time</th>
                                            <th>Min End Time</th>
                                            <th>End Time</th>
                                            <th>Max End Time</th>
                                            <th>Break Time</th>
                      <th class="text-center">Status</th>
                      <th class="text-end no-sort">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                                            <td>1</td>
                                            <td>10'o clock Shift</td>
                                            <td>09:00:00 am</td>
                                            <td>10:00:00 am</td>
                                            <td>10:30:00 am</td>
                                            <td>06:00:00 pm</td>
                                            <td>06:30:00 pm</td>
                                            <td>07:00:00 pm</td>
                                            <td>30 mins</td>
                                            <td class="text-center">
                        <div class="action-label">
                          <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_shift"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>10:30 shift</td>
                                            <td>10:00:00 am</td>
                                            <td>10:30:00 am </td>
                                            <td>11:00:00 am</td>
                                            <td>06:30:00 pm</td>
                                            <td>07:00:00 pm</td>
                                            <td>07:30:00 pm </td>
                                            <td>45 mins</td>
                                            <td class="text-center">
                        <div class="action-label">
                          <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_shift"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Daily Rout</td>
                                            <td>06:00:00 am</td>
                                            <td>06:30:00 am</td>
                                            <td>07:00:00 am</td>
                                            <td>03:00:00 pm</td>
                                            <td>03:30:00 pm</td>
                                            <td>04:00:00 pm</td>
                                            <td>60 mins</td>
                                            <td class="text-center">
                        <div class="action-label">
                          <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_shift"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>New Shift</td>
                                            <td>06:11:00 am</td>
                                            <td>06:30:00 am</td>
                                            <td>08:12:00 am</td>
                                            <td>09:12:00 pm </td>
                                            <td>09:30:00 pm</td>
                                            <td>09:45:00 pm</td>
                                            <td>45 mins</td>
                                            <td class="text-center">
                        <div class="action-label">
                          <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_shift"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Recurring Shift</td>
                                            <td>08:30:00 am</td>
                                            <td>09:00:00 am</td>
                                            <td>09:30:00 am</td>
                                            <td>05:30:00 pm</td>
                                            <td>06:00:00 pm</td>
                                            <td>06:30:00 pm</td>
                                            <td>60 mins</td>
                                            <td class="text-center">
                        <div class="action-label">
                          <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                            <i class="fa fa-dot-circle-o text-success"></i> Active
                          </a>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_shift"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
          <!-- /Content End -->
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
        
      <!-- Add Shift Modal -->
      <div id="add_shift" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add Shift</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="col-form-label">Shift Name <span class="text-danger">*</span></label>
                      <div class="input-group time timepicker">
                        <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group" >
                      <label>Min Start Time <span class="text-danger">*</span></label>
                      <div class="input-group time timepicker">
                        <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Start Time <span class="text-danger">*</span></label>
                      <div class="input-group time timepicker">
                        <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>                  
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Max Start Time <span class="text-danger">*</span></label>
                      <div class="input-group time timepicker">
                        <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>                      
                    </div>
                  </div>    
                  <div class="col-md-4">
                    <div class="form-group" >
                      <label>Min End Time <span class="text-danger">*</span></label>
                      <div class="input-group time timepicker">
                        <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>End Time <span class="text-danger">*</span></label>
                      <div class="input-group time timepicker">
                        <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>                  
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Max End Time <span class="text-danger">*</span></label>
                      <div class="input-group time timepicker">
                        <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                      </div>                      
                    </div>
                  </div>  
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Break Time (In Minutes) </label>
                      <input type="text" class="form-control">                      
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="custom-control form-check">
                      <input type="checkbox" class="form-check-input" id="customCheck1">
                      <label class="form-check-label" for="customCheck1">Recurring Shift</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="col-form-label">Repeat Every</label>
                      <select class="select">
                        <option value="">1 </option>
                        <option value="1">2</option>
                        <option value="2">3</option>
                        <option value="3">4</option>
                        <option  selected value="4">5</option>
                        <option value="3">6</option>
                      </select>
                      <label class="col-form-label">Week(s)</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group wday-box">
                      <label class="checkbox-inline"><input type="checkbox" value="monday" class="days recurring" checked=""><span class="checkmark">M</span></label>
    
                      <label class="checkbox-inline ms-1"><input type="checkbox" value="tuesday" class="days recurring" checked=""><span class="checkmark">T</span></label>
                    
                      <label class="checkbox-inline ms-1"><input type="checkbox" value="wednesday" class="days recurring" checked=""><span class="checkmark">W</span></label>
                    
                      <label class="checkbox-inline ms-1"><input type="checkbox" value="thursday" class="days recurring" checked=""><span class="checkmark">T</span></label>
                    
                      <label class="checkbox-inline ms-1"><input type="checkbox" value="friday" class="days recurring" checked=""><span class="checkmark">F</span></label>
                    
                      <label class="checkbox-inline ms-1"><input type="checkbox" value="saturday" class="days recurring"><span class="checkmark">S</span></label>
                    
                      <label class="checkbox-inline ms-1"><input type="checkbox" value="sunday" class="days recurring"><span class="checkmark">S</span></label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="col-form-label">End On <span class="text-danger">*</span></label>
                      <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="custom-control form-check">
                      <input type="checkbox" class="form-check-input" id="customCheck2">
                      <label class="form-check-label" for="customCheck2">Indefinite</label>
                    </div>
                  </div>                
              
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Add Tag </label>
                      <input type="text" class="form-control">                      
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Add Note </label>
                      <textarea class="form-control"></textarea>                      
                    </div>
                  </div>
                </div>
                <div class="submit-section">
                  <button class="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Add Shift Modal -->

        <!-- Edit Shift Modal -->
        <div id="edit_shift" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Shift</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Shift Name <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group" >
                        <label>Min Start Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Start Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>                  
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Max Start Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>                      
                      </div>
                    </div>    
                    <div class="col-md-4">
                      <div class="form-group" >
                        <label>Min End Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>End Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>                  
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Max End Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>                      
                      </div>
                    </div>  
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Break Time (In Minutes) </label>
                        <input type="text" class="form-control">                      
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="custom-control form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck3">
                        <label class="form-check-label" for="customCheck3">Recurring Shift</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Repeat Every</label>
                        <select class="select">
                          <option value="">1 </option>
                          <option value="1">2</option>
                          <option value="2">3</option>
                          <option value="3">4</option>
                          <option  selected value="4">5</option>
                          <option value="3">6</option>
                        </select>
                        <label class="col-form-label">Week(s)</label>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group wday-box">
                        <label class="checkbox-inline"><input type="checkbox" value="monday" class="days recurring" checked=""><span class="checkmark">M</span></label>
      
                        <label class="checkbox-inline"><input type="checkbox" value="tuesday" class="days recurring" checked=""><span class="checkmark">T</span></label>
                      
                        <label class="checkbox-inline"><input type="checkbox" value="wednesday" class="days recurring" checked=""><span class="checkmark">W</span></label>
                      
                        <label class="checkbox-inline"><input type="checkbox" value="thursday" class="days recurring" checked=""><span class="checkmark">T</span></label>
                      
                        <label class="checkbox-inline"><input type="checkbox" value="friday" class="days recurring" checked=""><span class="checkmark">F</span></label>
                      
                        <label class="checkbox-inline"><input type="checkbox" value="saturday" class="days recurring"><span class="checkmark">S</span></label>
                      
                        <label class="checkbox-inline"><input type="checkbox" value="sunday" class="days recurring"><span class="checkmark">S</span></label>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">End On <span class="text-danger">*</span></label>
                        <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="custom-control form-check">
                        <input type="checkbox" class="form-check-input" id="customCheck4">
                        <label class="form-check-label" for="customCheck4">Indefinite</label>
                        </div>
                    </div>                
                
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Add Tag </label>
                        <input type="text" class="form-control">                      
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Add Note </label>
                        <textarea class="form-control"></textarea>                      
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Shift Modal -->

        <!-- Add Schedule Modal -->
        <div id="add_schedule" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Schedule</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Department <span class="text-danger">*</span></label>
                        <select class="select">
                                                    <option value="">Select</option>
                          <option value="">Development</option>
                                                    <option value="1">Finance</option>
                                                    <option value="2">Finance and Management</option>
                                                    <option value="3">Hr & Finance</option>
                                                    <option value="4">ITech</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                                            <div class="form-group">
                        <label class="col-form-label">Employee Name <span class="text-danger">*</span></label>
                        <select class="select">
                          <option value="">Select </option>
                                                    <option value="1">Richard Miles </option>
                                                    <option value="2">John Smith</option>
                                                    <option value="3">Mike Litorus </option>
                                                    <option value="4">Wilmer Deluna</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="col-form-label">Date</label>
                                                <div class="cal-icon"><input class="form-control datetimepicker" type="text"></div>
                                            </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="col-form-label">Shifts <span class="text-danger">*</span></label>
                        <select class="select">
                          <option value="">Select </option>
                                                    <option value="1">10'o clock Shift</option>
                                                    <option value="2">10:30 shift</option>
                                                    <option value="3">Daily Shift </option>
                                                    <option value="4">New Shift</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Min Start Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Start Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Max Start Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Min End Time  <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">End Time   <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Max End Time <span class="text-danger">*</span></label>
                        <div class="input-group time timepicker">
                          <input class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="col-form-label">Break Time  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Accept Extra Hours </label>
                        <div class="custom-control form-switch">
                          <input type="checkbox" class="form-check-input" id="customSwitch1" checked="">
                          <label class="form-check-label" for="customSwitch1"></label>
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label">Publish </label>
                        <div class="custom-control form-switch">
                          <input type="checkbox" class="form-check-input" id="customSwitch2" checked="">
                          <label class="form-check-label" for="customSwitch2"></label>
                          </div>
                      </div>
                    </div>
                  </div>
                
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
                <!-- /Add Schedule Modal -->
                  
        <!-- Delete Shift Modal -->
        <div class="modal custom-modal fade" id="delete_employee" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Delete Shift</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Delete Employee Modal -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  
  // Datatable

  if($('.datatable').length > 0) {
    $('.datatable').DataTable({
      "bFilter": false,
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'shiftlist'
  }
</Script>