<template>
  <div class="subscribedcompanies">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
      
        <!-- Page Content -->
                <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header">
            
            <div class="row">
              <div class="col">
                <h3 class="page-title">Subscribed Companies</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Subscriptions</li>
                </ul>
              </div>
            </div>
      
        
          </div>
          <!-- /Page Header -->
          
          <div class="row">
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Joining</h6>
                <h4>12 <span>This Month</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Renewal</h6>
                <h4>3 <span>This Month</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Renewal</h6>
                <h4>0 <span>Next Month</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Total Companies</h6>
                <h4>312</h4>
              </div>
            </div>
          </div>

          <!-- Company List -->
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">  
                <table class="table table-hover custom-table datatable mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Client</th>
                      <th>Plan</th>
                      <th>Users</th>
                      <th>Plan Duration</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Amount</th>
                      <th>Plan Status</th>
                      <th>Update Plan</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"><img src="../../assets/img/profiles/avatar-19.jpg" alt=""></router-link>
                          <router-link to="/client-profile">Global Technologies</router-link>
                        </h2>
                      </td>
                      <td>Free Trial</td>
                      <td>30</td>
                      <td>6 Months</td>
                      <td>15 Feb 2019</td>
                      <td>14 Jul 2019</td>
                      <td>$200</td>
                      <td><span class="badge bg-inverse-success">Active</span></td>
                      <td><a class="btn btn-primary btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#upgrade_plan">Change Plan</a></td>
                      <td>
                        <div class="status-toggle">
                          <input type="checkbox" id="company_status_1" class="check">
                          <label for="company_status_1" class="checktoggle">checkbox</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"><img src="../../assets/img/profiles/avatar-29.jpg" alt=""></router-link>
                          <router-link to="/client-profile">Delta Infotech</router-link>
                        </h2>
                      </td>
                      <td>Premium</td>
                      <td>50</td>
                      <td>1 Year</td>
                      <td>15 Feb 2019</td>
                      <td>14 Feb 2020</td>
                      <td>$200</td>
                      <td><span class="badge bg-inverse-danger">Expired</span></td>
                      <td><a class="btn btn-primary btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#upgrade_plan">Change Plan</a></td>
                      <td>
                        <div class="status-toggle">
                          <input type="checkbox" id="company_status_2" class="check" checked>
                          <label for="company_status_2" class="checktoggle">checkbox</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-07.jpg"></router-link>
                          <router-link to="/client-profile">Cream Inc</router-link>
                        </h2>
                      </td>
                      <td>Professional</td>
                      <td>10</td>
                      <td>1 Month</td>
                      <td>15 Feb 2019</td>
                      <td>14 Feb 2020</td>
                      <td>Free</td>
                      <td><span class="badge bg-inverse-success">Active</span></td>
                      <td><a class="btn btn-primary btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#upgrade_plan">Change Plan</a></td>
                      <td>
                        <div class="status-toggle">
                          <input type="checkbox" id="company_status_3" class="check" checked>
                          <label for="company_status_3" class="checktoggle">checkbox</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"><img alt="" src="../../assets/img/profiles/avatar-06.jpg"></router-link>
                          <router-link to="/client-profile">Wellware Company</router-link>
                        </h2>
                      </td>
                      <td>Enterprise</td>
                      <td>10</td>
                      <td>1 Month</td>
                      <td>15 Feb 2019</td>
                      <td>14 Feb 2020</td>
                      <td>Free</td>
                      <td><span class="badge bg-inverse-success">Active</span></td>
                      <td><a class="btn btn-primary btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#upgrade_plan">Change Plan</a></td>
                      <td>
                        <div class="status-toggle">
                          <input type="checkbox" id="company_status_4" class="check" checked>
                          <label for="company_status_4" class="checktoggle">checkbox</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"><img src="../../assets/img/profiles/avatar-14.jpg" alt=""></router-link>
                          <router-link to="/client-profile">Mustang Technologies</router-link>
                        </h2>
                      </td>
                      <td>Free Trial</td>
                      <td>10</td>
                      <td>1 Year</td>
                      <td>15 Feb 2019</td>
                      <td>14 Feb 2020</td>
                      <td>Free</td>
                      <td><span class="badge bg-inverse-success">Active</span></td>
                      <td><a class="btn btn-primary btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#upgrade_plan">Change Plan</a></td>
                      <td>
                        <div class="status-toggle">
                          <input type="checkbox" id="company_status_5" class="check" checked>
                          <label for="company_status_5" class="checktoggle">checkbox</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"><img src="../../assets/img/profiles/avatar-18.jpg" alt=""></router-link>
                          <router-link to="/client-profile">International Software Inc</router-link>
                        </h2>
                      </td>
                      <td>Enterprise</td>
                      <td>10</td>
                      <td>1 Month</td>
                      <td>15 Feb 2019</td>
                      <td>14 Feb 2020</td>
                      <td>Free</td>
                      <td><span class="badge bg-inverse-success">Active</span></td>
                      <td><a class="btn btn-primary btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#upgrade_plan">Change Plan</a></td>
                      <td>
                        <div class="status-toggle">
                          <input type="checkbox" id="company_status_6" class="check" checked>
                          <label for="company_status_6" class="checktoggle">checkbox</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"><img src="../../assets/img/profiles/avatar-28.jpg" alt=""></router-link>
                          <router-link to="/client-profile">Mercury Software Inc</router-link>
                        </h2>
                      </td>
                      <td>Professional</td>
                      <td>10</td>
                      <td>1 Year</td>
                      <td>15 Feb 2019</td>
                      <td>14 Feb 2020</td>
                      <td>Free</td>
                      <td><span class="badge bg-inverse-success">Active</span></td>
                      <td><a class="btn btn-primary btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#upgrade_plan">Change Plan</a></td>
                      <td>
                        <div class="status-toggle">
                          <input type="checkbox" id="company_status_7" class="check" checked>
                          <label for="company_status_7" class="checktoggle">checkbox</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- /Company List -->
          
          <!-- Upgrade Plan Modal -->
          <div class="modal custom-modal fade" id="upgrade_plan" role="dialog">
            <div class="modal-dialog modal-md modal-dialog-centered">
              <div class="modal-content">
                <button type="button" class="close" data-bs-dismiss="modal"><i class="fa fa-close"></i></button>
                <div class="modal-body">
                  <h5 class="modal-title text-center mb-3">Upgrade Plan</h5>
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Plan Name</label>
                          <input type="text" placeholder="Free Trial" class="form-control" value="Free Trial">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Amount</label>
                          <input type="text" class="form-control" value="$500">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Plan Type</label>
                          <select class="select"> 
                            <option> Monthly </option>
                            <option> Yearly </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>No of Users</label>
                          <select class="select"> 
                            <option> 5 Users </option>
                            <option> 50 Users </option>
                            <option> Unlimited </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>No of Projects</label>
                          <select class="select"> 
                            <option> 5 Projects </option>
                            <option> 50 Projects </option>
                            <option> Unlimited </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>No of Storage Space</label>
                          <select class="select"> 
                            <option> 5 GB </option>
                            <option> 100 GB </option>
                            <option> 500 GB </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Plan Description</label>
                      <textarea class="form-control" rows="4" cols="30"></textarea>
                    </div>
                    <div class="form-group">
                      <label class="d-block">Status</label>
                      <div class="status-toggle">
                        <input type="checkbox" id="upgrade_plan_status" class="check">
                        <label for="upgrade_plan_status" class="checktoggle">checkbox</label>
                      </div>
                    </div>
                    <div class="m-t-20 text-center">
                      <button class="btn btn-primary submit-btn">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- /Upgrade Plan Modal -->
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }

    },
    name: 'subscribedcompanies'
  }
</Script>