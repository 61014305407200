<template>
  <div class="projectview">
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
        
        <!-- Page Content -->
                <div class="content container-fluid">
        
          <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Hospital Admin</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Project</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a href="#" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#edit_project"><i class="fa fa-plus"></i> Edit Project</a>
                <router-link to="/task-board" class="btn btn-white float-end m-r-10" data-bs-toggle="tooltip" title="Task Board"><i class="fa fa-bars"></i></router-link>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <div class="row">
            <div class="col-lg-8 col-xl-9">
              <div class="card">
                <div class="card-body">
                  <div class="project-title">
                    <h5 class="card-title">Hospital Administration</h5>
                    <small class="block text-ellipsis m-b-15"><span class="text-xs">2</span> <span class="text-muted">open tasks, </span><span class="text-xs">5</span> <span class="text-muted">tasks completed</span></small>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sollicitudin libero vitae est consectetur, a molestie tortor consectetur. Aenean tincidunt interdum ipsum, id pellentesque diam suscipit ut. Vivamus massa mi, fermentum eget neque eget, imperdiet tristique lectus. Proin at purus ut sem pellentesque tempor sit amet ut lectus. Sed orci augue, placerat et pretium ac, hendrerit in felis. Integer scelerisque libero non metus commodo, et hendrerit diam aliquet. Proin tincidunt porttitor ligula, a tincidunt orci pellentesque nec. Ut ultricies maximus nulla id consequat. Fusce eu consequat mi, eu euismod ligula. Aliquam porttitor neque id massa porttitor, a pretium velit vehicula. Morbi volutpat tincidunt urna, vel ullamcorper ligula fermentum at. </p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sollicitudin libero vitae est consectetur, a molestie tortor consectetur. Aenean tincidunt interdum ipsum, id pellentesque diam suscipit ut. Vivamus massa mi, fermentum eget neque eget, imperdiet tristique lectus. Proin at purus ut sem pellentesque tempor sit amet ut lectus. Sed orci augue, placerat et pretium ac, hendrerit in felis. Integer scelerisque libero non metus commodo, et hendrerit diam aliquet. Proin tincidunt porttitor ligula, a tincidunt orci pellentesque nec. Ut ultricies maximus nulla id consequat. Fusce eu consequat mi, eu euismod ligula. Aliquam porttitor neque id massa porttitor, a pretium velit vehicula. Morbi volutpat tincidunt urna, vel ullamcorper ligula fermentum at. </p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                            <h5 class="card-title m-b-20">Uploaded image files</h5>
                  <div class="row">
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title m-b-20">Uploaded files</h5>
                  <ul class="files-list">
                    <li>
                      <div class="files-cont">
                        <div class="file-type">
                          <span class="files-icon"><i class="fa fa-file-pdf-o"></i></span>
                        </div>
                        <div class="files-info">
                          <span class="file-name text-ellipsis"><a href="#">AHA Selfcare Mobile Application Test-Cases.xls</a></span>
                          <span class="file-author"><a href="#">John Doe</a></span> <span class="file-date">May 31st at 6:53 PM</span>
                          <div class="file-size">Size: 14.8Mb</div>
                        </div>
                        <ul class="files-action">
                          <li class="dropdown dropdown-action">
                            <a href="" class="dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_horiz</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="javascript:void(0)">Download</a>
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#share_files">Share</a>
                              <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div class="files-cont">
                        <div class="file-type">
                          <span class="files-icon"><i class="fa fa-file-pdf-o"></i></span>
                        </div>
                        <div class="files-info">
                          <span class="file-name text-ellipsis"><a href="#">AHA Selfcare Mobile Application Test-Cases.xls</a></span>
                          <span class="file-author"><a href="#">Richard Miles</a></span> <span class="file-date">May 31st at 6:53 PM</span>
                          <div class="file-size">Size: 14.8Mb</div>
                        </div>
                        <ul class="files-action">
                          <li class="dropdown dropdown-action">
                            <a href="" class="dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_horiz</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="javascript:void(0)">Download</a>
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#share_files">Share</a>
                              <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="project-task">
                <ul class="nav nav-tabs nav-tabs-top nav-justified mb-0">
                  <li class="nav-item"><a class="nav-link active" href="#all_tasks" data-bs-toggle="tab" aria-expanded="true">All Tasks</a></li>
                  <li class="nav-item"><a class="nav-link" href="#pending_tasks" data-bs-toggle="tab" aria-expanded="false">Pending Tasks</a></li>
                  <li class="nav-item"><a class="nav-link" href="#completed_tasks" data-bs-toggle="tab" aria-expanded="false">Completed Tasks</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane show active" id="all_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul id="task-list">
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Patient appointment booking</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Appointment booking with payment gateway</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label">Doctor available module</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Patient and Doctor video conferencing</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Private chat module</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Patient Profile add</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea  id="new-task" placeholder="Enter new task here. . ."></textarea>
                            <span class="error-message hidden">You need to enter a task first</span>
                            <span class="add-new-task-btn btn" id="add-task">Add Task</span>
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="pending_tasks"></div>
                  <div class="tab-pane" id="completed_tasks"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3">
              <div class="card">
                <div class="card-body">
                  <h6 class="card-title m-b-15">Project details</h6>
                  <table class="table table-striped table-border">
                    <tbody>
                      <tr>
                        <td>Cost:</td>
                        <td class="text-end">$1200</td>
                      </tr>
                      <tr>
                        <td>Total Hours:</td>
                        <td class="text-end">100 Hours</td>
                      </tr>
                      <tr>
                        <td>Created:</td>
                        <td class="text-end">25 Feb, 2019</td>
                      </tr>
                      <tr>
                        <td>Deadline:</td>
                        <td class="text-end">12 Jun, 2019</td>
                      </tr>
                      <tr>
                        <td>Priority:</td>
                        <td class="text-end">
                          <div class="btn-group">
                            <a href="#" class="badge badge-danger dropdown-toggle" data-bs-toggle="dropdown">Highest </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> Highest priority</a>
                              <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-info"></i> High priority</a>
                              <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-primary"></i> Normal priority</a>
                              <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> Low priority</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Created by:</td>
                        <td class="text-end"><router-link to="/profile">Barry Cuda</router-link></td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td class="text-end">Working</td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="m-b-5">Progress <span class="text-success float-end">40%</span></p>
                  <div class="progress progress-xs mb-0">
                    <div class="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style="width: 40%"></div>
                  </div>
                </div>
              </div>
              <div class="card project-user">
                <div class="card-body">
                  <h6 class="card-title m-b-20">Assigned Leader <button type="button" class="float-end btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#assign_leader"><i class="fa fa-plus"></i> Add</button></h6>
                  <ul class="list-box">
                    <li>
                      <router-link to="/profile">
                        <div class="list-item">
                          <div class="list-left">
                            <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-11.jpg"></span>
                          </div>
                          <div class="list-body">
                            <span class="message-author">Wilmer Deluna</span>
                            <div class="clearfix"></div>
                            <span class="message-content">Team Leader</span>
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/profile">
                        <div class="list-item">
                          <div class="list-left">
                            <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-01.jpg"></span>
                          </div>
                          <div class="list-body">
                            <span class="message-author">Lesley Grauer</span>
                            <div class="clearfix"></div>
                            <span class="message-content">Team Leader</span>
                          </div>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card project-user">
                <div class="card-body">
                  <h6 class="card-title m-b-20">
                    Assigned users 
                    <button type="button" class="float-end btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#assign_user"><i class="fa fa-plus"></i> Add</button>
                  </h6>
                  <ul class="list-box">
                    <li>
                      <router-link to="/index">
                        <div class="list-item">
                          <div class="list-left">
                            <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-02.jpg"></span>
                          </div>
                          <div class="list-body">
                            <span class="message-author">John Doe</span>
                            <div class="clearfix"></div>
                            <span class="message-content">Web Designer</span>
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/profile">
                        <div class="list-item">
                          <div class="list-left">
                            <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></span>
                          </div>
                          <div class="list-body">
                            <span class="message-author">Richard Miles</span>
                            <div class="clearfix"></div>
                            <span class="message-content">Web Developer</span>
                          </div>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
                </div>
        <!-- /Page Content -->
        
        <!-- Assign Leader Modal -->
        <div id="assign_leader" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Assign Leader to this project</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="input-group m-b-30">
                  <input placeholder="Search to add a leader" class="form-control search-input" type="text">
                  <button class="btn btn-primary">Search</button>
                </div>
                <div>
                  <ul class="chat-user-list">
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">Richard Miles</div>
                            <span class="designation">Web Developer</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">John Smith</div>
                            <span class="designation">Android Developer</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar">
                            <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                          </span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">Jeffery Lalor</div>
                            <span class="designation">Team Leader</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="submit-section">
                  <button class="btn btn-primary submit-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Assign Leader Modal -->
        
        <!-- Assign User Modal -->
        <div id="assign_user" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Assign the user to this project</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="input-group m-b-30">
                  <input placeholder="Search a user to assign" class="form-control search-input" type="text">
                  <button class="btn btn-primary">Search</button>
                </div>
                <div>
                  <ul class="chat-user-list">
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-09.jpg"></span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">Richard Miles</div>
                            <span class="designation">Web Developer</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar"><img alt="" src="../../assets/img/profiles/avatar-10.jpg"></span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">John Smith</div>
                            <span class="designation">Android Developer</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="media d-flex">
                          <span class="avatar">
                            <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                          </span>
                          <div class="media-body align-self-center text-nowrap">
                            <div class="user-name">Jeffery Lalor</div>
                            <span class="designation">Team Leader</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="submit-section">
                  <button class="btn btn-primary submit-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Assign User Modal -->
        
        <!-- Edit Project Modal -->
        <div id="edit_project" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Project</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Project Name</label>
                        <input class="form-control" value="Project Management" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Client</label>
                        <select class="select">
                          <option>Global Technologies</option>
                          <option>Delta Infotech</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Start Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>End Date</label>
                        <div class="cal-icon">
                          <input class="form-control datetimepicker" type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Rate</label>
                        <input placeholder="$50" class="form-control" value="$5000" type="text">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <select class="select">
                          <option>Hourly</option>
                          <option selected>Fixed</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Priority</label>
                        <select class="select">
                          <option selected>High</option>
                          <option>Medium</option>
                          <option>Low</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Project Leader</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Leader</label>
                        <div class="project-members">
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="Jeffery Lalor">
                            <img alt="" src="../../assets/img/profiles/avatar-16.jpg">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Add Team</label>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Team Members</label>
                        <div class="project-members">
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="John Doe">
                            <img alt="" src="../../assets/img/profiles/avatar-02.jpg">
                          </a>
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="Richard Miles">
                            <img alt="" src="../../assets/img/profiles/avatar-09.jpg">
                          </a>
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="John Smith">
                            <img alt="" src="../../assets/img/profiles/avatar-10.jpg">
                          </a>
                          <a class="avatar" href="#" data-bs-toggle="tooltip" title="Mike Litorus">
                            <img alt="" src="../../assets/img/profiles/avatar-05.jpg">
                          </a>
                          <span class="all-team">+2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea rows="4" class="form-control" placeholder="Enter your message here"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Upload Files</label>
                    <input class="form-control" type="file">
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Project Modal -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
          // Select 2
  
  if($('.select').length > 0) {
    $('.select').select2({
      minimumResultsForSearch: -1,
      width: '100%'
    });
  }
  // Floating Label

  if($('.floating').length > 0 ){
    $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  // Date Time Picker
  
  if($('.datetimepicker').length > 0) {
    $('.datetimepicker').datetimepicker({
      format: 'DD/MM/YYYY',
      icons: {
        up: "fa fa-angle-up",
        down: "fa fa-angle-down",
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    });
  }
  
  // Datatable

  if($('.datatable').length > 0) {
    $('.datatable').DataTable({
      "bFilter": false,
    });
  }
  var notificationTimeout;

    //Shows updated notification popup 
    var updateNotification = function(task, notificationText, newClass){
      var notificationPopup = $('.notification-popup ');
      notificationPopup.find('.task').text(task);
      notificationPopup.find('.notification-text').text(notificationText);
      notificationPopup.removeClass('hide success');
      // If a custom class is provided for the popup, add It
      if(newClass)
        notificationPopup.addClass(newClass);
      // If there is already a timeout running for hiding current popup, clear it.
      if(notificationTimeout)
        clearTimeout(notificationTimeout);
      // Init timeout for hiding popup after 3 seconds
      notificationTimeout = setTimeout(function(){
        notificationPopup.addClass('hide');
      }, 3000);
    };

    // Adds a new Task to the todo list 
    var addTask = function(){
      // Get the new task entered by user
      var newTask = $('#new-task').val();
      // If new task is blank show error message
      if(newTask === ''){
        $('#new-task').addClass('error');
        $('.new-task-wrapper .error-message').removeClass('hidden');
      }
      else{
        var todoListScrollHeight = $('.task-list-body').prop('scrollHeight');
        // Make a new task template
        var newTemplate = $(taskTemplate).clone();
        // update the task label in the new template
        newTemplate.find('.task-label').text(newTask);
        // Add new class to the template
        newTemplate.addClass('new');
        // Remove complete class in the new Template in case it is present
        newTemplate.removeClass('completed');
        //Append the new template to todo list
        $('#task-list').append(newTemplate);
        // Clear the text in textarea
        $('#new-task').val('');
        // As a new task is added, hide the mark all tasks as incomplete button & show the mark all finished button
        $('#mark-all-finished').removeClass('move-up');
        $('#mark-all-incomplete').addClass('move-down');
        // Show notification
        updateNotification(newTask, 'added to list');
        // Smoothly scroll the todo list to the end
        $('.task-list-body').animate({ scrollTop: todoListScrollHeight}, 1000);
      }
    };

    // Closes the panel for entering new tasks & shows the button for opening the panel
    var closeNewTaskPanel = function(){
      $('.add-task-btn').toggleClass('visible');
      $('.new-task-wrapper').toggleClass('visible');
      if($('#new-task').hasClass('error')){
        $('#new-task').removeClass('error');
        $('.new-task-wrapper .error-message').addClass('hidden');
      }
    };

    // Initalizes HTML template for a given task 
    //var taskTemplate = $($('#task-template').html());
    var taskTemplate = '<li class="task"><div class="task-container"><span class="task-action-btn task-check"><span class="action-circle large complete-btn" title="Mark Complete"><i class="material-icons">check</i></span></span><span class="task-label" contenteditable="true"></span><span class="task-action-btn task-btn-right"><span class="action-circle large" title="Assign"><i class="material-icons">person_add</i></span> <span class="action-circle large delete-btn" title="Delete Task"><i class="material-icons">delete</i></span></span></div></li>';
    // Shows panel for entering new tasks
    $('.add-task-btn').click(function() {
        var newTaskWrapperOffset = $('.new-task-wrapper').offset().top;
        $(this).toggleClass('visible');
        $('.new-task-wrapper').toggleClass('visible');
        // Focus on the text area for typing in new task
        $('#new-task').focus();
        // Smoothly scroll to the text area to bring the text are in view
        $('body').animate({
            scrollTop: newTaskWrapperOffset
        }, 1000);
    });

    // Deletes task on click of delete button
    $('#task-list').on('click', '.task-action-btn .delete-btn', function(){
      var task = $(this).closest('.task');
      var taskText = task.find('.task-label').text();
      task.remove();
      updateNotification(taskText, ' has been deleted.');
    });

    // Marks a task as complete
    $('#task-list').on('click', '.task-action-btn .complete-btn', function(){
      var task = $(this).closest('.task');
      var taskText = task.find('.task-label').text();
      var newTitle = task.hasClass('completed') ? 'Mark Complete' : 'Mark Incomplete';
      $(this).attr('title', newTitle);
      task.hasClass('completed') ? updateNotification(taskText, 'marked as Incomplete.') : updateNotification(taskText, ' marked as complete.', 'success');
      task.toggleClass('completed');
    });

    // Adds a task on hitting Enter key, hides the panel for entering new task on hitting Esc. key
    $('#new-task').keydown(function(event){
      // Get the code of the key that is pressed
      var keyCode = event.keyCode;
      var enterKeyCode = 13;
      var escapeKeyCode = 27;
      // If error message is already displayed, hide it.
      if($('#new-task').hasClass('error')){
        $('#new-task').removeClass('error');
        $('.new-task-wrapper .error-message').addClass('hidden');
      }
      // If key code is that of Enter Key then call addTask Function
      if(keyCode == enterKeyCode){
        event.preventDefault();
        addTask();
      }
      // If key code is that of Esc Key then call closeNewTaskPanel Function
      else if(keyCode == escapeKeyCode)
        closeNewTaskPanel();

    });

    // Add new task on click of add task button
    $('#add-task').click(addTask);

    // Close new task panel on click of close panel button
    $('#close-task-panel').click(closeNewTaskPanel);

    // Mark all tasks as complete on click of mark all finished button
    $('#mark-all-finished').click(function(){
      $('#task-list .task').addClass('completed');
      $('#mark-all-incomplete').removeClass('move-down');
      $(this).addClass('move-up');
      updateNotification('All tasks', 'marked as complete.', 'success');
    });

    // Mark all tasks as incomplete on click of mark all incomplete button
    $('#mark-all-incomplete').click(function(){
      $('#task-list .task').removeClass('completed');
      $(this).addClass('move-down');
      $('#mark-all-finished').removeClass('move-up');
      updateNotification('All tasks', 'marked as Incomplete.');
    });
    // Chat sidebar overlay
  
  $(document).on('click', '#task_chat', function() {
    $('.sidebar-overlay').toggleClass('opened');
    $('#task_window').addClass('opened');
    return false;
  });
// Modal Popup hide show

  if($('.modal').length > 0 ){
    var modalUniqueClass = ".modal";
    $('.modal').on('show.bs.modal', function(e) {
      var $element = $(this);
      var $uniques = $(modalUniqueClass + ':visible').not($(this));
      if ($uniques.length) {
      $uniques.modal('hide');
      $uniques.one('hidden.bs.modal', function(e) {
        $element.modal('show');
      });
      return false;
      }
    });
  }
  // Email Inbox

  if($('.clickable-row').length > 0 ){
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  }

  // Check all email
  
  $(document).on('click', '#check_all', function() {
    $('.checkmail').click();
    return false;
  });
  if($('.checkmail').length > 0) {
    $('.checkmail').each(function() {
      $(this).on('click', function() {
        if($(this).closest('tr').hasClass('checked')) {
          $(this).closest('tr').removeClass('checked');
        } else {
          $(this).closest('tr').addClass('checked');
        }
      });
    });
  }
  
  // Mail important
  
  $(document).on('click', '.mail-important', function() {
    $(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
  });
// Task Complete
  
  $(document).on('click', '#task_complete', function() {
    $(this).toggleClass('task-completed');
    return false;
  });
  
  // Multiselect

  if($('#customleave_select').length > 0) {
    $('#customleave_select').multiselect();
  }
  if($('#edit_customleave_select').length > 0) {
    $('#edit_customleave_select').multiselect();
  }
// Leave Settings button show
  
  $(document).on('click', '.leave-edit-btn', function() {
    $(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
    $(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
    $(this).parent().parent().find("input").prop('disabled', false);
    return false;
  });
  $(document).on('click', '.leave-cancel-btn', function() {
    $(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
    $(this).closest("div.leave-right").find(".leave-save-btn").remove();
    $(this).parent().parent().find("input").prop('disabled', true);
    return false;
  });
  
  $(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  $('.leave-box .onoffswitch-checkbox').each(function() {
    var id = $(this).attr('id').split('_')[1];
    if ($(this).prop("checked") == true) {
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
      $("#leave_"+id+" .leave-action .btn").prop('disabled', false);
    }
      else {
      $("#leave_"+id+" .leave-action .btn").prop('disabled', true); 
      $("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
      $("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
      $("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
      $("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
    }
  });
  
  // Placeholder Hide

  if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
    $('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
      $(this).data('placeholder', $(this).attr('placeholder'))
           .attr('placeholder', '');
    }).blur(function () {
      $(this).attr('placeholder', $(this).data('placeholder'));
    });
  }
  
  // OTP Input
  
  if ($('.otp-input').length > 0) {
    $(".otp-input").keyup(function(e) {
      if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
        $(e.target).next('.otp-input').focus();
      } else if (e.which == 8) {
        $(e.target).prev('.otp-input').focus();
      }
    });
  }
$(document).on('click', '.top-nav-search .responsive-search', function() {
    $('.top-nav-search').toggleClass('active');
  });
  
  $(document).on('click', '#file_sidebar_toggle', function() {
    $('.file-wrap').toggleClass('file-sidebar-toggle');
  });
  
  $(document).on('click', '.file-side-close', function() {
    $('.file-wrap').removeClass('file-sidebar-toggle');
  });
  
  if($('.kanban-wrap').length > 0) {
    $(".kanban-wrap").sortable({
      connectWith: ".kanban-wrap",
      handle: ".kanban-box",
      placeholder: "drag-placeholder"
    });
  }
    },
    name: 'projectview'
  }
</Script>